    import React, {useState} from 'react';
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../config";
import {AdminContent, DisplayImg, ImageContent, ModalWrapper, Image} from "./styles/AddImagesModal.styled";
import {setSelectedImageUrlsLabels} from "../../Store/slices/imageUrlLabelPairSlice";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {selectedImageUrlsLabelsSelector} from "../../Store/selectors/imageUrlsLabelsSelector";



const AddImagesModal = () => {
    const dispatch = useAppDispatch();
    const imagesWithLabels = useAppSelector(selectedImageUrlsLabelsSelector);

    const [imageUpload, setImageUpload] = useState<File | null>(null);
    const [imageLabel, setImageLabels] = useState<string>('');

    const uploadFile = (imageLabel: string) => {
        if (imageUpload == null) return;
        const imageRef = ref(storage, `images/${imageUpload.name}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                if (imagesWithLabels !== null) {
                    dispatch(setSelectedImageUrlsLabels([...imagesWithLabels, {url: url, label: imageLabel}]));
                } else {
                    dispatch(setSelectedImageUrlsLabels([{url: url, label: imageLabel}]));
                }
            });
        });
        setImageLabels('');
    };

    return (
        <ModalWrapper>
            <AdminContent>
                <input
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={(event) => {
                        if (event.target.files !== null) {
                            setImageUpload(event.target.files[0]);
                        }
                    }}/>
                <label htmlFor="label">Podpis</label>
                <input type="text"
                       id="label"
                       name="label"
                       onChange={(e) => setImageLabels(e.target.value)}
                       value={imageLabel}
                />

                <button onClick={(e) => {
                    e.preventDefault();
                    uploadFile(imageLabel)
                }}>
                    Zapisz obraz + opis
                </button>
                <ImageContent>

                    {imagesWithLabels?.map((item) => {
                        return <DisplayImg
                            onDoubleClick={() => {
                                dispatch(setSelectedImageUrlsLabels(imagesWithLabels.filter(img => img.url !== item.url)));
                            }}
                            key={Math.random()}>
                            <Image src={item.url}/>
                            <p>{item.label}</p>
                        </DisplayImg>
                    })
                    }
                </ImageContent>
            </AdminContent>
        </ModalWrapper>
    );
}

export default AddImagesModal
