import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {DocumentData} from "@firebase/firestore";
import styled from "styled-components";
import FACEBOOK from "../../Assets/Autumn2023/Faceebok.svg";
import TWITTER from "../../Assets/Autumn2023/Twitter.svg"
import LINKEDIN from "../../Assets/Autumn2023/Linkedin.svg"
import BlogSlider from "./blogSlider";
import {BUTTON_STYLE} from "../../types/style";
import Button from "../../Components/Common/Button";
import HoverableButton from "../../Components/Common/HoverableButton";
import {useAppSelector} from "../../Store/hooks";
import {blogSelector} from "../../Store/selectors/blogSelector";
import {ArticleDataDisplay} from "../../Models/blog.model";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";
import {generateBlogPostParts} from "./utils/generateBlogPostParts/generateBlogPostParts";

function Post() {
    const {slug} = useParams();
    const [blogPost, setBlogPost] = useState<DocumentData | null>(null);
    const [prevEntryId, setPrevEntryId] = useState<DocumentData | null>(null);
    const [nextEntryId, setNextEntryId] = useState<DocumentData | null>(null);
    const isMobile = useAppSelector(isMobileSelector);
    const blogEntries = useAppSelector(blogSelector) as NonNullable<ArticleDataDisplay[]>
    const navigate = useNavigate();


    useEffect(() => {
        const post = blogEntries.filter(entry => entry.id === slug)[0];
        setBlogPost(post)
    }, [slug]);

    useEffect(() => {
        const currentIndex = blogEntries.findIndex((entry) => entry.id === slug);
        if (currentIndex !== -1) {
            const prevIndex = currentIndex - 1;
            const prevEntry = blogEntries[prevIndex];

            const nextIndex = currentIndex + 1;
            const nextEntry = blogEntries[nextIndex];

            setPrevEntryId(prevEntry ? prevEntry : null);
            setNextEntryId(nextEntry ? nextEntry : null);
        }
    }, [blogEntries, slug]);


    let result;
    if (blogPost) {
        result = generateBlogPostParts(blogPost);
    }
    if (!blogPost) {
        return <div>Loading...</div>;
    }

    return (
        <Wrapper isMobile={isMobile}>
            <Breadcrumb aria-label="Breadcrumb" className="breadcrumb">
                <ol>
                    <li>
                        <a href="/">
                            Strona Główna
                        </a>
                        <span>/</span>
                    </li>
                    <li>
                        <a href="/blog">
                            Blog
                        </a>
                        <span>/</span>
                    </li>
                    <li>
                        <a href={`/blog/${blogPost.id}`}>
                            {blogPost.title}
                        </a>
                    </li>
                </ol>
            </Breadcrumb>
            <img src={blogPost.thumbnailUrl} alt={blogPost.title}/>
            <ContentDiv isMobile={isMobile}>
                <TextDiv>
                    <h6>{blogPost.datePublished} / {blogPost.author}</h6>
                    <h1>{blogPost.title}</h1>
                    {isMobile ? result.map((entry) =>
                        <MobileBlogPart>
                            <p>{entry.part}</p>
                            {entry.imageUrl && <img src={entry.imageUrl} alt={blogPost.title}/>}
                            {entry.imageLabel && <label>{entry.imageLabel}</label>}
                        </MobileBlogPart>
                    ) : <p>{blogPost.content}</p>}

                    {isMobile && <TagsDiv isMobile={isMobile}>
                        <h3>Kategorie</h3>
                        <div style={{marginBottom: 20}}>
                            {blogPost.tags.map((tag, index) => (
                                <Button
                                    key={index}
                                    label={tag}
                                    onClick={()=>navigate(`/search?query=${tag}`)}
                                    buttonStyle={BUTTON_STYLE.FILTERS_ACTIVE}
                                />))}
                        </div>
                    </TagsDiv>}
                    {!isMobile &&
                        <>
                            <SharingDiv>
                                <h6>Spodobał Ci się artykuł? Udostępnij go!</h6>
                                <div>
                                    <a
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={FACEBOOK} alt="Facebook"/>
                                    </a>
                                    <a
                                        href={`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={TWITTER} alt="Twitter"/>
                                    </a>
                                    <a
                                        href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={LINKEDIN} alt="LinkedIn"/>
                                    </a>
                                </div>
                            </SharingDiv>
                            <BottomNavDiv>
                                <HoverableButton
                                    disabled={!prevEntryId}
                                    left={true}
                                    selectedCity={''}
                                    id={prevEntryId ? prevEntryId.id : null}
                                    title={prevEntryId ? prevEntryId.title : null}
                                    buttonImageUrl={prevEntryId ? prevEntryId.thumbnailUrl : null}
                                />
                                <HoverableButton
                                    disabled={!nextEntryId}
                                    left={false}
                                    selectedCity={''}
                                    id={nextEntryId ? nextEntryId.id : null}
                                    title={nextEntryId ? nextEntryId.title : null}
                                    buttonImageUrl={nextEntryId ? nextEntryId.thumbnailUrl : null}
                                />
                            </BottomNavDiv>
                        </>}
                </TextDiv>
                {!isMobile && <TagsDiv isMobile={isMobile}>
                    <h3>Kategorie</h3>
                    <div style={{marginBottom: 20}}>
                        {blogPost.tags.map((tag, index) => (
                            <Button
                                key={index}
                                label={tag}
                                onClick={()=>navigate(`/search?query=${tag}`)}
                                buttonStyle={BUTTON_STYLE.FILTERS_ACTIVE}
                            />))}
                    </div>
                    <BlogSlider images={blogPost.images} width={440}/>
                </TagsDiv>}
                {isMobile &&
                    <>
                        <SharingDiv>
                            <h6>Spodobał Ci się artykuł? Udostępnij go!</h6>
                            <div>
                                <a
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={FACEBOOK} alt="Facebook"/>
                                </a>
                                <a
                                    href={`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={TWITTER} alt="Twitter"/>
                                </a>
                                <a
                                    href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href)}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={LINKEDIN} alt="LinkedIn"/>
                                </a>
                            </div>
                        </SharingDiv>
                        <BottomNavDiv>
                            <HoverableButton
                                disabled={!prevEntryId}
                                left={true}
                                isMobile={isMobile}
                                selectedCity={''}
                                id={prevEntryId ? prevEntryId.id : null}
                                title={prevEntryId ? prevEntryId.title : null}
                                buttonImageUrl={prevEntryId ? prevEntryId.thumbnailUrl : null}
                            />
                            <HoverableButton
                                disabled={!nextEntryId}
                                left={false}
                                isMobile={isMobile}
                                selectedCity={''}
                                id={nextEntryId ? nextEntryId.id : null}
                                title={nextEntryId ? nextEntryId.title : null}
                                buttonImageUrl={nextEntryId ? nextEntryId.thumbnailUrl : null}
                            />
                        </BottomNavDiv>
                    </>}
            </ContentDiv>
        </Wrapper>
    );
}

export default Post;


const Wrapper = styled.div<{ isMobile: boolean }>`
    width: ${({isMobile}) => (isMobile ? '92vw' : ' 1400px')};
    display: flex;
    flex-direction: column;
    position: relative;
    padding: ${({isMobile}) => (isMobile ? '0' : ' 120px 40px 0px 40px')};
    gap: ${({isMobile}) => (isMobile ? '12px' : ' 24px')};

    img {
        width: 100vw;
        height: ${({isMobile}) => (isMobile ? '37vw' : ' 80vh;')};
        position: relative;
        left: ${({isMobile}) => (isMobile ? '-35px' : ' -60px')};
        object-fit: cover;
        margin: 20px 0;
    }
`
export const Breadcrumb = styled.nav`
    a {
        text-decoration: none;
        color: #514D58;

        &:last-of-type {
            color: #1F0812;
        }
    }


    ol {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    li {
        text-decoration: none;
        color: #514D58;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        cursor: pointer;

        &:last-of-type {
            font-size: 18px;
        }
    }

    span {
        padding: 0 8px;
    }


    a:hover {
        color: #3e1daa;
    }
`
const ContentDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    width: 92vw;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
`

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 56vw;
    color: #1F0812;
    line-height: 147%;

    h6 {
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-bottom: 8px;
    }

    h1 {
        font-family: Cabinet Grotesk;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 32px;
    }
`

const TagsDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({isMobile}) => (isMobile ? '88vw' : '440px')};
    margin-left: ${({isMobile}) => (isMobile ? '0' : 'auto')};

    h3 {
        font-family: Arial;
        font-weight: 700;
        font-size: 32px;
        line-height: 38.4px;
        color: #1F0812;
        margin: 40px 0;
    }

    & div {
        display: flex;
        flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
        flex-wrap: ${({isMobile}) => (isMobile ? 'no-wrap' : 'wrap')};
        gap: 18px;

        button {
            font-size: 16px;
            text-transform: lowercase;
            width: ${({isMobile}) => (isMobile && 'auto')};
            margin: 0 auto 0 0;
        }
    }
`

const SharingDiv = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #1F0812;
    margin-top: 64px;


    & div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    img {
        width: 32px;
        height: 32px;
        margin: 0;
        left: 0;
    }
`

const BottomNavDiv = styled.div`
    display: flex;
    justify-content: space-between;
`


const MobileBlogPart = styled.div`
    width: 90vw;

    img {
        width: 100%;
        height: auto;
        left: 0;
        right: 0;
        margin: 12px 0 8px 0;
        padding: 0;
    }

    p {
        margin-top: 12px;
    }
`
