import styled from "styled-components";
import {useEffect, useState} from "react";
import ARROW from '../../Assets/Autumn2023/Arrow.svg'
import {Link} from "react-router-dom";

interface HoverableButtonProps {
    left: boolean;
    id: string;
    title: string;
    buttonImageUrl: string;
    disabled: boolean;
    blog?: boolean
    selectedCity: string
    isMobile?: boolean
}


const HoverableButton = ({
                             id,
                             buttonImageUrl,
                             disabled,
                             left = true,
                             title,
                             blog = true,
                             selectedCity,
                             isMobile = false
                         }: HoverableButtonProps) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <StyledHoverableButton
            imageUrl={buttonImageUrl}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isHovered={isHovered}
            disabled={disabled}
            isMobile={isMobile}
        >
            {isHovered && !left && <div style={{alignItems: 'flex-end'}}>
                <p>Następny</p>
                <h6>{title}</h6>
            </div>}
            <Link to={blog ? `/blog/${id}` : `/${selectedCity}/events/${id}`}>
                <button disabled={disabled}>
                    {!isHovered && <img src={ARROW} style={{transform: left ? 'rotate(90deg)' : 'rotate(-90deg)'}}/>}
                </button>
            </Link>
            {isHovered && left && <div style={{alignItems: 'flex-start'}}>
                <p>Poprzedni</p>
                <h6>{title}</h6>
            </div>}
        </StyledHoverableButton>
    );
};

export default HoverableButton;


const StyledHoverableButton = styled.div<{
    imageUrl: string,
    onMouseEnter: () => void,
    onMouseLeave: () => void
    isHovered: boolean
    disabled: boolean
    isMobile: boolean
}>`

  display: flex;
  flex-direction: ${({isMobile}) => (isMobile ? 'column-reverse' : ' row')};
  align-items: center;
  margin-top: 20px;
  gap: 18px;

  div {
    display: flex;
    flex-direction: column;
  }

  h6 {
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    opacity: 1;
  }

  p {
    margin-bottom: 6px;
    font-size: 14px;
  }

  button {
    position: relative;
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 360px;
    margin: 5px;
    background: #fff;

    img {
      width: 16px;
      height: 16px;
      margin: 0;
      left: 0;
    }

    &:hover {
      background-image: url(${props => props.imageUrl});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      color: transparent; /* Hide text on hover */
    }

    ${props => !props.disabled && `
          ::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 360px;
  background-image: linear-gradient(to right,
  rgba(175, 142, 245, 1),
  rgba(228, 119, 241, 1),
  rgba(241, 145, 84, 1),
  rgba(245, 193, 25, 1));
  }
   `};

    :disabled {
      background: #E5E4E7;
    }
  }
`
