import { createSelector } from "reselect";
import type { RootState } from "../index";

const isAuthenticated = (state: RootState) => state.isAuthenticated;

export const isAuthenticatedSelector = createSelector(
    isAuthenticated,
    (isAuthenticated) => isAuthenticated.value
);
export const userSelector = createSelector(
    isAuthenticated,
    (isAuthenticated) => isAuthenticated.user
);
