import {db} from "../config";
import {doc, setDoc,} from "@firebase/firestore";


const updateIsEventVisible = async (
    id: string | undefined,
    isVisible?: boolean,
) => {
    try {

        if (id === undefined) {
            return;
        }
        const docRef = doc(db, 'events', id)
        await setDoc(docRef, {isVisible: !isVisible, id:id}, {merge: true});

    } catch(err){
        alert('Wystąpił błąd!')
        console.log(err);
    }
}

export default updateIsEventVisible
