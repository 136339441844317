import React, {useState} from 'react';
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {FestivalBox, CollapsibleHead} from './styles/Collapsible.styled';

const Collapsible = (props: any) => {
    const [open, setOPen] = useState(false);
    const toggle = () => setOPen(!open);

    return (
        <FestivalBox>
            <CollapsibleHead>
                <p> {props.festival.title}</p>
                <button onClick={toggle}>{open ? <FaChevronUp size={16}/> : <FaChevronDown size={16}/>}</button>
            </CollapsibleHead>
            {open && <div className="toggle">{props.children}</div>}
        </FestivalBox>
    );
}
export default Collapsible;
