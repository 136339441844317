export enum menuEnum {
    Gdynia = 'Gdynia',
    Gdansk = 'Gdansk',
    Sopot = 'Sopot',
    Blog = 'Blog',
    User = 'User',
    Login = "Login"
}

export enum PagesDisplay {
    login = "Login",
    blog = "Blog",
    gdynia = "Gdynia",
    gdansk = "Gdańsk",
    admin = "Admin",
    user = "User",
    search = "Search",
    about = "About",
    team = 'Team',
    rules = 'Rules',
}

export enum FestivalDisplay {
    current = "current",
    archive = "archive",
    about = "about",
}

export enum EventView {
    map = "map",
    list = "list",
    tile = "tile",
}

export enum EventViewFilters {
    ALL = "ALL",
    RESERVATIONS = "RESERVATIONS",
    NO_RESERVATIONS = "NO_RESERVATIONS",
}

export enum EventViewWeekdayFilters {
    ALL = "ALL",
    THURSDAY = 'THURSDAY',
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}

export enum UserSubpageView {
    myFestival = "myFestival",
    myArticles = "myArticles",
    profile = "profile",
}


export enum AdminPagesDisplay {
    addFestival = "addFestival",
    addArticle = "addArticle",
    addEvent = "addEvent",
    festivals = "festivals",
    blog = "blog",
    events = "events",
    resources = "resources"
}
