import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SELECTED_CITY} from "../../types/constants";


interface SelectedCityState {
    value: SELECTED_CITY
}

const initialState: SelectedCityState = {
    value: SELECTED_CITY.NONE
};

export const selectedCitySlice = createSlice({
    name: 'selectedCity',
    initialState,
    reducers: {
        setSelectedCity: (state, action: PayloadAction<SELECTED_CITY>) => {
            state.value = action.payload;
        },
    },
});

export const {setSelectedCity} = selectedCitySlice.actions;
export default selectedCitySlice.reducer;
