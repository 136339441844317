import {
    Outlet
} from 'react-router-dom';
import GdanskPartners from "./components/GdanskPartners";

function Gdansk() {


    return (
        <>
            <Outlet/>
            <GdanskPartners/>
        </>
    );
}

export default Gdansk
