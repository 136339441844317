import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AddImagesModalState {
    value: boolean
}

const initialState: AddImagesModalState = {
    value: false,
};

export const addImagesModalSlice = createSlice({
    name: 'showAddImagesModal',
    initialState,
    reducers: {
        setShowAddImagesModal: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
    },
});

export const {setShowAddImagesModal} = addImagesModalSlice.actions;
export default addImagesModalSlice.reducer;

