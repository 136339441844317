import styled from "styled-components";
import LOGO from "../../Assets/Autumn2023/Logo-footer.svg"
import pura from "../../Assets/pura.svg";
import invest from "../../Assets/invest.svg";
import React from "react";
import facebook from "../../Assets/facebook.svg";
import ig from "../../Assets/ig.svg";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../Store/hooks";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";


const NewFooter = () => {
    const isMobile = useAppSelector(isMobileSelector);

    return (
        <>
            <FooterDiv isMobile={isMobile}>
                <LogoDiv>
                    <img src={LOGO}/>
                    <CommonIcons>
                        <Icons isMobile={isMobile}>
                            <h4>Gdynia</h4>
                            <a href="https://www.facebook.com/OpenHouseGdynia" target='_blank'>
                                <img src={facebook} alt={'clickable icon - open house gdynia facebook'}/>
                            </a>
                            <a href="https://www.instagram.com/openhousegdynia/" target='_blank'>
                                <img src={ig} alt={'clickable icon - open house gdynia instagram'}/>
                            </a>
                        </Icons>
                        <Icons isMobile={isMobile}>
                            <h4>Gdańsk</h4>
                            <a href="https://www.facebook.com/OpenHouseGdansk" target='_blank'>
                                <img src={facebook} alt={'clickable icon - open house gdansk facebook'}/>
                            </a>
                            <a href="https://www.instagram.com/openhousegdansk/" target='_blank'>
                                <img src={ig} alt={'clickable icon - open house gdansk instagram'}/>
                            </a>
                        </Icons>
                    </CommonIcons>
                </LogoDiv>
                <EmailDiv isMobile={isMobile}>
                    <h4>Kontakt</h4>
                    <h3>Program i informacje ogólne</h3>
                    <h4><a href="mailto:info@openhousefestival.pl">info@openhousefestival.pl</a></h4>
                    <h3>Promocja i PR</h3>
                    <h4><a href="mailto:tkosmider@openhousefestival.pl">tkosmider@openhousefestival.pl</a></h4>
                    <h3>Wolontariat</h3>
                    <h4><a href="mailto:wolontariat@openhousefestival.pl">wolontariat@openhousefestival.pl</a></h4>
                    {isMobile && <SponsorsDiv isMobile={isMobile}>
                        <div>
                            <h4> Organizator </h4>
                            <a href="https://www.facebook.com/Fundacja-Pura-474586729418170/">
                                <img src={pura} alt={'logo pura'}/>
                            </a>
                        </div>
                        <div>
                            <h4>Mecenat </h4>
                            <img src={invest} alt={'logo invest'}/>
                        </div>
                    </SponsorsDiv>}
                    {!isMobile && <>
                        <h5>Autorki projektu strony</h5>
                        <AuthorsDiv isMobile={false}>
                            <Author>
                                <h4>Emilia Szydłowska</h4>
                                <h5>Linkedin | Portfolio</h5>
                            </Author>
                            <Author>
                                <h4>&</h4>
                            </Author>
                            <Author>
                                <h4>Jagoda Szerement</h4>
                                <h5>Linkedin | Portfolio</h5>
                            </Author>
                        </AuthorsDiv></>}
                </EmailDiv>
                <BottomNav isMobile={isMobile}>
                    <SiteNav isMobile={isMobile}>
                        <Column isMobile={isMobile}>
                            <ul>
                                <li><Link to='/gdynia'>Gdynia</Link></li>
                                <li><Link to='/gdansk'>Gdansk</Link></li>
                                <li><Link to='/blog'>Blog</Link></li>
                                <li><Link to='/archive'>Archiwum</Link></li>
                                <li><Link to='/faq'>FAQ</Link></li>
                            </ul>
                        </Column>
                        <Column isMobile={isMobile}>
                            <ul>
                                <li><Link to='/login'>Logowanie</Link></li>
                                <li><Link to='/register'>Rejestracja</Link></li>
                                <li><Link to='/rules'>Regulamin</Link></li>
                                <li><Link to='/rodo'>Polityka prywatności</Link></li>
                            </ul>
                        </Column>
                    </SiteNav>
                    {!isMobile && <SponsorsDiv isMobile={isMobile}>
                        <div>
                            <h4> Organizator </h4>
                            <a href="https://www.facebook.com/Fundacja-Pura-474586729418170/">
                                <img src={pura} alt={'logo pura'}/>
                            </a>
                        </div>
                        <div>
                            <h4>Mecenat </h4>
                            <img src={invest} alt={'logo invest'}/>
                        </div>
                    </SponsorsDiv>}
                </BottomNav>
                {isMobile && <>
                    <h5>Autorki projektu strony</h5>
                    <AuthorsDiv isMobile={true}>
                        <Author>
                            <h4>Emilia Szydłowska</h4>
                            <h5>Linkedin | Portfolio</h5>
                        </Author>
                        <Author>
                            <h4>Jagoda Szerement</h4>
                            <h5>Linkedin | Portfolio</h5>
                        </Author>
                    </AuthorsDiv>

                    <h5>Realizacja</h5>
                    <AuthorsDiv isMobile={false}>
                        <Author>
                            <h4>Jan Rogalo</h4>
                            <h5>Linkedin | Portfolio</h5>
                        </Author>
                    </AuthorsDiv></>}
            </FooterDiv>
            <Yellow>
                {/*<img src={FOOTER_BG}/>*/}
            </Yellow>
        </>
    )
        ;
}

export default NewFooter


const FooterDiv = styled.footer<{ isMobile: boolean }>`
    background-color: #1B0466;
    position: relative;
    color: #FBFDFF;
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    flex-wrap: wrap;
    justify-content: ${({isMobile}) => (isMobile ? 'center' : 'space-between')};
    padding: ${({isMobile}) => (isMobile ? '32px 20px' : '60px')};
`

const LogoDiv = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
        margin-bottom: 22px;
        height: 180px;
        width: 180px;
    }
`

const EmailDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({isMobile}) => (isMobile ? '100%' : '390px')};
    color: #FBFDFF;
    line-height: 130%;

    h4 {
        font-family: Cabinet Grotesk;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        margin: 0 0 20px 0;
    }

    h3 {
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
    }

    h5 {
        margin-top: 40px;
        margin-bottom: 8px;
    }

    a {
        color: #FBFDFF;
        font-family: Cabinet Grotesk;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: #FFA4CF
        }

        &:focus {
            color: #FFA4CF
        }

        &:active {
            color: #FFA4CF
        }

`
const AuthorsDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    gap: 8px;
    margin-bottom: 20px;
`

const Author = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    h4 {
        margin: 0;
        margin-bottom: 8px;
    }

    h5 {
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
    }
`

const BottomNav = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({isMobile}) => (isMobile ? '30px' : '0')};

`

const SponsorsDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    justify-content: space-between;
    gap: ${({isMobile}) => (isMobile ? '18px' : '60px')};
    margin-bottom: ${({isMobile}) => (isMobile ? '28px' : '0')};

    h4 {
        margin: 0;
        color: #FBFDFF;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.16px;
    }

    &div {
        display: flex;
        flex-direction: column;
    }

    img {
        min-height: ${({isMobile}) => (isMobile ? '80%' : '60px')};
        max-height: ${({isMobile}) => (isMobile ? '80px ' : '70px')};
    }
`

const SiteNav = styled.div<{ isMobile: boolean }>`
    display: flex;
        // flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    gap: 20px;
    margin-bottom: auto;
`

const Column = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({isMobile}) => (isMobile ? '160px' : '200px')};


    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: 8px;
    }

    a {
        color: #FBFDFF;
        font-family: Cabinet Grotesk;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: #FFA4CF
        }

        &:focus {
            color: #FFA4CF
        }

        &:active {
            color: #FFA4CF
        }
    }
`
const Yellow = styled.div`
    width: 100%;
    height: 50px;
    background: #FCE36E;

    img {
        height: auto;
        width: 100%;
    }
`

const Icons = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    margin: 10px 0 20px 0;
    gap: 12px;

    a {
        margin: 0;
    }

    img {
        width: 32px;
        height: 32px;
        margin: 0;
    }

    img:hover {
        transform: scale(1.1);
    }
`
const CommonIcons = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
`
