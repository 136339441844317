import React, {Dispatch, SetStateAction, useEffect, useMemo} from "react";
import {FestivalFormInterface, FestivalLocation, NewFestivalData} from "../../Models/festival.model";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import updateFestival from "../../Utilities/updateFestival";
import {fetchFestivals} from "../../Utilities/fetchFestivals";
import {IoMdClose} from "react-icons/io";
import {useFormik} from "formik";
import {festivalSchema} from "./validators/festivalSchema";
import styled from "styled-components";
import {selectedImageUrlsLabelsSelector} from "../../Store/selectors/imageUrlsLabelsSelector";
import AddImagesModal from "../../Components/Common/AddImagesModal";
import {setSelectedImageUrlsLabels} from "../../Store/slices/imageUrlLabelPairSlice";

type Props = {
    festival: NewFestivalData;
    openEdit: string;
    setOpenEdit: Dispatch<SetStateAction<string | null>>;
}

const EditFestival = (props: Props) => {
    const dispatch = useAppDispatch();
    const imagesWithLabels = useAppSelector(selectedImageUrlsLabelsSelector);

    useEffect(() => {
        if (imagesWithLabels.length === 0) {
            dispatch(setSelectedImageUrlsLabels(props.festival.images!));
        }
    }, [props.openEdit])


    const formik = useFormik({
        initialValues: {
            location: props.festival.location,
            startDate: props.festival.startDate,
            endDate: props.festival.endDate,
            title: props.festival.title,
            description: props.festival.description,
            images: props.festival.images,
        },

        validationSchema: festivalSchema,
        onSubmit: (values: FestivalFormInterface) => {
            console.log(values);
        }
    });


    return (
        <ModalWrapper>
            <Whiteout/>
            <ContentBox>
                <Icon>
                    <IoMdClose onClick={() => props.setOpenEdit(null)}/>
                </Icon>


                <h2>Edytuj Festiwal</h2>
                <form onSubmit={formik.handleSubmit}>
                    <FormUnit>
                        <label htmlFor="title">Nazwa</label>
                        <input
                            id="title"
                            name="title"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            style={{
                                backgroundColor: formik.touched.title && formik.errors.title ? "#FFD8C9" : "",
                                color: formik.touched.title && formik.errors.title ? "red" : ""
                            }}
                        />
                        {formik.touched.title && formik.errors.title ? (
                            <ErrorDiv>{formik.errors.title}</ErrorDiv>
                        ) : null}

                        <label htmlFor="description">Opis</label>
                        <textarea
                            id="description"
                            name="description"
                            rows={10}
                            cols={50}
                            onChange={formik.handleChange}
                            value={formik.values.description}
                        />
                    </FormUnit>

                    <FormUnit>
                        <label htmlFor="location"> Lokalizacja </label>
                        <select
                            id="location"
                            name="location"
                            onChange={formik.handleChange}
                            value={formik.values.location}>
                            <option value="">Wybierz lokalizację</option>
                            <option value={FestivalLocation.Gdansk}>Gdańsk</option>
                            <option value={FestivalLocation.Sopot} disabled>Sopot</option>
                            <option value={FestivalLocation.Gdynia}>Gdynia</option>
                        </select>

                        <label htmlFor="startDate">Data rozpoczęcia</label>
                        <input
                            id="startDate"
                            name="startDate"
                            type="date"
                            onChange={formik.handleChange}
                            value={formik.values.startDate}
                        />

                        <label htmlFor="endDate">Data zakończenia</label>
                        <input
                            id="endDate"
                            name="endDate"
                            type="date"
                            onChange={formik.handleChange}
                            value={formik.values.endDate}
                        />
                    </FormUnit>
                    <AddImagesModal/>
                    <button onClick={() => props.setOpenEdit(null)}>Wróć</button>
                    <button type="submit" onClick={async (e) => {
                        e.preventDefault();
                        await updateFestival(
                            props.festival.id,
                            formik.values.location,
                            formik.values.startDate,
                            formik.values.endDate,
                            formik.values.title,
                            formik.values.description,
                            imagesWithLabels,
                        )
                        await fetchFestivals(dispatch);
                        props.setOpenEdit(null);
                    }}>Zaakceptuj zmiany
                    </button>
                </form>
            </ContentBox>
        </ModalWrapper>
    )
}

export default EditFestival;


const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Whiteout = styled.div`
  background: gray;
  opacity: 0.7;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
`

const ContentBox = styled.div`
  background: white;
  height: 600px;
  width: 700px;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: scroll;

  span {
    margin-right: 10px;
  }

  button {
    margin-top: 20px;
  }
`
const Icon = styled.div`
  width: 100%;
  position: absolute;
  left: 95%;
  top: 4%;

`

const FormWrap = styled.div`
  width: 65%;
  height: auto;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin: 0 auto;

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  label {
    margin-bottom: 5px;
  }

  input {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  textarea {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  select {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  button {
    width: 200px;
  }
`
const ErrorDiv = styled.div`
  color: red;
`
const FormUnit = styled.div`
  background-color: #ECEFF1;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`
