import {useState} from "react";
import {addDoc, collection} from "@firebase/firestore";
import {FirebaseCollections} from "../../Constants/firebaseCollections";
import {db} from "../../config";
import {useAppSelector} from "../../Store/hooks";
import {selectedImageUrlsSelector} from "../../Store/selectors/imageUrlsSelector";
import {sanitizeTags} from "../sanitizeTags";
import {ArticleDataInput} from "../../Models/blog.model";
import {selectedImageUrlsLabelsSelector} from "../../Store/selectors/imageUrlsLabelsSelector";


const useAddBlog = () => {
    const [isPending, setIsPending] = useState(false);
    let addedBlogId = "";
    const imageUrls = useAppSelector(selectedImageUrlsSelector)
    const imagesWithLabels = useAppSelector(selectedImageUrlsLabelsSelector);



    const addNewBlog = async (articleData: ArticleDataInput) => {
        let success = true;
        setIsPending(true);
        console.log(imagesWithLabels);
        try {
            const doc = await addDoc(collection(db, FirebaseCollections.Articles), {
                ...articleData,
                isHero: false,
                isVisible: false,
                images: imagesWithLabels,
                thumbnailUrl: imagesWithLabels![0].url,
                tags: sanitizeTags(articleData.tags),
                datePublished: new Date().toLocaleDateString(),
            });
            addedBlogId = doc.id;
        } catch (err) {
            success = false;
            console.log(err);
        }
        setIsPending(false);
        return {success, addedBlogId};
    };
    return {addNewBlog, isPending};
};

export default useAddBlog;
