import {useMapEvents} from "react-leaflet";
import {useState} from 'react';

const useZoom = (map) => {
    const [zoom, setZoom] = useState(map.getZoom());

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoom(mapEvents.getZoom());
        }
    });
    return zoom;
}

export default useZoom;
