import {Outlet} from 'react-router-dom';

function Blog() {

    return (
        <div style={{ padding: 20 }}>
            <Outlet/>
        </div>
    );
}

export default Blog
