export enum FestivalLocation {
    Gdynia = 'Gdynia',
    Gdansk = 'Gdańsk',
    Sopot = 'Sopot'
}

export interface FestivalInterface{
    id: string;
    location: string;
    startDate: string;
    endDate: string;
    images?: {url: string, label: string }[]
    title: string;
    description: string;
}

export interface FestivalFormInterface{
    location: string;
    startDate: string;
    endDate: string;
    images?: {url: string, label: string }[]
    title: string;
    description: string;
}

export interface NewFestivalData{
    location: string;
    startDate: string;
    endDate: string;
    images?: {url: string, label: string }[]
    isCurrent: boolean;
    title: string;
    description: string;
    id?: string;
}
