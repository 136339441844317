import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {sendPasswordReset} from "../../Utilities/sendPasswordReset";
import styled from "styled-components";
import bg from "../../Assets/Autumn2023/bg.webp";
import InputField from "../../Components/Common/InputField/InputField";
import {BUTTON_STYLE} from "../../types/style";
import Button from "../../Components/Common/Button";
import validator from "validator";
import {mapError} from "../../Utilities/mapError";
import {useNavigate} from "react-router-dom";

const ForgotPasswordPage = () => {
    const [emailError, setEmailError] = useState<string>("");
    const emailRef = useRef<HTMLInputElement | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        if (window.outerWidth < 1100) {
            setIsMobile(true);
        }
    }, []);

    const validateEmail = (email: string) => {
        if (!validator.isEmail(email) && emailRef.current !== document.activeElement) {
            setEmailError("Podaj poprawny email");
            return false;
        } else {
            setEmailError("");
            return true;
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const emailValue = emailRef.current?.value || "";

        if (!validateEmail(emailValue)) return;

        try {
            await sendPasswordReset(emailValue);
            setSuccess(true);

        } catch (error: any) {
            setEmailError(mapError(error.code));
        }
    };


    return (
        <ContentWrapper isMobile={isMobile}>
            {!isMobile && <img src={bg}/>}
            {!success ?
                <FormContainer>
                    <LoginTitle>Nie pamiętasz hasła?</LoginTitle>
                    <p>Wystarczy, że podasz swój adres e-mail, a my pomożemy Ci ustawić nowe hasło.</p>
                    <form onSubmit={handleSubmit}>
                        <InputField
                            placeholder="Podaj swój adres e-mail"
                            label="Twój e-mail"
                            ref={emailRef}
                            error={emailError}
                            isMobile={isMobile}
                        />
                        <ButtonSpan isMobile={isMobile}>
                            <Button buttonStyle={BUTTON_STYLE.PINK} label="Resetuj hasło" onClick={() => handleSubmit}/>
                        </ButtonSpan>
                    </form>
                </FormContainer>
                : <FormContainer>
                    <LoginTitle>Wysłaliśmy do Ciebie mail z linkiem do zmiany hasła</LoginTitle>
                    <p>Nie zapomnij o sprawdzeniu również folderu SPAM.</p>
                    <ButtonSpan isMobile={isMobile}>
                        <Button buttonStyle={BUTTON_STYLE.GREEN} label="Przejdź na stronę główną"
                                onClick={() => navigate('/')}/>
                    </ButtonSpan>
                </FormContainer>}
        </ContentWrapper>
    );
};


export default ForgotPasswordPage;


const ButtonSpan = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: ${({isMobile}) => (isMobile ? 'flex-start' : 'center')};
  margin-top: 12px;

  p {
    font-family: "Lato", serif;
    font-size: 1.5rem;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const ContentWrapper = styled.div<{ isMobile: boolean }>`
  width: 100vw;
    margin-top: 100px;
  background: #FBFDFF;
  display: flex;
  padding: ${({isMobile}) => (isMobile ? '16px' : ' 120px 40px 40px 40px')};
  gap: 115px;

  img {
    width: 40vw;
  height: auto;

    @media (max-width: 1000px) {
      width: 90%;
      height: auto;
    }
  }

  @media (max-width: 500px) {
    height: auto;
  }
`

export const FormContainer = styled.div`
  position: relative;
  width: 500px;
  top: 20%;
  display: flex;
  flex-direction: column;

  form {
    margin-top: 32px;
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    width: 280px;
    top: 3%;
  }
`

export const LoginTitle = styled.div`
  color: #3E1DAA;
  font-family: Cabinet Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  padding-bottom: 20px;
`
export const DivButton = styled.div`
  margin-top: 12px;
  color: #3E1DAA;
  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;

  :hover {
    font-weight: 800;
  }
`


