import {db} from "../config";
import {doc, updateDoc} from "@firebase/firestore";
import {UpdateFestivalData} from "../types/festival";

const updateFestival = async (
    id: string | undefined,
    location?: string,
    startDate?: string,
    endDate?: string,
    title?: string,
    description?: string,
    images?: {url: string, label: string }[],
) => {

    try {
        if (id === undefined) {
            return;
        }
        const docRef = doc(db, 'festivals', id)
        const dataToUpdate: UpdateFestivalData = {};
        if (location) {
            dataToUpdate['location'] = location
        }
        if (startDate) {
            dataToUpdate['startDate'] = startDate
        }
        if (endDate) {
            dataToUpdate['endDate'] = endDate
        }

        if (title) {
            dataToUpdate['title'] = title
        }
        if (description) {
            dataToUpdate['description'] = description
        }
        if (images) {
            dataToUpdate['images'] = images
        }

        await updateDoc(docRef, dataToUpdate);
    } catch(err){
        alert('Wystąpił błąd!')
        console.log(err);
    }
}

export default updateFestival;
