import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../Models/user.model";

interface isAuthenticatedState {
    value: boolean;
    user: null | User;
}
const initialState: isAuthenticatedState = {
    value: false,
    user: null,
};

export const authenticatedSlice = createSlice({
    name: "isAuthenticated",
    initialState,
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        },
    },
});

export const { setIsAuthenticated, setUser } = authenticatedSlice.actions;
export default authenticatedSlice.reducer;
