import styled from "styled-components";
import Button from "../../../Components/Common/Button";
import {BUTTON_STYLE} from "../../../types/style";
import ICONS_VIEW from "../../../Assets/Autumn2023/Icons-view.svg";
import LIST_VIEW from "../../../Assets/Autumn2023/List-view.svg";
import MAP_VIEW from "../../../Assets/Autumn2023/map-view.svg";
import {EventView, EventViewFilters, EventViewWeekdayFilters} from "../../../Models/menu.model";
import {Dispatch, SetStateAction, useEffect} from "react";
import PaginationButton from "../../../Components/Common/PaginationButton";

interface GdyniaEventsHeaderProps {
    eventViewMode: EventView,
    setEventViewMode: Dispatch<SetStateAction<EventView>>
    filteredEvents: EventViewFilters;
    setFilteredEvents: Dispatch<SetStateAction<EventViewFilters>>
    filteredEventsByDay: EventViewWeekdayFilters;
    setFilteredEventsByDay: Dispatch<SetStateAction<EventViewWeekdayFilters>>
}

function GdyniaEventsHeaderMobile({
                                eventViewMode,
                                setEventViewMode,
                                filteredEvents,
                                setFilteredEvents,
                                filteredEventsByDay,
                                setFilteredEventsByDay,
                            }: GdyniaEventsHeaderProps) {


    useEffect(()=>{
        setFilteredEventsByDay(EventViewWeekdayFilters.THURSDAY)
    },[])


    const handleNavigatePreviousDay = () => {
        switch (filteredEventsByDay) {
            case EventViewWeekdayFilters.FRIDAY:
                setFilteredEventsByDay(EventViewWeekdayFilters.THURSDAY);
                break;
            case EventViewWeekdayFilters.SATURDAY:
                setFilteredEventsByDay(EventViewWeekdayFilters.FRIDAY);
                break;
            case EventViewWeekdayFilters.SUNDAY:
                setFilteredEventsByDay(EventViewWeekdayFilters.SATURDAY);
                break;
            default:
                // If it's already Friday or any other day, do nothing
                break;
        }
    };

    const handleNavigateNextDay = () => {
        switch (filteredEventsByDay) {
            case EventViewWeekdayFilters.THURSDAY:
                setFilteredEventsByDay(EventViewWeekdayFilters.FRIDAY);
                break;
            case EventViewWeekdayFilters.FRIDAY:
                setFilteredEventsByDay(EventViewWeekdayFilters.SATURDAY);
                break;
            case EventViewWeekdayFilters.SATURDAY:
                setFilteredEventsByDay(EventViewWeekdayFilters.SUNDAY);
                break;
            // Add more cases as needed
            default:
                // If it's already Sunday or any other day, do nothing
                break;
        }
    };
    const getCurrentDayLabel = () => {
        switch (filteredEventsByDay) {
            case EventViewWeekdayFilters.THURSDAY:
                return "czwartek";
            case EventViewWeekdayFilters.FRIDAY:
                return "piątek";
            case EventViewWeekdayFilters.SATURDAY:
                return "sobota";
            case EventViewWeekdayFilters.SUNDAY:
                return "niedziela";
        }
    };


    return (
        <Wrapper>
            <FestivalDataDiv>
                <h1>Gdynia</h1>
                <div>
                    <h2>23.05 – 26.05.2024</h2>
                </div>
            </FestivalDataDiv>
            <FiltersDiv>
                <div>
                    <h3>Widok</h3>
                    <div>
                        <Button
                            label="lista"
                            buttonStyle={eventViewMode === EventView.list
                                ? BUTTON_STYLE.FILTERS_ACTIVE
                                : BUTTON_STYLE.FILTERS
                            }
                            icon={LIST_VIEW}
                            onClick={() => setEventViewMode(EventView.list)}
                        />
                        <Button
                            label="kafelki"
                            buttonStyle={eventViewMode === EventView.tile
                                ? BUTTON_STYLE.FILTERS_ACTIVE
                                : BUTTON_STYLE.FILTERS
                            }
                            icon={ICONS_VIEW}
                            onClick={() => setEventViewMode(EventView.tile)}
                        />
                        <Button
                            label="mapa"
                            buttonStyle={eventViewMode === EventView.map
                                ? BUTTON_STYLE.FILTERS_ACTIVE
                                : BUTTON_STYLE.FILTERS
                            }
                            icon={MAP_VIEW}
                            onClick={() => setEventViewMode(EventView.map)}
                        />
                    </div>
                </div>
                <div>
                    <h3>Rezerwacja</h3>
                    <div>
                        <Button
                            label="tak"
                            buttonStyle={filteredEvents === EventViewFilters.RESERVATIONS
                                ? BUTTON_STYLE.FILTERS_ACTIVE
                                : BUTTON_STYLE.FILTERS
                            }
                            onClick={() => setFilteredEvents(EventViewFilters.RESERVATIONS)
                            }/>
                        <Button label="nie"
                                buttonStyle={filteredEvents === EventViewFilters.NO_RESERVATIONS
                                    ? BUTTON_STYLE.FILTERS_ACTIVE
                                    : BUTTON_STYLE.FILTERS
                                }
                                onClick={() => setFilteredEvents(EventViewFilters.NO_RESERVATIONS)
                                }/>
                        <Button label="wszystkie"
                                buttonStyle={filteredEvents === EventViewFilters.ALL
                                    ? BUTTON_STYLE.FILTERS_ACTIVE
                                    : BUTTON_STYLE.FILTERS
                                }
                                onClick={() => setFilteredEvents(EventViewFilters.ALL)
                                }/>
                    </div>
                </div>
                <div>

                    <DatesDiv>
                        <PaginationButton
                            disabled={filteredEventsByDay === EventViewWeekdayFilters.THURSDAY}
                            onClick={handleNavigatePreviousDay}
                            left={true}
                        />
                        <p>{getCurrentDayLabel()}</p>

                        <PaginationButton
                            disabled={filteredEventsByDay === EventViewWeekdayFilters.SUNDAY}
                            onClick={handleNavigateNextDay}
                            left={false}
                        />
                    </DatesDiv>
                </div>
            </FiltersDiv>
        </Wrapper>
    );
}

export default GdyniaEventsHeaderMobile


const Wrapper = styled.div`
  width: 94vw;
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: space-between;
  position: relative;
`

const FestivalDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #3E1DAA;

  h1 {
    font-family: Cabinet Grotesk;
    font-size: 6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.4px;
    text-align: center;
  }

  & div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-family: Cabinet Grotesk;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }

    p {
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      padding: 12px;
      background: #FCE36E;
      border-radius: 60px;
    }
  }

`

const FiltersDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    button {
        max-width: 114px;
    }

    & div {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

        }

        h3 {
            font-family: Arial;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            letter-spacing: 0.16px;
            color: black;
            margin-right: auto;
        }
    }
`

const DatesDiv = styled.div`
  color: var(--purple-purple-600, #1B0466);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`

