import styled from "styled-components";

export const HomeDiv = styled.section <{ isMobile: boolean }>`
    height: ${({isMobile}) => (!isMobile && '100vh')};
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: ${({isMobile}) => (isMobile ? '0' : '130px')};
`

export const HomePage = styled('div')<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;   
`;

export const TitleDiv = styled.div <{ isMobile: boolean }>`
    padding: ${({isMobile}) => (isMobile ? '20px' : '0')};
    top: ${({isMobile}) => (isMobile ? '0px;' : '140px')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${({isMobile}) => (isMobile ? '90vw' : '900px')};

    h1 {
        font-family: Cabinet Grotesk, sans-serif;
        font-size: ${({isMobile}) => (isMobile ? '36px' : '48px')};
        font-weight: 500;
        line-height: ${({isMobile}) => (isMobile ? '36px' : '55px')};
        letter-spacing: 0;
        text-align: ${({isMobile}) => (isMobile ? 'left' : 'center')};;
        color: ${props => props.theme.black};
        margin: 0;
    }

    h2 {
        font-family: Cabinet Grotesk, sans-serif;
        font-weight: 700;
        font-size: ${({isMobile}) => (isMobile ? '16px;' : '20px')};
        line-height: 26px;
        margin: 0;
        color: ${props => props.theme.purple};
        text-align: center;
    }

    img {
        position: relative;
        width: 260px;
        left: ${({isMobile}) => (isMobile ? '5px;' : '600px')};
        margin-top: ${({isMobile}) => (isMobile ? '-8px;' : '-18px')};
        z-index: -1;
    }
`;

export const CardDiv = styled.div<{ isMobile: boolean }>`
    margin-top: 40px;
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    justify-content: space-between;
    gap: 8%;
`
