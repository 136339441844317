import {collection, getDocs, query} from "@firebase/firestore";
import {db} from "../config";
import {setActiveFestival} from "../Store/slices/activeFestivalSlice";
import {setFestivals} from "../Store/slices/festivalsSlice";

export const fetchFestivals = async(dispatchFunction:any)=> {
    let fests: any = []
    let activeFests: any = [];
    const q = query(collection(db, "festivals"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        fests.push({
            ...doc.data(),
            id: doc.id
        });
        if(doc.data().isCurrent) {
            const active = doc.data();
            activeFests.push(active);

        }
    })

    dispatchFunction(setFestivals(fests));
    dispatchFunction(setActiveFestival(activeFests))
}
