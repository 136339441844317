import {db} from "../config";
import {doc, updateDoc} from "@firebase/firestore";
import {sanitizeTags} from "./sanitizeTags";
import {UpdateBlogData} from "../types/blogPosts";

const updateBlog = async (
    id: string | undefined,
    title?: string,
    content?: string,
    author?: string,
    tags?: string,
    layout?: number,
    images?: {url: string, label: string }[],
) => {

    try {
        if (id === undefined) {
            return;
        }
        const docRef = doc(db, 'articles', id)
        const dataToUpdate: UpdateBlogData = {};
        if (title) {
            dataToUpdate['title'] = title
        }
        if (content) {
            dataToUpdate['content'] = content
        }
        if (author) {
            dataToUpdate['author'] = author
        }

        if (tags) {
            // @ts-ignore
            dataToUpdate['tags'] = sanitizeTags(tags)
        }
        if (layout) {
            dataToUpdate['layout'] = layout
        }

        if (images) {
            dataToUpdate['images'] = images
        }
        await updateDoc(docRef, dataToUpdate);
    } catch(err){
        alert('Wystąpił błąd!')
        console.log(err);
    }
}

export default updateBlog;
