import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NewFestivalData} from "../../Models/festival.model";

interface FestivalsState {
    value: NewFestivalData[] | null;
}

const initialState: FestivalsState = {
    value: null
};

export const festivalsSlice = createSlice({
    name: 'festivals',
    initialState,
    reducers: {
        setFestivals: (state, action: PayloadAction<NewFestivalData[]>) => {
            state.value = action.payload;
        },
    },
});

export const {setFestivals} = festivalsSlice.actions;
export default festivalsSlice.reducer;

