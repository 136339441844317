import { createSelector } from "reselect";
import type { RootState } from "../index";

const searchQuery = (state: RootState) => state.searchQuery

export const searchQuerySelector = createSelector(
    searchQuery,
    (searchQuery) => searchQuery.value
);

