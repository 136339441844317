import {DocumentData} from "@firebase/firestore";
import {User} from "../Models/user.model";

export const SerializeUserData = (user: DocumentData): User => {
    return {
        username: user.email!,
        email: user.email!,
        uid: user.uid!,
        firstName: (user.firstName ? user.firstName : user.displayName?.includes(' ')
            ? user.displayName.split(' ')[0] : user.name),
        secondName: (user.secondName ? user.secondName : user.name),
        displayName: (user.displayName ? user.displayName : user.name),
        isAdmin: user.isAdmin || false,
        likedEvents: user.likedEvents!,
        likedArticles: user.likedArticles!,
        newsletter: (!!user.newsletter),
    };
};
