import styled from "styled-components";
import pura from "../../../Assets/pura_black.svg";
import invest from "../../../Assets/invest_black.svg";
import Button from "../../../Components/Common/Button";
import {BUTTON_STYLE} from "../../../types/style";
import gdynia from "../../../Assets/gdynia_logo.svg";
import mmg from "../../../Assets/mmg_logo.png"
import meg from "../../../Assets/meg.svg"
import LO from "../../../Assets/LOII.png"
import brydz from "../../../Assets/brydzLogo.svg"
import sygnet from "../../../Assets/sygnet.svg"
import kazstudio from "../../../Assets/kazstudio.png"
import gotowala from "../../../Assets/gotowala.png"
import lseg from "../../../Assets/lseg.png"
import mercure from "../../../Assets/mercure.png"
import trefl from "../../../Assets/trefl.png"
import ohw from "../../../Assets/ohworldwide.png"
import gca from "../../../Assets/gca.svg"
import trojmiasto from "../../../Assets/trojmiasto.svg"
import label from "../../../Assets/label.svg"
import prestiz from "../../../Assets/prestiz.svg"
import dd from "../../../Assets/Gdynia2024/DesignDoc.webp";
import rg from "../../../Assets/Gdynia2024/radio-gdańsk-logo.webp";
import wm from "../../../Assets/Gdynia2024/whitemad logo.webp";
import wy from "../../../Assets/Gdynia2024/wyborcza trojmiasto kolor png.webp";
import zp from "../../../Assets/Gdynia2024/zawsze pomorze_logo.webp"
import inne from "../../../Assets/inneSzlaki-Logo.svg"

import akwarium from "../../../Assets/Gdynia2024/akwarium.png";
import antares from "../../../Assets/Gdynia2024/antares.png";
import arg from "../../../Assets/Gdynia2024/arg.jpg";
import automobil from "../../../Assets/Gdynia2024/automobil.png";
import cech from "../../../Assets/Gdynia2024/cech.webp";
import gcf from "../../../Assets/Gdynia2024/gcf.jpg";
import ggcf from "../../../Assets/Gdynia2024/ggcf.webp";
import gliniarze from "../../../Assets/Gdynia2024/gliniarze.webp";
import przyplyw from "../../../Assets/Gdynia2024/przyplyw.webp";
import riviera from "../../../Assets/Gdynia2024/riviera.svg";
import skm from "../../../Assets/Gdynia2024/skm.webp";
import szostka from "../../../Assets/Gdynia2024/szostka.png";
import trojka from "../../../Assets/Gdynia2024/trojka.png";
import um from "../../../Assets/Gdynia2024/um.png";
import wylew from "../../../Assets/Gdynia2024/wylew.webp";
import zkmgdynia from "../../../Assets/Gdynia2024/zkmgdynia.png";
import vhshell from "../../../Assets/VHS.png";
import kosmos from "../../../Assets/kosmos.png";
import ab from "../../../Assets/AB.png";
import ts from "../../../Assets/ts.webp";


import {useEffect, useState} from "react";


function GdyniaPartners() {


    const [isMobile, setMobile] = useState(false);


    useEffect(() => {
        if (window.outerWidth < 1100) {
            setMobile(true);
        }
    }, []);


    return (
        <Wrapper isMobile={isMobile}>
            <SponsorsDiv>
                <h3> Partnerzy </h3>
                <Sponsors>
                    <div>
                        <h4> Organizator </h4>
                        <Row>
                            <a href="https://www.facebook.com/Fundacja-Pura-474586729418170/">
                                <img src={pura} alt={'logo pura'}/>
                            </a>
                            <a href="https://www.facebook.com/inneszlaki">
                                <img src={inne} alt={'logo inne'}/>
                            </a>
                        </Row>
                    </div>
                    <div>
                        <h4>Mecenat </h4>
                        <img src={invest} alt={'logo invest'}/>
                    </div>
                    <div>
                        <h4>Dofinansowano ze środków </h4>
                        <div>
                            <img src={gdynia} alt={'logo gdynia'}/>

                        </div>
                    </div>
                </Sponsors>
                <Button label="Zostań partnerem" onClick={() => console.log('zostan partnerem')}
                        buttonStyle={BUTTON_STYLE.PINK}/>
            </SponsorsDiv>


            <OtherLogos>
                <h4>Współpraca</h4>
                <Logos>
                    <img src={ohw} alt={'ohw'}/>
                    <img src={mercure} alt={'mercure'}/>
                    <img src={sygnet} alt={'sygnet'}/>
                    <img src={mmg} alt={'Muzeum Miasta Gdynia'}/>
                    <img src={meg} alt={'Muzeum Emigracji'}/>
                    <img src={lseg} alt={'lseg'}/>
                    <img src={trefl} alt={'trefl'}/>
                    <img src={kazstudio} alt={'kazstudio'}/>
                    <img src={brydz} alt={'Muzeum Kart'}/>
                    <img src={gca} alt={'gca'}/>
                    <img src={LO} alt={'LO II'}/>
                    <img src={vhshell} alt={'vhshell'}/>
                    <img src={gotowala} alt={'gotowala'}/>
                    <img src={akwarium} alt={'akwarium'}/>
                    <img src={antares} alt={'antares'}/>
                    <img src={arg} alt={'arg'}/>
                    <img src={automobil} alt={'automobil'}/>
                    <img src={cech} alt={'cech'}/>
                    <img src={gcf} alt={'gcf'}/>
                    <img src={ggcf} alt={'ggcf'}/>
                    <img src={gliniarze} alt={'gliniarze'}/>
                    <img src={przyplyw} alt={'przyplyw'}/>
                    <img src={riviera} alt={'riviera'}/>
                    <img src={skm} alt={'skm'}/>
                    <img src={szostka} alt={'szostka'}/>
                    <img src={trojka} alt={'trojka'}/>
                    <img src={um} alt={'um'}/>
                    <img src={wylew} alt={'wylew'}/>
                    <img src={zkmgdynia} alt={'zkmgdynia'}/>
                    <img src={kosmos} alt={'kosmos'}/>

                </Logos>


                <h4>Patronat Medialny</h4>
                <Logos>
                    <img src={trojmiasto} alt={'trojmiasto'}/>
                    <img src={label} alt={'label'}/>
                    <img src={prestiz} alt={'prestiz'}/>
                    <img src={dd} alt={'Design  '}/>
                    <img src={rg} alt={'Radio gdansk  '}/>
                    <img src={wm} alt={'White Mad   '}/>
                    <img src={wy} alt={'gazeta wyborcza   '}/>
                    <img src={zp} alt={'zawsze pomorze'}/>
                    <img src={ab} alt={'architektura & biznes'}/>
                    <img src={ts} alt={'tawerna skipperow'}/>

                </Logos>

            </OtherLogos>
        </Wrapper>
    )
        ;
}

export default GdyniaPartners


const SponsorsDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    padding: 40px 0 0 0;
    overflow-x: hidden;

    button {
        position: relative;
        top: -30px;
        left: 70vw;
    }

    h3 {
        font-family: Cabinet Grotesk, serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    h4 {
        margin: 0;
        color: #1F0812;
        font-family: Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.16px;
        margin-bottom: 8px;
    }

    &div {
        display: flex;
        flex-direction: column;

        & div {
            flex-direction: row;
        }
    }

    img {
        min-height: 60px;
        max-height: 70px;

        & + img {
            margin-left: 40px;
        }
    }
`
const Sponsors = styled.div`
    display: flex;
    flex-direction: row;
    gap: 100px;
`
const Wrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    padding: ${({isMobile}) => (isMobile ? '12px' : ' 120px 40px 0px 40px')};
    gap: ${({isMobile}) => (isMobile ? '12px' : ' 24px')};
`

const OtherLogos = styled.div`
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin-bottom: 20px;
`
const Logos = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center ;
    gap: 48px;
    padding: 12px 0;
    margin-bottom: 40px;


    img {
        max-width: 120px;
        max-height: 80px;
        object-fit: contain;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;`
