import styled from "styled-components";

export const Wrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    padding: ${({isMobile}) => (isMobile ? '16px' : '100px 40px')};

    button {
        height: 30px;
        padding: 24px 16px;
        margin-left: 24px;
    }

    h2 {
        color: #1F0812;
        font-family: Cabinet Grotesk;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 40px;
    }

    .pagination {
        display: flex;
        margin-top: 20px;
    }


    .pagination button {
        width: 32px;
        height: 32px;
        border: 1px solid #E5E4E7;
        background-color: #fff;
        cursor: pointer;
        margin: 0 4px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0; /* Remove padding */
        color: #1F0812;
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }

    .pagination button.active {
        background-color: #ff69b4;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        top: 0;
        left: 0;

        ::before {
            width: 0px;
            background: none;
            display: none;
            position: initial;
        }
    }

    .pagination button:disabled {
        background-color: #ccc;
        color: #888;
        cursor: not-allowed;
    }

    .pagination span {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 4px;
        padding: 0; /* Remove padding */
    }
`

export const ResultDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    gap: 18px;

    img {
        width: 58px;
        height: 58px;
    }
`

export const ResultContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 706px;

    h3 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%
    }

    p {
        color: #1F0812;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    button {
        max-width: 180px;
        margin-left: 0;
    }
`

export const InputDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    margin-bottom: 20px;
    margin-top: 44px;
    align-items: center;

    button {
        position: relative;
        float: left;
        margin: 0;
        margin-left: 12px;
        width: 160px;
    }
`

    export const Breadcrumb = styled.nav`
        a {
            text-decoration: none;
            color: #514D58;
    
            &:last-of-type {
                color: #1F0812;
            }
        }
    
    
        ol {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    
        li {
            text-decoration: none;
            color: #514D58;
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            cursor: pointer;
    
            &:last-of-type {
                font-size: 18px;
            }
        }
    
        span {
            padding: 0 8px;
        }
    
    
        a:hover {
            color: #3e1daa;
        }
    `

export const ContentDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

export const Results = styled.div`
    display: flex;
    flex-direction: column;
`

export const Display = styled.div`
    float: right;
    position: relative;
    top: -120px;
    margin-left: 150px;
`
