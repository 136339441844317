import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface DeleteEventModalState {
    value: string
}

const initialState: DeleteEventModalState = {
    value: '',
};

export const deleteEventModalSlice = createSlice({
    name: 'deleteEventModal',
    initialState,
    reducers: {
        setDeleteEventModal: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
    },
});

export const {setDeleteEventModal} = deleteEventModalSlice.actions;
export default deleteEventModalSlice.reducer;

