import styled from "styled-components";

export const FestivalBox = styled.div`
  background-color: white;
  color: black;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  img {
    margin-left: 20px;
    height: 140px;
    width: auto;
    align-self: center;
  }`

export const CollapsibleHead= styled.div`
display: flex;
flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
