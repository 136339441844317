import styled from "styled-components";

export const StyledQuoteSection = styled('section')<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 524px;
  padding: ${({isMobile}) => (isMobile ? '48px 8px' : '152px 270px')};
  font-family: Cabinet Grotesk;
  font-size: ${({isMobile}) => (isMobile ? '32px' : '46px')};
  font-style: normal;
  line-height: 120%;
  max-width: 900px;
  
  h4 {
    color:  #1F0812;
    text-align: center;
    font-weight: 500;
    
    span {
      color: #FF59A9;
      font-weight: 700;
    }
  }
`;
