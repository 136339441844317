import React from 'react';
import styled from "styled-components";
import deleteFestival from "../../../Utilities/deleteFestival";
import {useAppDispatch} from "../../../Store/hooks";
import {fetchFestivals} from "../../../Utilities/fetchFestivals";
import {setDeleteFestivalModal} from "../../../Store/slices/deleteFestivalModalSlice";
import {IoMdClose} from "react-icons/io";

type Props = {
    id: string;
}

const DeleteConfirmModal = (props: Props) => {
const dispatch = useAppDispatch();

    const deleteHandler = async (id: string) =>{
        await deleteFestival(id);
        await fetchFestivals(dispatch);
        dispatch(setDeleteFestivalModal(''));
    }

    return (
        <ModalWrapper>
            <Whiteout/>
            <ContentBox>
                <Icon>
                    <IoMdClose onClick={()=>dispatch(setDeleteFestivalModal(''))}/>
                </Icon>
            <h1>Uwaga</h1>
            <p>Czy  napewno chcesz usunąć festiwal?</p>
            <button onClick={()=>deleteHandler(props.id)} >OK</button>
            <button onClick={()=>dispatch(setDeleteFestivalModal(''))}>cancel</button>
            </ContentBox>
        </ModalWrapper>
    );
}

export default DeleteConfirmModal


const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Whiteout = styled.div`
  background: gray;
  opacity: 0.7;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
`

const ContentBox = styled.div`
  background: white;
  height: 600px;
  width: 700px;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;

  span {
    margin-right: 10px;
  }

  button{
    margin-top: 20px;
  }
`
const Icon = styled.div`
  width: 100%;
  position: absolute;
  left: 95%;
  top: 4%;
`
