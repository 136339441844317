import React, {Dispatch, SetStateAction} from 'react';
import styled from "styled-components";
import {FestivalFormInterface, FestivalLocation} from "../../Models/festival.model";
import {useFormik} from "formik";
import AddImagesModal from "../../Components/Common/AddImagesModal";
import {AdminPagesDisplay} from "../../Models/menu.model";

type Props = {
    submit: (values?: any) => void;
    setPage: Dispatch<SetStateAction<AdminPagesDisplay>>;
}

const AdminPanelAddFestival = (props: Props) => {

    const formik = useFormik({
        initialValues: {
            location: '',
            startDate: '',
            endDate: '',
            title: '',
            description: '',
            images: [],
        },

        // validationSchema: festivalSchema,
        onSubmit: (values: FestivalFormInterface) => {
            props.submit(values)
            props.setPage(AdminPagesDisplay.festivals)
        }
    });

    return (
        <FormWrap>
            <h2>Dodaj Festiwal</h2>
            <form onSubmit={formik.handleSubmit}>
                <FormUnit>
                <label htmlFor="title">Nazwa</label>
                <input
                    id="title"
                    name="title"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    style={{
                        backgroundColor: formik.touched.title && formik.errors.title ? "#FFD8C9" : "",
                        color: formik.touched.title && formik.errors.title ? "red" : ""
                    }}
                />
                {formik.touched.title && formik.errors.title ? (
                    <ErrorDiv>{formik.errors.title}</ErrorDiv>
                ) : null}

                <label htmlFor="description">Opis</label>
                <textarea
                    id="description"
                    name="description"
                    rows={10}
                    cols={50}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                />
                    <p>FYI: Aby wygenerować nowy akapit - po spacji napisz: /n </p>
                </FormUnit>

                <FormUnit>
                <label htmlFor="location"> Lokalizacja </label>
                <select
                    id="location"
                    name="location"
                    onChange={formik.handleChange}
                    value={formik.values.location}>
                    <option value="">Wybierz lokalizację</option>
                    <option value={FestivalLocation.Gdansk}>Gdańsk</option>
                    <option value={FestivalLocation.Sopot} disabled>Sopot</option>
                    <option value={FestivalLocation.Gdynia}>Gdynia</option>
                </select>

                <label htmlFor="startDate">Data rozpoczęcia</label>
                <input
                    id="startDate"
                    name="startDate"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.startDate}
                />

                <label htmlFor="endDate">Data zakończenia</label>
                <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.endDate}
                />
                </FormUnit>

                <AddImagesModal/>

                <button type="submit">Dodaj Festiwal</button>
            </form>
        </FormWrap>
    )
};
export default AdminPanelAddFestival

const FormWrap = styled.div`
  width: 65%;
  height: auto;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin: 0 auto;

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  
  label{
   margin-bottom: 5px;
  }
  input{
    padding: 6px 3px;
    margin-bottom: 12px;
  }
  textarea{
    padding: 6px 3px;
    margin-bottom: 12px;
  }
  select{
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  button{
    width: 200px;
  }
`
const ErrorDiv = styled.div`
  color: red;
`
const FormUnit = styled.div`
    background-color: #ECEFF1;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`
