import {useMap} from "react-leaflet";
import {useEffect} from "react";

//todo: move to hook
const RecenterMap = ({lat, lng}: {lat:number, lng:number}) => {
    const map = useMap();
    useEffect(() => {
        map.setView([lat, lng]);
    }, [map, lat, lng]);
    return null;
}

export default RecenterMap
