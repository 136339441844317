import {Link, Navigate, Outlet} from "react-router-dom";
import AdminPanelWrapper from "./AdminPanelWrapper";

function AdminPanel({isAdmin}) {

    if (!isAdmin) {
        return (
            <Navigate to="/" replace/>
        );
    }

    return (
        <AdminPanelWrapper/>
    );
}

export default AdminPanel
