export default function dateName(date : string ) {
    return new Date(date)
        .toLocaleDateString('default', {weekday: 'long'})
        .split('')
        .map((l,index)=>{
        if (index === 0) return l.toUpperCase()
        else return l})
        .join('');
}




