import styled from "styled-components";

export const BookingDiv = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    width: ${props => (props.isMobile ? '92vw;' : '54vw')};
`


export const BookingsDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const UserDataDiv = styled.div<{isMobile: boolean}>`
    display: flex;
  flex-direction: column;
  gap: 24px;
    width: ${props => (props.isMobile ? '92vw;' : '30vw')};
`


export const DivClickable = styled.div`
    display: flex;
    flex: 1;
    width: 300px;
    height: 31px;
    padding: 16px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 2px solid #8E8A95;
    background: #FBFDFF;
`

export const SuccessDiv = styled.div`
  width: 330px;
  background-color: #93e58f;
  font-family: 'Cabinet Grotesk', serif;
  font-size: 20px;
  font-weight: 600;
  color: #1d590b;
  padding: 10px;
  margin-bottom: 10px;

  span {
    position: relative;
    left: 57%;
    color: #07080D;
  }
`
export const InputDiv= styled.div`
    margin-top: 12px;
display: flex;
    flex-direction: column; 
    gap 12px;

    input {
        flex: 1;
        width: 300px;
        height: 31px;
        padding: 16px 24px;
        align-items: center;
        gap: 80px;
        border-radius: 60px;
        border: 2px solid #8E8A95;
        background: #FBFDFF;
    }
    
    label{
        color: #514D58;
        font-family: Cabinet Grotesk;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }
`;









