import {useEffect} from 'react';
import styled from "styled-components";
import NewFooter from "./Components/Footer/NewFooter";
import {setIsMobile} from "./Store/slices/isMobileSlice";
import {CookieConsent} from "react-cookie-consent";
import {useAppDispatch, useAppSelector} from "./Store/hooks";
import {isMobileSelector} from "./Store/selectors/isMobileSelector";
import {AppLayout} from "./Router";
import {BrowserRouter as Router} from "react-router-dom";


function App() {

    const dispatch = useAppDispatch()
    dispatch(setIsMobile(window.outerWidth < 1100));
    const isMobile = useAppSelector(isMobileSelector);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Router>
            <AppWrapper>
                <AppLayout/>
                <NewFooter/>
                <CookieConsent buttonText="Akceptuję"
                               style={{background: "#07080D"}}
                               buttonStyle={{
                                   fontFamily: 'Cabinet Grotesk',
                                   fontWeight: 600,
                                   border: 'transparent',
                                   borderRadius: '25px',
                                   padding: '10px',
                                   color: '#07080D',
                                   background: '#FFF75F',
                                   fontSize: '12px',
                                   width: '150px',
                                   textTransform: 'uppercase',
                               }}
                               expires={150}>
                    Ta strona używa ciasteczek. Zapoznaj się z regulaminem oraz polityką prywatności.
                </CookieConsent>
            </AppWrapper>
        </Router>
    );
}

export default App;

const AppWrapper = styled.div`
    max-width: 100vw;
    margin: 0 auto;
    overflow: hidden;
`


