import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface DeleteBlogModalState {
    value: string;
}

const initialState: DeleteBlogModalState = {
    value: ''
};

export const deleteBlogModalSlice = createSlice({
    name: 'deleteBlogModal',
    initialState,
    reducers: {
        setDeleteBlogModal: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
    },
});

export const {setDeleteBlogModal} = deleteBlogModalSlice.actions;
export default deleteBlogModalSlice.reducer;

