import React, {Dispatch, SetStateAction} from 'react';
import styled from "styled-components";
import {useFormik} from "formik";
import {AdminPagesDisplay} from "../../Models/menu.model";
import AddImagesModal from "../../Components/Common/AddImagesModal";

type Props = {
    submit: (values?: any) => void;
    setPage: Dispatch<SetStateAction<AdminPagesDisplay>>;
}

const AdminPanelAddBlog = (props: Props) => {

    const formik = useFormik({
        initialValues: {
            title: '',
            content: '',
            imageUrl: [],
            imageLabels: [],
            author: '',
            tags: '',
            layout: 1,
        },

        onSubmit: (values: any) => {
            props.submit(values);
            props.setPage(AdminPagesDisplay.blog)
        }
    });

    return (
        <FormWrap>
            <h2>Dodaj Artykuł</h2>
            <form onSubmit={formik.handleSubmit}>
                <FormUnit>
                    <label htmlFor="title">Tytuł</label>
                    <input
                        id="title"
                        name="title"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        style={{
                            backgroundColor: formik.touched.title && formik.errors.title ? "#FFD8C9" : "",
                            color: formik.touched.title && formik.errors.title ? "red" : ""
                        }}
                    />
                    {formik.touched.title && formik.errors.title ? (
                        <ErrorDiv>{formik.errors.title}</ErrorDiv>
                    ) : null}

                    <label htmlFor="content">Treść</label>
                    <textarea
                        id="content"
                        name="content"
                        rows={10}
                        cols={100}
                        onChange={formik.handleChange}
                        value={formik.values.content}
                    />
                    <p>FYI: Aby wygenerować nowy akapit - po spacji napisz: /n </p>
                </FormUnit>
                <FormUnit>
                    <label htmlFor="author"> Autor</label>
                    <input
                        type="text"
                        id="author"
                        name="author"
                        onChange={formik.handleChange}
                        value={formik.values.author}>
                    </input>

                    <label htmlFor="tags">Tagi (podawaj po przecinku)</label>
                    <input
                        type="text"
                        id="tags"
                        name="tags"
                        onChange={formik.handleChange}
                        value={formik.values.tags}>
                    </input>


                    <label htmlFor="layout"> Layout </label>
                    <select
                        id="layout"
                        name="layout"
                        onChange={formik.handleChange}
                        value={formik.values.layout}>
                        <option value="">Wybierz layout</option>
                        <option value={1}>1</option>
                        <option value={2} disabled>2</option>
                        <option value={3} disabled>3</option>
                    </select>
                </FormUnit>
                <AddImagesModal/>

                <button type="submit">Dodaj Artykuł</button>
            </form>
        </FormWrap>
    )
};
export default AdminPanelAddBlog

const FormWrap = styled.div`
  width: 65%;
  height: auto;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin: 0 auto;

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  label {
    margin-bottom: 5px;
  }

  input {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  textarea {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  select {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  button {
    width: 200px;
  }
`
const ErrorDiv = styled.div`
  color: red;
`
const FormUnit = styled.div`
  background-color: #ECEFF1;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`
