import {useState} from "react";
import {addDoc, collection} from "@firebase/firestore";
import {FirebaseCollections} from "../../Constants/firebaseCollections";
import {db} from "../../config";
import {useAppSelector} from "../../Store/hooks";
import {EventSubmitData} from "../../Models/event.model";
import {selectedImageUrlsLabelsSelector} from "../../Store/selectors/imageUrlsLabelsSelector";
import {addressGeocoding} from "../addressGeocoding";


const useAddEvent = () => {
    const [isPending, setIsPending] = useState(false);
    let addedEventId = "";
    const imagesWithLabels = useAppSelector(selectedImageUrlsLabelsSelector);

    const addNewEvent = async (eventData: EventSubmitData) => {
        let success = true;
        setIsPending(true);
        console.log(eventData);
        const coordinates = await addressGeocoding(eventData.address)
        try {
            const doc = await addDoc(collection(db, FirebaseCollections.Events), {
                ...eventData,
                position: coordinates,
                images: imagesWithLabels,
                isVisible: false,
            });
            addedEventId = doc.id;
        } catch (err) {
            success = false;
            console.log(err);
        }
        setIsPending(false);
        return {success, addedEventId};
    };
    return {addNewEvent, isPending};
};

export default useAddEvent
