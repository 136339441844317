import {AuthCode} from "../types/constants";

export function mapError(authCode: AuthCode){
        switch (authCode) {
            case AuthCode.InvalidPassword:
                return "Błędne hasło";

            case AuthCode.InvalidEmail:
                return "Błędny email.";

            case AuthCode.WrongPassword:
                return "Błędne hasło";

            case AuthCode.UserNotFound:
                return "Nie ma użytkownika przypisanego do tego adresu.";

            case AuthCode.EmailAlreadyInUse:
                return "Użytkownik o tym adresie email już istnieje";

            case AuthCode.PasswordsDoNotMatch:
                return "Hasła się nie zgadzają";

            default:
                return "";
        }
    }
