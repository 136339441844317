import Button from "../../../Components/Common/Button";
import {BUTTON_STYLE} from "../../../types/style";
import Slider from "./Slider";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../Store/hooks";
import {selectedCitySelector} from "../../../Store/selectors/selectedCitySelector";
import {
    AboutOpenHousePage,
    ButtonContainer,
    Column,
    DescriptionContainer,
    DescriptionDiv,
    Line,
    StatBox,
    Text, Wrapper
} from "./AboutOpenHouse.styles";

interface AboutOpenHouseProps {
    isMobile: boolean;
}

function AboutOpenHouse({isMobile}: AboutOpenHouseProps) {

    const navigate = useNavigate();

    function archiveButtonHandler() {
        navigate("/notfound");
    };

    return (
        <Wrapper isMobile={isMobile}>
            <AboutOpenHousePage isMobile={isMobile}>
                {isMobile && <h2>Czym jest <span>Open</span> House?</h2>}
                {!isMobile && <Slider isMobile={isMobile}/>}
                <DescriptionDiv>
                    {!isMobile && <h2>Czym jest <span>Open</span> House?</h2>}
                    <DescriptionContainer isMobile={isMobile} style={{marginTop: !isMobile ? 140 : 0}}>
                        <Column isMobile={isMobile} style={{marginBottom: 20}}>
                            <Text>
                                Jesteśmy częścią międzynarodowej rodziny festiwali poświęconych architekturze i miastu.
                                Otwieramy drzwi do miejsc intrygujących, zaskakujących i poruszających. Każdego roku
                                skupiamy się na innej dzielnicy-bohaterce, poznając jej najnowsze oblicze i odświeżając
                                związane z nią wspomnienia.
                            </Text>
                            <Text>
                                Open House, jak sama nazwa wskazuje, zaprasza do odwiedzania przestrzeni – mieszkań i
                                domów,
                                pracowni, biur, instytucji publicznych. Dzielnice zaskakują i nas, i Was.
                            </Text>
                        </Column>
                        <Column isMobile={isMobile}>
                            <Text>
                                Nawet najbardziej znane miejsca skrywają bowiem tajemnice, które wspólnie z lokalną
                                społecznością eksplorujemy. Zwiedzanie to jednak nie wszystko. Dbamy o to, by każda
                                edycja
                                wzmacniała w Was pasję do architektury za pomocą wykładów i debat, wystaw, warsztatów,
                                dedykowanych oprowadzań.
                            </Text>
                            <Text>
                                Wszystko po to, by poznawać – siebie nawzajem, miejskie struktury, siebie w kontekście
                                architektury.
                            </Text>
                        </Column>
                    </DescriptionContainer>
                    <DescriptionContainer isMobile={isMobile}>
                        <StatBox isMobile={isMobile}>
                            <Line/>
                            <h4>9</h4>
                            <p>edycji w Gdyni</p>
                        </StatBox>
                        <StatBox isMobile={isMobile}>
                            <Line/>
                            <h4>6</h4>
                            <p>edycji w Gdańsku</p>
                        </StatBox>
                        <StatBox isMobile={isMobile}>
                            <Line/>
                            <h4>490</h4>
                            <p>lokalizacji</p>
                        </StatBox>
                    </DescriptionContainer>
                    <ButtonContainer isMobile={isMobile}>
                        <Button label="Zobacz poprzednie edycje" onClick={archiveButtonHandler}
                                buttonStyle={BUTTON_STYLE.GREEN}/>
                    </ButtonContainer>
                </DescriptionDiv>
            </AboutOpenHousePage>
        </Wrapper>
    );
}

export default AboutOpenHouse;

