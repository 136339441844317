import {doc, setDoc} from "@firebase/firestore";
import {db} from "../config";
import {BookingData} from "../types/events";

const deleteBooking = async (
    id: string,
    bookings: BookingData[],
    userUid: string,
) => {
    try {

        if (id === undefined) {
            return;
        }
        const docRef = doc(db, 'events', id)
        console.log(docRef);

        await setDoc(docRef, {
            bookings: bookings.filter(booking => booking.userUid !== userUid),
            id: id
        }, {merge: true});

    } catch (err) {
        alert('Wystąpił błąd!')
        console.log(err);
    }
}

export default deleteBooking

