import {useState} from "react";
import {NewFestivalData} from "../../Models/festival.model";
import {addDoc, collection} from "@firebase/firestore";
import {FirebaseCollections} from "../../Constants/firebaseCollections";
import {db} from "../../config";
import {useAppSelector} from "../../Store/hooks";
import {selectedImageUrlsLabelsSelector} from "../../Store/selectors/imageUrlsLabelsSelector";

const useAddFestival = () => {
    const [isPending, setIsPending] = useState(false);
    let addedFestivalId = "";
    const imagesWithLabels = useAppSelector(selectedImageUrlsLabelsSelector);

    const addNewFestival = async (festivalData: NewFestivalData) => {
        let success = true;
        setIsPending(true);
        try {
            const doc = await addDoc(collection(db, FirebaseCollections.Festivals), {
                ...festivalData,
                isCurrent: false,
                images: imagesWithLabels,
            });
            addedFestivalId = doc.id;
        } catch (err) {
            success = false;
            console.log(err);
        }
        setIsPending(false);
        return {success, addedFestivalId};
    };
    return {addNewFestival, isPending};
};

export default useAddFestival;
