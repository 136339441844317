import styled from "styled-components";
import {useEffect, useState} from "react";

interface SliderProps {
isMobile: boolean
    circleColor? : string | undefined
}

function Slider({isMobile, circleColor}: SliderProps) {

    const images = [
        'https://storage.cloud.google.com/openhouse_trojmaisto/s1.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s3.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s4.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s5.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s6.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s7.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s8.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s9.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s10.png',
        'https://storage.cloud.google.com/openhouse_trojmaisto/s11.png',
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [images])

    return (
        <>
        <StyledSlider  isMobile={isMobile} circleColor={circleColor}>
            <EmptyOval isMobile={isMobile} circleColor={circleColor}/>
            <EmptyOval isMobile={isMobile} circleColor={circleColor}/>
            <EmptyOval isMobile={isMobile} circleColor={circleColor}/>
            <EmptyOval isMobile={isMobile} circleColor={circleColor}/>
            {images.map((image, index) => (
                <ImageSlide key={index} isActive={index === activeIndex} isMobile={isMobile}>
                    <img src={image} alt={`Slide ${index}`} />
                </ImageSlide>
            ))}
        </StyledSlider>

        </>
    );
}

export default Slider;


interface ImageSlideProps {
    isActive: boolean;
    isMobile: boolean;
}

const StyledSlider = styled.div<{isMobile:boolean, circleColor: string | undefined}>`
  position: relative; 
  width: ${({isMobile}) => (isMobile ? '300px' : '440px')};
  height: ${({isMobile}) => (isMobile ? '42vh' : '80vh')};
  border-radius: 248px;

  margin: 0 auto;
`

const EmptyOval = styled.div<{isMobile:boolean, circleColor: string | undefined}>`
  opacity: 1;
  position: absolute;
  content: "";
  width: ${({isMobile}) => (isMobile ? '270px' : '440px')};
  height: ${({isMobile}) => (isMobile ? '270px' : '440px')};
  border-radius: 248px;
  border: 2px solid;
  border-color: ${({circleColor}) => (circleColor ? circleColor : '#FBFDFF')};
  top:  ${({isMobile}) => (isMobile ? '70px' : '180px')};
  z-index: 2;

  :nth-of-type(2){
    top:  ${({isMobile}) => (isMobile ? '110px' : '220px')};
    z-index: 2;
  }
   :nth-of-type(3){
     top: ${({isMobile}) => (isMobile ? '150px' : '260px')};
     z-index: 2;
   }
   :nth-of-type(4){
     top: ${({isMobile}) => (isMobile ? '190px' : '300px')};
     z-index: 2;
   }
  `

const ImageSlide = styled.div<ImageSlideProps>`
  position: absolute;
  width: ${({isMobile}) => (isMobile ? '270px' : '440px')};
  height: ${({isMobile}) => (isMobile ? '270px' : '588px')};
  border-radius: 248px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  
  img{
    width: ${({isMobile}) => (isMobile ? '270px' : '440px')};
    height: ${({isMobile}) => (isMobile ? '270px' : '588px')};
    object-fit: cover;
    border-radius: 248px;
  }
`;
