import styled from "styled-components";
import Button from "../../../Components/Common/Button";
import {useNavigate} from "react-router-dom";
import {BORDER_STYLE, BUTTON_STYLE} from "../../../types/style";
import Entry from "./NewestBlogEntry";
import maxLength from "../../../Utilities/maxLength";
import {useAppSelector} from "../../../Store/hooks";
import {blogSelector} from "../../../Store/selectors/blogSelector";
import { StyledNewestBlogSection, SectionHeader, FirstEntry, ImageContainer, NextEntries, ContentContainer, TextContainer, EmptyCircle } from "../Component/NewestBlogSection.styles";

function NewestBlogSection({isMobile}) {

    const blogEntries = useAppSelector(blogSelector);
    const navigate = useNavigate();

    function blogButtonHandler() {
        navigate("/blog");
    };

    if (!blogEntries?.length) return <div>Loading...</div>
    return(
        <StyledNewestBlogSection isMobile={isMobile}>
            <SectionHeader isMobile={isMobile}>
                <h2>Najnowsze wpisy</h2>
                <Button label="Zajrzyj na bloga" onClick={blogButtonHandler} buttonStyle={BUTTON_STYLE.PINK}/>
            </SectionHeader>
            {!isMobile && <FirstEntry isMobile={isMobile}>
                <ImageContainer isMobile={isMobile}>
                    <img src={blogEntries[0].images[0].url}/>
                    <EmptyCircle index={1} isMobile={isMobile}/>
                    <EmptyCircle index={2} isMobile={isMobile}/>
                    <EmptyCircle index={3} isMobile={isMobile}/>
                </ImageContainer>
                <ContentContainer isMobile={isMobile}>
                    <TextContainer isMobile={isMobile}>
                        <h4>{blogEntries[0].title}</h4>
                        <p>
                            {maxLength(blogEntries[0].content, 350)}
                        </p>
                        <Button label='Czytaj więcej' onClick={() => navigate(`blog/${blogEntries[0].id}`)}
                                buttonStyle={BUTTON_STYLE.SECONDARY}/>
                    </TextContainer>
                </ContentContainer>
            </FirstEntry>}
            <NextEntries isMobile={isMobile}>
                {isMobile &&       <Entry
                    borderStyle={BORDER_STYLE.BOTTOM_RIGHT}
                    imageSrc={blogEntries[0].images[0].url}
                    id={blogEntries[0].id}
                    title={blogEntries[0].title}
                    content={maxLength(blogEntries[0].content, 350)}
                />}
                <Entry
                    borderStyle={BORDER_STYLE.BOTTOM_RIGHT}
                    imageSrc={blogEntries[1].images[0].url}
                    id={blogEntries[1].id}
                    title={blogEntries[1].title}
                    content={maxLength(blogEntries[1].content, 350)}
                />
                <Entry
                    imageSrc={blogEntries[2].images[0].url}
                    id={blogEntries[2].id}
                    title={blogEntries[2].title}
                    content={maxLength(blogEntries[2].content, 350)}
                />
                <Entry
                    borderStyle={BORDER_STYLE.TOP_LEFT}
                    imageSrc={blogEntries[3].images[0].url}
                    id={blogEntries[3].id}
                    title={blogEntries[3].title}
                    content={maxLength(blogEntries[3].content, 350)}
                />
            </NextEntries>
        </StyledNewestBlogSection>);
}

export default NewestBlogSection;
