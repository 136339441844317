import {Marker, useMap, Popup} from "react-leaflet";
import {useEffect} from "react";
import RenderPoint from "./MapSinglePoint";
import {useNavigate} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";


const MapPoint = ({id, position, event, index, activePoint, setActivePoint, events}: any) => {
    const map = useMap();
    const navigate = useNavigate();

    useEffect(() => {
        if (activePoint === id) {
            map.setView(position);
        }
    }, [activePoint]);

    const otherEvents = (events.filter(ev => ev.position[0] === position[0] && ev.position[1] === position[1]));

    return (
        <Marker
            position={position}
            name={event.title}
            icon={RenderPoint(id, activePoint, event.bookingUrl, event.title.split('.')[0])}
            fill={event.bookingUrl ? '#6F48EE' : "#FF59A9"}
            borderRadius={'50%'}
            offset={[0, -20]}>

            <Popup direction="bottom" offset={[0, -20]}
                   interactive={true}
                   className="custom-tooltip"
            >
                {otherEvents.map((event) => (
                    <div key={event.id}>
                        {event.images[0] && <img src={event.images[0].url} alt={'image'}/>}
                        <h1>{event.title}</h1>
                        <p>{event.address}</p>
                        <p>{event.address2}</p>
                        <div>
                            <button onClick={() => navigate(`${event.id}`)}>
                                Szczegóły wydarzenia
                            </button>
                        </div>
                    </div>
                ))}
            </Popup>
        </Marker>
    );
}
export default MapPoint;

