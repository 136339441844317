import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import validator from "validator";
import {logInWithEmailAndPassword} from "../config";
import bg from "../Assets/Autumn2023/bg.webp";
import {useNavigate} from "react-router-dom";
import InputField from "../Components/Common/InputField/InputField";
import {BUTTON_STYLE} from "../types/style";
import Button from "../Components/Common/Button";
import {mapError} from "../Utilities/mapError";


const LoginPage = () => {
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const navigate = useNavigate();
    const emailRef = useRef<HTMLInputElement | null>(null);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [formValid, setFormValid] = useState(false)

    useEffect(() => {
        if (window.outerWidth < 1100) {
            setIsMobile(true);
        }
    }, []);

    useEffect(() => {
        const emailValue = emailRef.current?.value || '';
        const passwordValue = passwordRef.current?.value || '';
        setFormValid(emailValue.trim() !== '' && passwordValue.trim() !== ''); }, [emailRef.current, passwordRef.current]);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const emailValue = emailRef.current?.value || "";
        const passwordValue = passwordRef.current?.value || "";

        if (!validateEmail(emailValue) || !validatePassword(passwordValue)) {
            return;
        }

        try {
           const user =  await logInWithEmailAndPassword(emailValue, passwordValue);
            setEmailError('');
            navigate("/user");

        } catch (e: any) {
            setEmailError(mapError(e.code));
        }
    };


    const validateEmail = (email: string) => {
        if (!validator.isEmail(email) && emailRef.current !== document.activeElement) {
            setEmailError("Podaj poprawny email");
            return false;
        } else {
            setEmailError("");
            return true;
        }
    };

    const validatePassword = (password: string) => {
        if (
            validator.isEmpty(password) &&
            passwordRef.current !== document.activeElement
        ) {
            setPasswordError("Hasło jest wymagane");
            return false;
        } else {
            setPasswordError("");
            return true;
        }
    };

    return (
        <ContentWrapper isMobile={isMobile}>
            {!isMobile && <img src={bg}/>}
            <FormContainer>
                <LoginTitle>Zaloguj się</LoginTitle>
                <form onSubmit={handleSubmit}>
                    <InputField
                        placeholder="Podaj swój adres e-mail"
                        label="Twój e-mail"
                        ref={emailRef}
                        error={emailError}
                        isMobile={isMobile
                        }
                    />
                    <InputField
                        placeholder="Wprowadź hasło"
                        label="Hasło"
                        type="password"
                        ref={passwordRef}
                        isMobile={isMobile}
                    />
                    <ButtonSpan isMobile={isMobile}>
                        <Button buttonStyle={BUTTON_STYLE.PINK} label="Zaloguj" disabled={false} onClick={handleSubmit}/>
                    </ButtonSpan>

                </form>
                <DivButton onClick={() => navigate('/forgot')}>
                    Nie pamiętam hasła.
                </DivButton>
                <DivButton onClick={() => navigate('/register')}>
                    Nie mam konta. Chcę się zarejestrować.
                </DivButton>
            </FormContainer>
        </ContentWrapper>
    );
};

export default LoginPage;

const ButtonSpan = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: ${({isMobile}) => (isMobile ? 'flex-start' : 'center')};
  margin-top: 12px;

  p {
    font-family: "Lato", serif;
    font-size: 1.5rem;
      margin-top: 0 !important;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const ContentWrapper = styled.div<{ isMobile: boolean }>`
  width: 100vw;
  background: #FBFDFF;
  display: flex;
  padding: ${({isMobile}) => (isMobile ? '16px' : ' 120px 40px 40px 40px')};
  gap: 115px;

  img {
    width: 40vw;

    @media (max-width: 1000px) {
      width: 90%;
      height: auto;
    }
  }

  @media (max-width: 500px) {
    height: auto;
  }
`

export const FormContainer = styled.div`
  position: relative;
  width: 500px;
  top: 20%;
  display: flex;
  flex-direction: column;
  gap:12px;

  p {
    color: #514D58;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.16px;
    margin-top: 8px;
  }


  form {
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    width: 280px;
    top: 3%;
  }
`

export const LoginTitle = styled.div`
  color: #3E1DAA;
  font-family: Cabinet Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  padding-bottom: 20px;
`
export const DivButton = styled.div`
  margin-top: 12px;
  color: #3E1DAA;
  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;

  :hover {
    font-weight: 800;
  }
`
