import bg from "../../Assets/Autumn2023/404bg.png"
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";


function ComingSoonPage() {

    const navigate = useNavigate();

    return (
        <Wrapper>
            <img src={bg}/>
            <Content>
                <h1>Jesienią wrócimy do was</h1>
                <h2>wraz z nowym programem.</h2><br/>
                <h2> Wróć do drzwi frontowych.</h2>
                <ButtonSpan>
                    <Button label="Przejdź na stronę główną" onClick={()=>navigate('/')} buttonStyle={BUTTON_STYLE.GREEN}/>
                </ButtonSpan>
            </Content>
        </Wrapper>
    );
}

export default ComingSoonPage;

const Wrapper = styled.div`
    img{
      width: 1440px;
      height: 101vh;
    }
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  z-index: 2;
  
  h1{
    color:  #FBFDFF;
    text-align: center;
    font-family: Cabinet Grotesk;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
  
  h2{
    color:  #FBFDFF;
    text-align: center;
    font-family: Cabinet Grotesk;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
    `
const ButtonSpan = styled.div`
    margin-top: 20px;
`
