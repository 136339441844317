import styled from "styled-components";

export const Wrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    background-color: ${props => props.theme.blue};
    padding: ${({isMobile}) => (isMobile ? '10px' : '56px 40px')};

`

export const AboutOpenHousePage = styled.section<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    overflow-y: hidden;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    padding: ${({isMobile}) => (isMobile ? '10px' : '56px 40px')};
    opacity: 1;
    gap:  ${({isMobile}) => (isMobile ? '10px' : '80px')};

    h2 {
        color: #FBFDFF;
        font-family: Cabinet Grotesk;
        font-size: 66px;
        font-style: normal;
        font-weight: 700;
        line-height: ${({isMobile}) => (isMobile ? '110%' : '90%')}
    }

    span {
        padding: 3px 16px;
        border-radius: 360px;
        border: 2px solid #E2FD5F;
    }

`;

export const DescriptionDiv = styled('div')`
  display: flex;
  flex-direction: column;
    align-items: flex-start;

`
export const DescriptionContainer = styled.div<{ isMobile: boolean }>`
  width: ${({isMobile}) => (isMobile ? '100%' : '800px')};
  display: flex;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  padding-bottom: ${({isMobile}) => (isMobile ? '24px' : '48px')};
  margin: 0;
`;

export const ButtonContainer = styled.div<{ isMobile: boolean }>`
  width: ${({isMobile}) => (isMobile ? '100%' : '800px')};
  display: flex;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  justify-content: center;
  flex-shrink: 1;
  padding: ${({isMobile}) => (isMobile ? '24px 0' : '48px 0')};
`;

export const Column = styled.div<{ isMobile: boolean }>`
  width: ${({isMobile}) => (isMobile ? '95%' : 'calc(50% - 20px)')};
`;

export const Text = styled.p`
  color: #FBFDFF;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
`;

export const StatBox = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 8px;
  color: #FBFDFF;
  font-family: Arial;
  height: 42px;
  gap: ${({isMobile}) => (isMobile ? '18px' : '8px')};
  margin-bottom: ${({isMobile}) => (isMobile ? '18px' : '0')};

  h4 {
    font-size: 58px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%; /* 58px */
    margin: 0;
  }

  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    padding-left: 5px;
  }
`;

export const Line = styled.div`
  position: relative;
  content: '';
  width: 2px;
  height: 33px;
  background: #E2FD5F;
  top: -10px;
`
