import React, {useState} from 'react';
import styled from "styled-components";
import {FestivalLocation, NewFestivalData} from "../../Models/festival.model";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import DeleteConfirmModal from "./Messages/DeleteConfirmModal";
import {deleteFestivalModalSelector} from "../../Store/selectors/deleteFestivalModalSelector";
import {setDeleteFestivalModal} from "../../Store/slices/deleteFestivalModalSlice";
import updateIsFestivalActive from "../../Utilities/updateIsFestivalActive";
import {fetchFestivals} from "../../Utilities/fetchFestivals";
import EditFestival from "./EditFestivalModal";
import Collapsible from "../../Components/Common/Collapsible";

type Props = {
    festivals: NewFestivalData[] | null
}

const ManageFestivalsComponent = (props: Props) => {

    const [selectedCity, setSelectedCity] = useState<FestivalLocation | null>(null)
    const [openEdit, setOpenEdit] = useState<string | null>(null);
    const deleteFestivalModal = useAppSelector(deleteFestivalModalSelector)
    const dispatch = useAppDispatch();

    const toggleActiveFestival = async (festival: NewFestivalData) => {
        await updateIsFestivalActive(festival.id, festival.isCurrent);
        await fetchFestivals(dispatch);
    }

    return (
        <AdminContent>
            <h1>Festiwale</h1>
            <BtnDisplay>
                <button
                    onClick={() => setSelectedCity(FestivalLocation.Gdynia)}
                    style={{
                        backgroundColor: (selectedCity === FestivalLocation.Gdynia) ? '#FFC627' : ''
                    }}
                >Gdynia
                </button>
                <button onClick={() => setSelectedCity(FestivalLocation.Gdansk)}
                        style={{
                            backgroundColor: (selectedCity === FestivalLocation.Gdansk) ? '#FFC627' : ''
                        }}>Gdańsk</button>
                <button onClick={() => setSelectedCity(FestivalLocation.Sopot)}
                        style={{
                            backgroundColor: (selectedCity === FestivalLocation.Sopot) ? '#FFC627' : ''
                        }}>Sopot</button>
                <button onClick={() => setSelectedCity(null)}
                        style={{
                            backgroundColor: (selectedCity === null) ? '#FFC627' : ''
                        }}>Wszystkie</button>
            </BtnDisplay>

            {props.festivals?.map((festival: NewFestivalData) => {
                {
                    if (festival.location === selectedCity || selectedCity === null) {
                        return (
                            <div key={Math.random()}>
                                {deleteFestivalModal === festival.id && <DeleteConfirmModal id={festival.id!}/>}
                                <Collapsible festival={festival}>
                                    <CollapsibleChildren>
                                        <CollapsibleInfo>
                                            <p>opis: {festival.description}</p>
                                            <h4>lokalizacja: {festival.location}</h4>
                                            <h4>data rozpoczęcia: {festival.startDate}</h4>
                                            <h4>data zakończenia: {festival.endDate}</h4>
                                        </CollapsibleInfo>

                                        {( festival.images && festival.images.length !== 0 && festival.images[0].url ) ?
                                            <img src={festival.images![0].url} alt="cover"/>
                                            : <p>brak obrazka</p>
                                        }
                                    </CollapsibleChildren>
                                    <ButtonCollapse>
                                        <button onClick={(e) => {
                                            setOpenEdit(festival.id!)
                                        }}>Edytuj
                                        </button>
                                        <button onClick={() => dispatch(setDeleteFestivalModal(festival.id!))}>Usuń</button>
                                        <button style={{
                                            backgroundColor: festival.isCurrent ? '#03AC13' : ''
                                        }} onClick={() => toggleActiveFestival(festival)}>ustaw jako aktywny
                                        </button>

                                    </ButtonCollapse>
                                </Collapsible>
                                {openEdit === festival.id && <EditFestival openEdit={openEdit} setOpenEdit={setOpenEdit} festival={festival}/>}
                            </div>
                        )
                    }
                }
            })}
        </AdminContent>
    );
};

export default ManageFestivalsComponent;


const ButtonCollapse = styled.div`
   display: flex;
  flex-direction: row;
  justify-content: right;
   `

const AdminContent = styled.div`
  width: 65%;
  height: 100%;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin-left: 20px;

  button {
    border: #FFC627 2px solid;
    border-radius: 20px;
    padding: 8px 12px;
    background-color: white;
    color: black;
    margin-right: 12px;
  }
`
const BtnDisplay = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const CollapsibleChildren = styled.div`
  display: flex;
  flex-direction: row`

const CollapsibleInfo = styled.div`
  width: 50%;
`
