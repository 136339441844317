import styled from "styled-components";

export const BookingWrapper = styled.div<{ isMobile: boolean }>`
    padding: ${props => props.isMobile ? '40px' : '100px 40px;'};
    display: flex;
    flex-direction: ${props => props.isMobile ? 'column-reverse' : 'row'};
    height: ${props => !props.isMobile && '100vh'};

    h1 {
        font-family: "Cabinet Grotesk";
        font-size: 40px;
        line-height: 44px;
        font-weight: 700;
        color: #3E1DAA;
        margin-bottom: 36px;
    }
`

export const FormWrapper = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: ${props => props.isMobile ? '92vw' : '54vw'};
    align-items: flex-start;
    

    button {
        margin-top: 60px;
    }
`

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const InputWrapperRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px
`

export const FormDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
        color: #514D58;
        font-family: "Cabinet Grotesk";
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    input {
        border: 2px solid;
        border-radius: 60px;
        padding: 16px 24px;
        justify-content: space-around;
        width: 280px;
    }

    select {
        border: 2px solid;
        border-radius: 60px;
        padding: 16px 24px;
        justify-content: space-around;
        appearance: none; // hide the default arrow (note: not supported in IE)
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"><polyline points="4 6 8 10 12 6"/></svg> ');
        background-repeat: no-repeat;
        background-position: right 10px center;
        padding-right: 40px; // increase right padding to prevent text overlap
    }

    input[type="checkbox"]{
        height: 20px;
        width: 20px;
        border: 2px solid #3E1DAA;
        border-radius: 4px;
    }
`


export const EventWrapper = styled.div<{isMobile: boolean}>`
    padding: ${props => props.isMobile ? '0' : '30px'};
`

export const EventCard = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    border-radius: 0 200px 0 0;
    background-color: white;
    height: ${props => !props.isMobile && '80vh'};
    width: ${props => props.isMobile ? '92vw' : '42vw'};
    padding: ${props => props.isMobile ? '0 0 20px 0' : '30px'};
    gap: 24px;

    img {
        max-width: ${props => props.isMobile ? '87%' : '60%'};
        max-height: 40%;
        object-fit: cover;
        margin-bottom: 12px;
    }

    h2 {
        font-family: "Cabinet Grotesk";
        font-size: 38px;
        line-height: 42px;
        font-weight: 700;
        color: #3E1DAA;
    }
`

export const AddressDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-bottom: auto;


    h3 {
        font-family: "Cabinet Grotesk";
        font-size: 32px;
        line-height: 38px;
        font-weight: 700;
        color: #3E1DAA;
    }

    h4 {
        font-size: 20px;
    }`

export const ErrorText = styled.div`
    color: #CC3A80;
    display: flex !important;
    flex-direction: row;
    margin-bottom: 24px;

    img {
        margin-right: 8px;
        height: 22px;
        width: 22px;
    }
`;
