import L from "leaflet";
import * as ReactDomServer from "react-dom/server";
import styled from "styled-components";

const PointDiv= styled.div<{booking: boolean}>`
  background-color: ${({booking}) => (booking ? '#6F48EE' : "#FF59A9")};
  height: 32px;
  width: 32px;
  border-radius: 50%;
  p{
    font-family: Arial;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    position: relative;
    top: 6.5px;
    left: 8.5px;
  }
`

function RenderPoint (id: number,  activePoint: number, booking: string, index: number){
    return L.divIcon({
        className: "div-icon",
        iconSize: { x: 16, y: 16 },
        html: ReactDomServer.renderToString(
            <>
                <PointDiv booking={!!booking}>
                    <p>{index}</p>
                    {activePoint === id && <div className="active"/>}
                </PointDiv>
            </>
        ),
    });
}

export default RenderPoint;
