import {BORDER_STYLE, BUTTON_STYLE} from "../../../types/style";
import Button from "../../../Components/Common/Button";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {EntryContainer, LazyImage} from "./NewestBlogEntry.styles";

interface EntryProps {
    borderStyle?: BORDER_STYLE,
    imageSrc: string,
    title: string,
    id: string,
    content: string,
}

//TODO: Make reusable
const Entry = ({borderStyle, imageSrc, title, content,id}: EntryProps) => {
    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        if (window.outerWidth < 1100) {
            setMobile(true);
        }
    }, []);

    const navigate = useNavigate();

    return (
        <EntryContainer className="entry" isMobile={isMobile}>
            <LazyImage borderStyle={borderStyle} isMobile={isMobile}>
                <img src={imageSrc} alt={title}/>
            </LazyImage>
            <h4>{title}</h4>
            <p>{content}</p>
            <Button
                label="Czytaj więcej"
                onClick={() => navigate(`/blog/${id}`)}
                buttonStyle={BUTTON_STYLE.SECONDARY}
            />
        </EntryContainer>
    );
};

export default Entry;

