import styled from "styled-components";
import {BORDER_STYLE} from "../../../types/style";

export const StyledVolonteeringSection = styled('section')<{ isMobile: boolean }>`
    display: flex;
    width: 100vw;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    height: ${({isMobile}) => (isMobile ? '920px' : '720px')};
    max-height: ${({isMobile}) => (isMobile ? '820px' : '720px')};
    padding: ${({isMobile}) => (isMobile ? '44px 40px 20x 40px' : '84px 40px')};
    font-family: Cabinet Grotesk, sans-serif;
    font-style: normal;
    line-height: 120%;
    justify-content: center;
`;

export const LazyImage = styled.div<{ borderStyle?: BORDER_STYLE }>`
  position: relative;
    top:25px;
  width: 440px;
  height: 440px;
  object-fit: cover;
  border-radius: ${(props) => {
    switch (props.borderStyle) {
        case BORDER_STYLE.TOP_LEFT:
            return '200px 0px 0px 0px';
        case BORDER_STYLE.BOTTOM_RIGHT:
            return '0px 0px 200px 0px';
        case BORDER_STYLE.BOTTOM_LEFT:
            return '0px 0px 0px 200px;';
        default:
            return '0px';
    }
}};

  img {
    width: 440px;
    height: 440px;
    object-fit: cover;
    border-radius: ${props => {
    switch (props.borderStyle) {
        case BORDER_STYLE.TOP_LEFT:
            return '200px 0px 0px 0px';
        case BORDER_STYLE.BOTTOM_RIGHT:
            return '0px 0px 200px 0px';
        case BORDER_STYLE.BOTTOM_LEFT:
            return '0px 0px 0px 200px;';
        default:
            return '0px';
    }
}};
  }
`;

export const DescriptionDiv = styled('div')<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({isMobile}) => (isMobile ? '90%' : '515px')};
  padding: ${({isMobile}) => (isMobile ? '44px 20px' : '0 40px')};

  h3 {
    font-size: ${({isMobile}) => (isMobile ? '40px' : '48px')};
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0;
    text-align: left;
    color: #1F0812;
    margin: 20px 0 0 0;
  }

  p {
    font-family: Arial, sans-serif;
    color: #1F0812;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.16px;
    margin-top: 24px;
  }
`;

export const ButtonDiv = styled('div')<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: ${({isMobile}) => (isMobile ? '100%' : ' 330px')};
 margin-bottom: 30px;
  position: relative;

  button {
    position: relative;
      width: ${({isMobile}) => (isMobile ? '90%' : ' 319Px')};
    top: ${({isMobile}) => (isMobile ? '0' : ' 60px')};
    margin: ${({isMobile}) => (isMobile ? '0 auto' : ' 0')};
  }
`;

export const EmptyCircle = styled.div<{ index: number }>`
  opacity: 1;
  position: absolute;
  content: "";
  border: 2px solid #FF59A9;
  border-radius: 50%;
  width: 230px;
  height: 230px;
  top: 0;
  left: ${props => (props.index - 1) * 50}px;
`;


