import chevOpen from "../../Assets/Autumn2023/chevOpen.svg";
import chevClose from "../../Assets/Autumn2023/chevClose.svg";
import {useEffect, useState} from "react";
import React from "react";
import {disclosures} from "./faq.helper";
import {
    DisclosureBody,
    DisclosureComponent,
    DisclosureHeader,
    Disclosures,
    FaqWrapper,
    Label,
    Motto,
    Spacer,
    Title
} from "./Faq.styles";

export function FaqPage() {

    const [disclosuresOpen, setDisclosuresOpen] = useState<number[]>([]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.outerWidth < 1100) {
            setIsMobile(true);
        }
    }, []);

    const toggleDisclosure = (index: number) => {
        setDisclosuresOpen((prevOpen) =>
            prevOpen.includes(index)
                ? prevOpen.filter((i) => i !== index)
                : [...prevOpen, index]
        );
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            toggleDisclosure(index);
        }
    };

    return (
        <FaqWrapper isMobile={isMobile}>
            <Title>FAQ</Title>
            <Label>Pytania, które najczęściej nam zadajecie.</Label>
            <Motto isMobile={isMobile}>
                Festiwal Open House Gdańsk to festiwal otwartości!
            </Motto>
            <p>
                Od wszystkich osób uczestniczących w festiwalu Open House oczekujemy
                przestrzegania zasad, które sprawiają, że wydarzenie jest tak wyjątkowe.
                Dążymy do tego, aby każdy czuł się swobodnie, bezpiecznie i komfortowo,
                niezależnie od płci, tożsamości, orientacji, stopnia niepełnosprawności,
                wyznania, pochodzenia, wyglądu fizycznego czy przekonań.
            </p>
            <p>
                Nie tolerujemy braku szacunku wobec drugiego człowieka. Jeśli podczas
                festiwalu czujesz się niekomfortowo, poinformuj nas o tym, a nasz zespół
                postara Ci się pomóc.
            </p>
            <Disclosures>
                {disclosures.map((disclosure, index) => (
                    <section key={index}>
                        <DisclosureComponent isMobile={isMobile}>
                            <DisclosureHeader
                                isMobile={isMobile}
                                onClick={() => toggleDisclosure(index)}
                                tabIndex={0}
                                role="button"
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                aria-expanded={disclosuresOpen.includes(index)}
                                aria-controls={`disclosure-body-${index}`}
                            >
                                <p>{disclosure.question}</p>
                                <img
                                    src={disclosuresOpen.includes(index) ? chevClose : chevOpen}
                                    alt={disclosuresOpen.includes(index) ? "Close" : "Open"}
                                />
                            </DisclosureHeader>
                            {disclosuresOpen.includes(index) &&
                            <DisclosureBody
                                id={`disclosure-body-${index}`}
                                aria-hidden={!disclosuresOpen.includes(index)}
                            >
                                <p>{disclosure.answer}</p>
                            </DisclosureBody>}
                        </DisclosureComponent>
                        <Spacer isMobile={isMobile} />
                    </section>
                ))}
            </Disclosures>
        </FaqWrapper>
    );
}
