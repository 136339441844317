import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ImageUrlsLabelsState {
    value: {url: string, label: string}[]
}

const initialState: ImageUrlsLabelsState = {
    value: []
};

export const selectedImageUrlsLabelsSlice = createSlice({
    name: 'selectedImageUrlsLabels',
    initialState,
    reducers: {
        setSelectedImageUrlsLabels: (state, action: PayloadAction<{url: string, label: string}[]>) => {
            state.value = action.payload;
        },
    },
});

export const {setSelectedImageUrlsLabels} = selectedImageUrlsLabelsSlice.actions;
export default selectedImageUrlsLabelsSlice.reducer;

