import {Link, Navigate, Outlet} from "react-router-dom";

function UserWrapper({user}) {

    if (!user) {
        return (
            <Navigate to="/login" replace/>
        );
    }

    return (
        <div style={{padding: 20}}>
            <Outlet />
        </div>
    );
}

export default UserWrapper;
