import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EventData, EventSubmitData} from "../../Models/event.model";

interface EventsState {
    value: EventData[] | null;
}

const initialState: EventsState = {
    value: null
};

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setEvents: (state, action: PayloadAction<EventData[]>) => {
            state.value = action.payload;
        },
    },
});

export const {setEvents} = eventsSlice.actions;
export default eventsSlice.reducer;

