import styled from "styled-components";
import {Link} from "react-router-dom";


interface MobileDropdownProps {
    id: string
}

const MobileDropdown = ({id}: MobileDropdownProps) => {
    return (
        <StyledMobileDropdown id="dropdown-link">
            <Link to={`/${id}/archive`} id="dropdown-link">
                O festiwalu
            </Link>
            <Link to={`/${id}/events`} id="dropdown-link">
                Program
            </Link>
            {id === 'gdansk' && <a id="dropdown-link"
                                   href={'https://firebasestorage.googleapis.com/v0/b/ohf-app.appspot.com/o/documents%2Fprogram_OHGdan%CC%81sk_Anio%C5%82ki.pdf?alt=media&token=d85854a8-eddc-434d-ab22-8c2330d21b7a'}
                                   download>Pobierz program</a>
            }
            <Link to={`/${id}/team`} id="dropdown-link">
                Zespół
            </Link>
        </StyledMobileDropdown>
    )
}

export default MobileDropdown

const StyledMobileDropdown = styled('div')`
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-left: 30px;

    #dropdown-link {
        color: #1F0812;
        font-family: Cabinet Grotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-decoration: none;
        padding: 10px 16px;

        :hover {
            color: #3E1DAA;
        }
    }
`

