import styled from "styled-components";

export const StyledNewestBlogSection = styled('section')<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  height: ${({isMobile}) => (isMobile && '2600px')};
  align-items: center;
  background: #FFF1B0;
  padding: ${({isMobile}) => (isMobile ? '40px 18px' : '64px 40px')};
  font-family: Arial;
  font-weight: 400;
  gap: 40px;
    width: 100%;

  h4 {
    font-size: 32px;
    font-weight: 400;
    line-height: 120%;
    margin: 0 0 16px 0;
  }

  p {
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.16px;
    margin: 0 0 24px 0;
  }

  @media (max-width: 300px) {
    height:  490vh;
  }
  
  @media (min-width: 301px) and (max-width: 400px) {
    height: 470vh;
  }

    @media (min-width: 401px) and (max-width: 500px) {
        height: 450vh;
    }

    @media (min-width: 501px) and (max-width: 700px) {
        height: 430vh;
    }


    @media (min-width: 701px) and (max-width: 900px) {
        height: 400vh;
    }
    
`;


export const SectionHeader = styled('section')<{isMobile: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 94vw;
    gap: 12px;
    margin-bottom: 30px;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: 50px;
        width: 240px;
        margin: 0 0 0 auto;

        p {
            margin: 0;
            font-size: 22px;
        }
    }

    h2 {
        color: #1F0812;
        font-family: Cabinet Grotesk;
        font-size: ${({isMobile}) => (isMobile ? '38px' : '58px')};
        font-style: normal;
        font-weight: 700;
        line-height: 100%
    }
`

export const FirstEntry = styled('div')<{isMobile: boolean}>`
  max-height: ${({isMobile}) => (isMobile ? '600px' : '344px')};
  width: 100%;

  button{
      width: 140px;
    p{
      margin: 0;
        font-size: 20px;
    }
  }
`;

export const ImageContainer = styled('div')<{isMobile: boolean}>`
  width: ${({isMobile}) => (isMobile ? '92vw' : '60vw')};
  max-height: ${({isMobile}) => (isMobile ? '240px' : '340px')};
  position: relative;
  top: ${({isMobile}) => (!isMobile && '-20px')};

  img {
    width: 100%;
    max-height: ${({isMobile}) => (isMobile ? '240px' : '354px')};
    object-fit: cover;
    border-radius: ${({isMobile}) => (isMobile ? ' 0 360px 360px 0;' : ' 0 360px 360px 0')};
    
  }
`
export const EmptyCircle = styled.div<{ index: number, isMobile: boolean}>`
  opacity: 1;
  position: absolute;
  content: "";
  border: 2px solid #FBFDFF;
  border-radius: 50%;
  width: ${({isMobile}) => (isMobile ? '120px' : '240px')};
  height: ${({isMobile}) => (isMobile ? '120px' : '240px')};
  top: ${({isMobile}) => (isMobile ? '115px' : '105px')};
  left: ${props => (props.index - 1) * (props.isMobile ? 25 :50)}px;
`

export const ContentContainer = styled('div')<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  align-items: ${({isMobile}) => (isMobile ? 'left' : 'center')};
  justify-content: ${({isMobile}) => (isMobile ? 'flex-start' : 'flex-end')};
  width: 64vw;
  height: ${({isMobile}) => (isMobile ? '450px' : '354px')};
  object-fit: cover;
  border-radius: 360px;
  border: ${({isMobile}) => (isMobile ? 'none' : '2px solid #1F0812')};
  position: ${({isMobile}) => !isMobile && 'relative'};
  top: ${({isMobile}) => (isMobile ? '0' : '-362px')};
  left: ${({isMobile}) => (isMobile ? '0' : '30vw;')};
  color: #1F0812;
`

export const TextContainer = styled('div')<{isMobile: boolean}>`
  width: ${({isMobile}) => (isMobile ? '92vw' : '25vw;')};
  margin-right: ${({isMobile}) => (isMobile ? '0' : '60px')};
`

export const NextEntries = styled.div<{isMobile: boolean}>`
    height: ${({isMobile}) => (isMobile ? '100%' : '610px')};
    display: flex;
    position: relative;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    justify-content: space-evenly;
    gap: 18px;

    button {
        width: 140px;

        p {
            margin: 0;
            font-size: 20px;
        }
    }
`;
