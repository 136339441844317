import bg from "../../Assets/Autumn2023/404bg.png"
import {useNavigate} from "react-router-dom";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";
import {Wrapper, Content, ButtonSpan} from "./notFound.styles";

function NoMatch() {

    const navigate = useNavigate();

    return (
        <Wrapper>
     <img src={bg} alt=''/>
            <Content>
                <h1>404</h1>
                <h2>Pod tym adresem URL drzwi są zamknięte.</h2><br/>
                   <h2> Wróć do drzwi frontowych.</h2>
               <ButtonSpan>
                <Button label="Przejdź na stronę główną" onClick={()=>navigate('/')} buttonStyle={BUTTON_STYLE.GREEN}/>
               </ButtonSpan>
            </Content>
        </Wrapper>
    );
}

export default NoMatch;

