import React from 'react';
import styled from "styled-components";

const Rodo = () => {

    return (
        <Wrapper>
            <h1>Informacja o przetwarzaniu i przechowywaniu danych osobowych.</h1>

            <ol>
                <li>
                    ADMINISTRATOR DANYCH OSOBOWYCH.
                    Fundacja PURA z siedzibą w Gdyni (NIP: 586-230-17-88) jest administratorem w rozumieniu
                    art. 4 pkt 7 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/69 z dnia 27 kwietnia
                    2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i
                    w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                    rozporządzenie o ochronie danych) (dalej „RODO”) danych osobowych Uczestników,
                    Wolontariuszy, Ekspertów w ramach Festiwalu Open House Gdynia. Kontakt z
                    Administratorem możliwy jest przez e-mail: info@openhousefestival.pl
                </li>
                <li>
                    CEL i PODSTAWY PRZETWARZANIA DANYCH.
                    Administrator przetwarza dane osobowe w celu umożliwienia uczestnictwa przez
                    Uczestników, Wolontariuszy i Ekspertów w Festiwalu Open House Gdynia, a także w celu
                    realizacji działań informacyjnych i promocyjnych Festiwalu, i Administratora (Organizatora) na
                    podstawie Regulaminu. Dane osobowe Uczestników, Wolontariuszy, Ekspertów, w tym imię,
                    nazwisko, adres e-mail, numer kontaktowy, adres do korespondencji, numer NIP, a także
                    wizerunek zarejestrowany na zdjęciach lub filmach dokumentujących Wydarzenie
                    przetwarzane będą na podstawie art. 6 ust. 1 lit. c i f RODO. Dane osobowe będą przetwarzane
                    na stronie internetowej Administratora oraz na profilach Administratora na portalach
                    społecznościowych w sposób umożliwiający dostęp osób trzecich, na cele związane z promocją
                    Administratora (Organizatora) i Festiwalu Open House Gdynia, a także w celach
                    dokumentacyjnych, informacyjnych, edukacyjnych, dydaktycznych i archiwizacyjnych.
                </li>
                <li>OKRES PRZECHOWYWANIA DANYCH.
                    Pani/Pana dane osobowe będą przetwarzane przez czas trwania festiwalu Open House Gdynia
                    w danym roku albo przez cały okres realizacji działań informacyjnych i promocyjnych Festiwalu
                    lub Administratora, albo do czasu wycofania przez Panią/Pana zgody na przetwarzanie danych
                    osobowych, w tym w szczególności do czasu wycofania zgody na przetwarzanie wizerunku. </li>
                <li>ODBIORCY DANYCH OSOBOWYCH.
                    Dane osobowe podlegają udostępnieniu podmiotom zewnętrznym, współpracującym z
                    Administratorem wyłącznie w granicach prawnie dozwolonych tj. tylko wtedy gdy podanie tych
                    danych jest konieczne dla świadczenia usług w ramach Festiwalu oraz usług marketingowych.
                    Podawana są tylko te dane, które są konieczne. Dane osobowe są przetwarzane w sposób
                    zgodny z wymogami prawa, w szczególności RODO. Dane osobowe pozyskane przez
                    Administratora w związku z Festiwalem Open House Gdynia nie będą przekazywane do państw
                    trzecich w rozumieniu RODO ani organizacji międzynarodowych. </li>
                <li>PRAWA OSOBY, KTÓREJ DANE DOTYCZĄ.
                    Osoby, których dane osobowe są przetwarzane przez Administratora mają prawo do:
                    a. dostępu do swoich danych osobowych;
                    b. sprostowania, usunięcia lub ograniczenia przetwarzania;
                    c. przenoszenia danych osobowych;
                    d. wniesienia sprzeciwu odnośnie przetwarzania danych osobowych;
                    e. wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, w przypadku gdy
                    uznają, że przetwarzanie ich danych osobowych narusza przepisy RODO.</li>
                <li>INFORMACJA O WYMOGU LUB DOBROWOLNOŚCI PODANIA DANYCH OSOBOWYCH.
                    Podanie przez Uczestnika, Wolontariusza lub Eksperta danych osobowych, w tym utrwalenie
                    wizerunku, jest niezbędne do udziału w Festiwalu Open House Gdynia, na co Pani/Pan wyraża
                    zgodę.</li>
            </ol>


        </Wrapper>
    );
}

export default Rodo

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: black;;
    font-family: 'Lato', sans-serif;
    margin: 5%;
    margin-top: 130px;

    h1 {
        color: #1F0812;
        font-family: Cabinet Grotesk;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 50px;
    }

    h2 {
        color: #1F0812;
        font-family: Cabinet Grotesk;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 24px;
    }

    li {

    }

    ol {
        margin-bottom: 30px;
    }
`
