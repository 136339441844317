import styled from "styled-components";
import maxLength from "../../Utilities/maxLength";
import {calculateBorderStyle} from "../../Utilities/StyleHelpers/styleHelpers";
import Entry from "../Home/Component/NewestBlogEntry";
import NewestBlogSection from "./BlogFirstEntry";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";
import {useAppSelector} from "../../Store/hooks";
import {blogSelector} from "../../Store/selectors/blogSelector";

function BlogList() {

    const isMobile = useAppSelector(isMobileSelector);
    const blogEntries = useAppSelector(blogSelector)

    if (!blogEntries?.length) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <BlogWrapper isMobile={isMobile}>
            <h1>Blog</h1>
            {blogEntries.length && !isMobile && <NewestBlogSection entry={blogEntries[0]}/>}
            {blogEntries && (
                <ul>
                    {blogEntries.slice(isMobile ? 0 : 1).map((blog, index) => (
                        <li key={blog.id}>
                            <Entry
                                borderStyle={calculateBorderStyle(index)}
                                imageSrc={blog.images[0].url}
                                title={blog.title}
                                id={blog.id}
                                content={maxLength(blog.content, 300)}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </BlogWrapper>
    );
}

export default BlogList;

const BlogWrapper = styled('div')<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
    padding: ${props => (props.isMobile ? '34px 0' : '84px 0')};


    h1 {
        color: #1F0812;
        font-family: Cabinet Grotesk, sans-serif;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 40px;
        text-align: left
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        max-width: 1440px;
        gap: 40px;
        margin: 40px auto;
    }

    li {
        margin-bottom: 20px;

        img {
            max-width: 400px;
        }
    }
`
