import styled from "styled-components";

export const CardContainer = styled.div<{isMobile: boolean}>`
    width: ${({isMobile}) => (isMobile ? '90vw;' : '40vw')};
    height: ${({isMobile}) => (isMobile ? '50vw;' : '40vw')};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ColorSquare = styled.div<{isMobile: boolean}>`
  width: ${({isMobile}) => (isMobile ? '80vw;' : '27vw')};
  height: ${({isMobile}) => (isMobile ? '27vw;' : '27vw')};
  background-color: ${props => props.color};
  position: absolute;
  top: -1vw;
  left: 6vw;
`;

export const Image = styled.img<{src: string, alt: string, isMobile: boolean}>`
    position: absolute;
    max-height: ${({isMobile}) => (isMobile ? '27vw;' : '27vw')};
    max-width: ${({isMobile}) => (!isMobile && '27vw')};
    width:  ${({isMobile}) => (isMobile && '90%')};
    object-fit: cover;
    border-radius: 0 300px 0 0;
`;

export const StyledButton = styled.button<{isMobile: boolean}>`
    position: relative;
    font-family: Arial;
    font-weight: 400;
    font-size:  ${({isMobile}) => (isMobile ? '18px' : '24px')};
    line-height: 31px;
    background: none;
    border: none;
    top: 28vw;

    text-decoration: underline;
    text-decoration-color: ${props => props.theme.purple};
    color: ${props => props.theme.black};

    &:hover,
    &:active {
        color: ${props => props.theme.purple};
        cursor: pointer;
    }
`;
