import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button{
    width: 200px;
  }

`
export const AdminContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: black;
  background-color: #ECEFF1;
  padding: 10px;
  margin-bottom: 20px;

  .touched {
    box-sizing: border-box;
    border: 4px green solid;
  }
`
export const ImageContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;

  p {
    color: black;
  }
`

export const DisplayImg = styled.div`
  padding: 10px;
  background-color: #FFFFFF;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: auto;

p{
  background-color: #ECEFF1;
  padding: 4px;
  font-size: 14px;
}`



export const Image = styled.img`
  height: 200px;
  width: auto;
`
