import React, {Dispatch, SetStateAction, useEffect,  useState} from "react";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {IoMdClose} from "react-icons/io";
import {useFormik} from "formik";
import styled from "styled-components";
import {selectedImageUrlsLabelsSelector} from "../../Store/selectors/imageUrlsLabelsSelector";
import {setSelectedImageUrlsLabels} from "../../Store/slices/imageUrlLabelPairSlice";
import {EventData, EventFormData} from "../../Models/event.model";
import {festivalsSelector} from "../../Store/selectors/festivalsSelector";
import {fetchAllEventsState} from "../../Utilities/fetchEvents";
import updateEvent from "../../Utilities/updateEvent";
import AddImagesModal from "../../Components/Common/AddImagesModal";

type Props = {
    event: EventData;
    openEditEvent: string;
    setOpenEditEvent: Dispatch<SetStateAction<string | null>>;
}

const EditEvent = (props: Props) => {
    const dispatch = useAppDispatch();
    const imagesWithLabels = useAppSelector(selectedImageUrlsLabelsSelector);
    const festivals = useAppSelector(festivalsSelector);
    const [secondDay, setSecondDay] = useState(false);

    useEffect(() => {
            dispatch(setSelectedImageUrlsLabels(props.event.images!));
    }, [props.event])


    useEffect( ()=> {
        if(props.event.dateTimes[1]){
            setSecondDay(true)
        }

    }, [props.event])

    const secondDate = () => secondDay ? props.event.dateTimes[1].date : '';
    const secondDateTimes = () => secondDay ? props.event.dateTimes[1].timeSlots.join(', ') : '';


    const formik = useFormik({

        initialValues: {
            address: props.event.address,
            address2: props.event.address2 ? props.event.address2 : '',
            date: props.event.dateTimes[0].date,
            timeSlots: props.event.dateTimes[0].timeSlots.join(', '),
            secondDate: props.event.dateTimes[1]?.date || '',
            secondTimeSlots:  props.event.dateTimes[1]?.timeSlots ? props.event.dateTimes[1]?.timeSlots.join(', ') : '',
            images: props.event.images,
            festivalId: props.event.festivalId,
            title: props.event.title,
            description: props.event.description,
            bookingUrl: props.event.bookingUrl || '',
            bookingsPerSlot: props.event.bookingsPerSlot || 0,
            bookingsPerUser: props.event.bookingsPerUser || 0,
        },
        onSubmit: (values: EventFormData) => {
            console.log(values);
        }
    });

    return (
        <ModalWrapper>
            <Whiteout/>
            <ContentBox>
                <Icon>
                    <IoMdClose onClick={() => props.setOpenEditEvent(null)}/>
                </Icon>


                <h2>Edytuj Wydarzenie</h2>
                <form onSubmit={formik.handleSubmit}>

                    <FormUnit>
                        <label htmlFor="title">Nazwa</label>
                        <input
                            id="title" name="title"  type="text"
                            onChange={formik.handleChange} value={formik.values.title}
                            style={{
                                backgroundColor: formik.touched.title && formik.errors.title ? "#FFD8C9" : "",
                                color: formik.touched.title && formik.errors.title ? "red" : ""
                            }}/>
                        {formik.touched.title && formik.errors.title ? (
                            <ErrorDiv>{formik.errors.title}</ErrorDiv>
                        ) : null}

                        <label htmlFor="description">Opis</label>
                        <textarea
                            id="description" name="description" rows={10} cols={50}
                            onChange={formik.handleChange} value={formik.values.description}/>
                    </FormUnit>
                    <FormUnit>

                        <label htmlFor="address"> Adres </label>
                        <input
                            id="address" name="address"
                            onChange={formik.handleChange} value={formik.values.address}/>

                        <label htmlFor="address2"> Komentarz do adresu </label>
                        <input
                            id="address2" name="address2"
                            onChange={formik.handleChange} value={formik.values.address2}/>


                        <label htmlFor="festivalId"> Przypisz do festiwalu </label>
                        <select
                            id="festivalId"
                            name="festivalId"
                            onChange={formik.handleChange}
                            value={formik.values.festivalId}>
                            <option value="">Wybierz Festival</option>
                            {festivals &&
                                festivals.map((festival) => {
                                    return (
                                        <option value={festival.id}>{festival.title}</option>
                                    )
                                })
                            }
                        </select>

                    </FormUnit>


                    <FormUnit>
                        <label htmlFor="date">Data:</label>
                        <input
                            id="date"
                            name="date"
                            type="date"
                            onChange={formik.handleChange}
                            value={formik.values.date}
                        />
                        <label htmlFor="timeSlots">Time slots</label>
                        <p>Podaj przedziały czasowe po przecinku (16:00-17:00, 17:15-17:45)</p>
                        <input
                            id="timeSlots"
                            name="timeSlots"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.timeSlots}
                        />
                        <button onClick={(e) => {
                            e.preventDefault();
                            setSecondDay(!secondDay)
                        }}>Jeszcze jeden dzień?</button>
                        <div style={{display: secondDay ? 'block' : 'none'}}>
                            <label htmlFor="secondDate">Data:</label>
                            <input
                                id="secondDate"
                                name="secondDate"
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.secondDate ? formik.values.secondDate : ''}
                            />

                            <label htmlFor="secondTimeSlots">Time slots</label>
                            <p>Podaj przedziały czasowe po przecinku (16:00-17:00, 17:15-17:45)</p>
                            <input
                                id="secondTimeSlots"
                                name="secondTimeSlots"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.secondTimeSlots ? formik.values.secondTimeSlots : ''}
                            />
                        </div>
                        <label htmlFor="bookingUrl">Link do rezerwacji</label>
                        <input
                            id="bookingUrl" name="bookingUrl"  type="text"
                            onChange={formik.handleChange} value={formik.values.bookingUrl}
                            style={{
                                backgroundColor: formik.touched.bookingUrl && formik.errors.bookingUrl ? "#FFD8C9" : "",
                                color: formik.touched.bookingUrl && formik.errors.bookingUrl ? "red" : ""
                            }}/>
                        {formik.touched.bookingUrl && formik.errors.bookingUrl ? (
                            <ErrorDiv>{formik.errors.bookingUrl}</ErrorDiv>
                        ) : null}

                        <label htmlFor="bookingUrl">Ilość miejsc per slot</label>
                        <input
                            id="bookingsPerSlot" name="bookingsPerSlot"  type="number"
                            onChange={formik.handleChange} value={formik.values.bookingsPerSlot}
                            style={{
                                backgroundColor: formik.touched.bookingsPerSlot && formik.errors.bookingsPerSlot ? "#FFD8C9" : "",
                                color: formik.touched.bookingsPerSlot&& formik.errors.bookingsPerSlot ? "red" : ""
                            }}/>
                        {formik.touched.bookingsPerSlot && formik.errors.bookingsPerSlot ? (
                            <ErrorDiv>{formik.errors.bookingsPerSlot}</ErrorDiv>
                        ) : null}

                        <label htmlFor="bookingsPerUser">Ile miejsc może zarezerwować użytkownik podczas rezerwacji</label>
                        <input
                            id="bookingsPerUser" name="bookingsPerUser"  type="number"
                            onChange={formik.handleChange} value={formik.values.bookingsPerUser}
                            style={{
                                backgroundColor: formik.touched.bookingsPerUser && formik.errors.bookingsPerUser ? "#FFD8C9" : "",
                                color: formik.touched.bookingsPerUser&& formik.errors.bookingsPerUser? "red" : ""
                            }}/>
                        {formik.touched.bookingsPerUser && formik.errors.bookingsPerUser ? (
                            <ErrorDiv>{formik.errors.bookingsPerUser}</ErrorDiv>
                        ) : null}

                    </FormUnit>
                    <AddImagesModal/>

                    <button onClick={() => props.setOpenEditEvent(null)}>Wróć</button>
                    <button type="submit" onClick={async (e) => {
                        e.preventDefault();

                        const dateTimes = [{date: formik.values.date, timeSlots: formik.values.timeSlots.split(",")}]
                        if (formik.values.secondDate !== '' && formik.values.secondTimeSlots !== '') {
                            // @ts-ignore
                            dateTimes.push({date: formik.values.secondDate, timeSlots: formik.values.secondTimeSlots.split(",")})
                        }

                        console.log(
                            props.event.id,
                            formik.values.address,
                            formik.values.address2,
                            dateTimes,
                            imagesWithLabels,
                            formik.values.festivalId,
                            formik.values.title,
                            formik.values.description,
                            formik.values.bookingUrl,
                            formik.values.bookingsPerSlot,
                            formik.values.bookingsPerUser,
                        )


                        await updateEvent(
                            props.event.id,
                            formik.values.address,
                            formik.values.address2,
                            dateTimes,
                            imagesWithLabels,
                            formik.values.festivalId,
                            formik.values.title,
                            formik.values.description,
                            formik.values.bookingUrl,
                            formik.values.bookingsPerSlot,
                            formik.values.bookingsPerUser,
                        )
                        await fetchAllEventsState(dispatch);
                        props.setOpenEditEvent(null);
                    }}>Zaakceptuj zmiany
                    </button>
                </form>
            </ContentBox>
        </ModalWrapper>
    )
}

export default EditEvent


const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
    height: auto;
`

const Whiteout = styled.div`
  background: gray;
  opacity: 0.7;
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
`

const ContentBox = styled.div`
  background: white;
  height: auto;
  width: 700px;
  z-index: 2;
  position: absolute;
  top: 900px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0  3px 8px;
  overflow: scroll;

  span {
    margin-right: 10px;
  }

  button {
    margin-top: 20px;
  }
`
const Icon = styled.div`
  width: 100%;
  position: absolute;
  left: 95%;
  top: 4%;

`

const FormWrap = styled.div`
  width: 65%;
  height: auto;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin: 0 auto;

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  label {
    margin-bottom: 5px;
  }

  input {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  textarea {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  select {
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  button {
    width: 200px;
  }
`
const ErrorDiv = styled.div`
  color: red;
`
const FormUnit = styled.div`
  background-color: #ECEFF1;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`
