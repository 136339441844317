export const theme = {
    white: "#F5F5F5",
    black: "#1F0812",
    purple: '#6F48EE',
    blue: "#3E1DAA",
    primary: "#FCE36E",
    pink: "#FF59A9",
    lime: "#E2FD5F",
    contentFont: "Lato",
    headerFont: "Cabinet Grotesk"
};

