import styled from "styled-components";
import {BORDER_STYLE} from "../../../types/style";

export const LazyImage = styled.div<{ borderStyle?: BORDER_STYLE, isMobile: boolean}>`
  opacity: 1;
  transition: opacity 0.5s;
  width: 100%;
  height: 302px;
  object-fit: cover;
  margin-bottom: 24px;
  border-radius: ${(props) => {
    switch (props.borderStyle) {
        case BORDER_STYLE.TOP_LEFT:
            return '200px 0px 0px 0px';
        case BORDER_STYLE.BOTTOM_RIGHT:
            return '0px 0px 200px 0px';
        case BORDER_STYLE.NONE:
            return '0px 0px 0px 0px';
        case BORDER_STYLE.BOTH_SIDES:
            return '200px 0px';
        default:
            return '0px';
    }
}};

  img {
    width: ${({isMobile}) => (isMobile ? '92vw' : ' 430px')};
    height: 302px;
    object-fit: cover;
    margin-bottom: 24px;
    border-radius: ${props => {
    switch (props.borderStyle) {
        case BORDER_STYLE.TOP_LEFT:
            return '200px 0px 0px 0px';
        case BORDER_STYLE.BOTTOM_RIGHT:
            return '0px 0px 200px 0px';
        case BORDER_STYLE.NONE:
            return '0px 0px 0px 0px';
        case BORDER_STYLE.BOTH_SIDES:
            return '200px 0px';
        default:
            return '0px';
    }
}};
  }
`;

export const EntryContainer = styled.div<{isMobile: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    width: ${({isMobile}) => (isMobile ? '92vw' : ' 430px')};

    p {
        margin-bottom: auto;
        color: #1F0812;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.16px;
    }

    h4 {
        color: #1F0812;
        font-family: Arial;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%
    }
`;
