import {db} from "../config";
import {doc, updateDoc} from "@firebase/firestore";
import {UpdateEventData} from "../types/events";

const updateEvent = async (
    id: string | undefined,
    address?: string,
    address2? : string,
    dateTimes?: {date: string, timeSlots:string[]}[],
    images?: { url: string, label: string }[],
    festivalId?: string,
    title?: string,
    description?: string,
    bookingUrl?: string,
    bookingsPerSlot?: number,
    bookingsPerUser?: number,
) => {

    try {
        if (id === undefined) return;

        const docRef = doc(db, 'events', id)
        const dataToUpdate: UpdateEventData = {};
        if (title) {
            dataToUpdate['title'] = title
        }
        if (description) {
            dataToUpdate['description'] = description
        }
        if (address) {
            dataToUpdate['address'] = address
        }
        if (address2) {
            dataToUpdate['address2'] = address2
        }

        if (images) {
            dataToUpdate['images'] = images
        }

        if (dateTimes) {
            dataToUpdate['dateTimes'] = dateTimes
        }

        if (festivalId) {
            dataToUpdate['festivalId'] = festivalId
        }

        if (title) {
            dataToUpdate['title'] = title
        }

        if (description) {
            dataToUpdate['description'] = description
        }

        if (bookingUrl) {
            dataToUpdate['bookingUrl'] = bookingUrl
        }
        if (bookingsPerUser) {
            dataToUpdate['bookingsPerUser'] = bookingsPerUser
        }
        if (bookingsPerSlot) {
            dataToUpdate['bookingsPerSlot'] = bookingsPerSlot
        }
        await updateDoc(docRef, dataToUpdate);
    } catch (err) {
        alert('Wystąpił błąd!')
        console.log(err);
    }
}

export default updateEvent
