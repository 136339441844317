import React from 'react';
import {useNavigate} from "react-router-dom";
import {CardContainer, ColorSquare, StyledButton, Image} from "./HomePageCard.styles";

interface CardPropsInterface {
    color: string;
    imageUrl: string;
    linkLabel: string;
    linkTo: string;
    isMobile?: boolean
}

export const HomePageCard = ({color, imageUrl, linkLabel, linkTo, isMobile = false}: CardPropsInterface) => {
    const navigate = useNavigate();
    const handleClick = () => navigate(linkTo);

    return (
        <CardContainer isMobile={isMobile}>
            <ColorSquare color={color} isMobile={isMobile} />
            <Image src={imageUrl} alt={linkLabel} isMobile={isMobile} />
            <StyledButton onClick={handleClick} isMobile={isMobile}>{linkLabel}</StyledButton>
        </CardContainer>
    );
};

