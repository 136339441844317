import React, {Dispatch, SetStateAction, useEffect} from 'react';
import styled from "styled-components";
import {AdminPagesDisplay} from "../../Models/menu.model";


type Props = {
    setPage: Dispatch<SetStateAction<AdminPagesDisplay>>;
}

const AdminSidebarComponent = (props: Props) => {
    const {setPage} = props;

    return (
        <AdminSidebar>
            <h1>Panel Admina</h1>
            <h2>Dodaj nowe:</h2>
            <ToggleFormButton onClick={()=>{setPage(AdminPagesDisplay.addFestival)}}>+ Dodaj edycję festiwalu</ToggleFormButton>
            <ToggleFormButton onClick={()=>{setPage(AdminPagesDisplay.addEvent)}}>+ Dodaj wydarzenie</ToggleFormButton>
            <ToggleFormButton onClick={()=>{setPage(AdminPagesDisplay.addArticle)}}>+ Dodaj artykuł</ToggleFormButton>
            <h2>Zarządzaj obecnymi:</h2>
            <ToggleFormButton onClick={()=>{setPage(AdminPagesDisplay.festivals)}}>Festiwale</ToggleFormButton>
            <ToggleFormButton onClick={()=>{setPage(AdminPagesDisplay.events)}}>Wydarzenia</ToggleFormButton>
            <ToggleFormButton onClick={()=>{setPage(AdminPagesDisplay.blog)}}>Artykuły</ToggleFormButton>
        </AdminSidebar>
    );
}
export default AdminSidebarComponent

const AdminSidebar = styled.div`
  width: 300px;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  font-family: 'Lato';
`

const ToggleFormButton = styled.button`
  color: #07080D;
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 250px;
`
