import styled from "styled-components";

export const FaqWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  padding: ${({isMobile}) => (isMobile ? '150px 24px 50px 24px' : '150px 150px 50px 150px')};

  p {
    color: #1F0812;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    margin-bottom: 20px;
    max-width: ${({isMobile}) => (isMobile ? '85%' : '805px')};
  }
`;

export const Title = styled.h1`
  color: #1F0812;
  font-family: Cabinet Grotesk;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`

export const Label = styled.h2`
  color: #514D58;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.16px;
  margin-bottom: 36px;
`

export const Motto = styled.h3<{ isMobile: boolean }>`
  color: #CC3A80;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 8px;
  max-width: ${({isMobile}) => (isMobile ? '85%' : '805px')};

`

export const DisclosureComponent = styled.div<{ isMobile: boolean }>`
`;

export const DisclosureHeader = styled.div <{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
    padding: 10px 0;
  max-width: ${({isMobile}) => (isMobile ? '85%' : '805px')};

  p {
    color: #1B0466;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 23.4px */
    max-width: ${({isMobile}) => (isMobile ? '280px' : '100%')};
margin: 0;
  }

  img {
    height: 9px;
    width: 18px;
  }

`

export const DisclosureBody = styled.div`
  p{
    color: #1F0812;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.16px;
  }
`

export const Spacer = styled.div<{ isMobile: boolean }>`
  background: #E5E4E7;
  max-width: ${({isMobile}) => (isMobile ? '85%' : '805px')};
  height: 2px;
  margin: 24px 0;
`

export const Disclosures = styled.div`
  margin-top: 24px
`;
