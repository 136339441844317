import {initializeApp} from "firebase/app";
import {getStorage} from "firebase/storage";
import {collection, getFirestore, query, getDocs, where} from "@firebase/firestore";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    setPersistence,
    browserLocalPersistence
} from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();


export const logInWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    await setPersistence(auth, browserLocalPersistence);
    const user = await signInWithEmailAndPassword(auth, email, password);
    return user
};

export const isUserAdmin = async (id: string): Promise<boolean> => {
    const q = query(collection(db, "users"), where("uid", "==", id));
    const docs = await getDocs(q);
    const isAdminArray = docs.docs.map((doc) => {
        const u = doc.data();
        return !!u.isAdmin;
    });
    return isAdminArray.some((isAdmin) => isAdmin);
};

export const signOutUser = async () => {
    try {
        await auth.signOut();
        console.log("User signed out successfully");
    } catch (error) {
        console.error("Error signing out:", error);
    }
};

export const db = getFirestore(app);
