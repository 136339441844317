import styled from "styled-components";
import {Link} from "react-router-dom";


interface NavbarDropdownProps {
    id: string
}

const NavbarDropdown = ({id}: NavbarDropdownProps) => {
    return (
        <StyledNavbarDropdown>
            <Link to={`/${id}/`} style={{padding: 5}}>
                O festiwalu
            </Link>
            <Link to={`/${id}/events`} style={{padding: 5}}>
                Program
            </Link>
            {id === 'gdansk' && <a id="dropdown-link"
                                   href={'https://firebasestorage.googleapis.com/v0/b/ohf-app.appspot.com/o/documents%2Fprogram_OHGdan%CC%81sk_Anio%C5%82ki.pdf?alt=media&token=d85854a8-eddc-434d-ab22-8c2330d21b7a'}
                                   download>Pobierz program</a>}
            <Link to={`/${id}/team`} style={{padding: 5}}>
                Zespół
            </Link>
        </StyledNavbarDropdown>
    )
}

export default NavbarDropdown

const StyledNavbarDropdown = styled('div')`
    display: flex;
    flex-direction: column;
    background-color: #FBFDFF;
    align-items: center;
    position: relative;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    gap: 14px;

    a {
        color: #1F0812;: hover {
        color: #3E1DAA;
    }
    }
`

