import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import AdminSidebarComponent from "./AdminSidebarComponent";
import {FestivalFormInterface} from "../../Models/festival.model";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import useAddFestival from "../../Utilities/Hooks/useAddFestival";
import {festivalsSelector} from "../../Store/selectors/festivalsSelector";
import {fetchFestivals} from "../../Utilities/fetchFestivals";
import {DateTime, EventFormData, EventSubmitData} from "../../Models/event.model";
import useAddEvent from "../../Utilities/Hooks/useAddEvent";
import {ArticleDataInput} from "../../Models/blog.model";
import useAddBlog from "../../Utilities/Hooks/useAddBlog";
import AdminPanelManageFestivals from "./AdminPanelManageFestivals";
import AdminPanelAddEvent from "./AdminPanelAddEvent";
import AdminPanelAddFestival from "./AdminPanelAddFestival";
import {blogSelector} from "../../Store/selectors/blogSelector";
import {fetchAllArticles} from "../../Utilities/fetchBlog";
import {AdminPagesDisplay} from "../../Models/menu.model";
import AdminPanelAddBlog from "./AdminPanelAddBlog";
import ManageBlogComponent from "./AdminPanelManageBlog";
import ManageEventsComponent from "./AdminPanelManageEvents";
import {setSelectedImageUrlsLabels} from "../../Store/slices/imageUrlLabelPairSlice";
import {fetchAllEventsState} from "../../Utilities/fetchEvents";


const AdminPanelWrapper = () => {

    const dispatch = useAppDispatch();


    useEffect(() => {
        fetchFestivals(dispatch);
    }, []);


    const festivals = useAppSelector(festivalsSelector);
    const articles = useAppSelector(blogSelector);
    const [page, setPage] = useState<AdminPagesDisplay>(AdminPagesDisplay.blog)


    useEffect(()=>{
        dispatch(setSelectedImageUrlsLabels([]));
    },[page])

    // handling
    const {addNewBlog} = useAddBlog();
    const {addNewFestival} = useAddFestival();
    const {addNewEvent} = useAddEvent();

    const submitBlogHandler = async (values: ArticleDataInput) => {
        const {success: addedBlogSuccesfully} = await addNewBlog(
            {
                ...values,
            });
        if (addedBlogSuccesfully) {
            alert('success!');
            await fetchAllArticles(dispatch)

        } else {
            console.log('NO!')
        }
        dispatch(setSelectedImageUrlsLabels([]));
    }


    const submitFestivalHandler = async (values: FestivalFormInterface) => {
        const {success: addedFestivalSuccesfully} = await addNewFestival(
            {
                ...values,
                isCurrent: false,
            });
        if (addedFestivalSuccesfully) {
            alert('success!');
            await fetchFestivals(dispatch)

        } else {
            console.log('NO!')
        }
        dispatch(setSelectedImageUrlsLabels([]));
    }

    const submitEventHandler = async (values: EventFormData) => {
        let dateTimes: DateTime[] = [{date: values.date, timeSlots: values.timeSlots.split(",")}]
        if (values.secondDate && values.secondTimeSlots) {
            dateTimes.push({date: values.secondDate, timeSlots: values.secondTimeSlots.split(",")})
        }
        const data: EventSubmitData = {
            title: values.title,
            description: values.description,
            address: values.address,
            festivalId: values.festivalId,
            dateTimes: dateTimes,
            bookingUrl: values.bookingUrl,
            bookingsPerSlot: values.bookingsPerSlot,
            // @ts-ignore
            bookingsPerUser: values.bookingsPerUser
        }
        const {success: addedEventSuccesfully} = await addNewEvent(
            {
                ...data
            });
        if (addedEventSuccesfully) {
            alert('success!');
            await fetchAllEventsState(dispatch)

        } else {
            console.log('NO!')
        }
        dispatch(setSelectedImageUrlsLabels([]));
    }

    return (
        <AdminWrapper>
            <AdminSidebarComponent setPage={setPage}/>
            {page === AdminPagesDisplay.addFestival && <AdminPanelAddFestival setPage={setPage} submit={submitFestivalHandler}/>}
            {page === AdminPagesDisplay.addEvent && <AdminPanelAddEvent setPage={setPage} submit={submitEventHandler}/>}
            {page === AdminPagesDisplay.addArticle && <AdminPanelAddBlog setPage={setPage} submit={submitBlogHandler}/>}
            {page === AdminPagesDisplay.events && <ManageEventsComponent/>}
            {page === AdminPagesDisplay.blog && <ManageBlogComponent articles={articles}/>}
            {page === AdminPagesDisplay.festivals && <AdminPanelManageFestivals festivals={festivals}/>}

        </AdminWrapper>
    );
};

export default AdminPanelWrapper

const AdminWrapper = styled.div`
  width: 100%;
  height: auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  padding: 20px;
`
