import {collection, doc, getDoc, getDocs, query} from "@firebase/firestore";
import {db} from "../config";
import {setBlog} from "../Store/slices/blogSlice";

export const fetchAllArticles = async(dispatchFunction:any)=> {
    let articles: any = []
    const q = query(collection(db, "articles"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        articles.push({
            ...doc.data(),
            id: doc.id
        });
    })
    dispatchFunction(setBlog(articles));
}

export const fetchArticleById = async (articleId: string) => {
    try {
        const articleDocRef = doc(db, "articles", articleId);
        const articleDocSnapshot = await getDoc(articleDocRef);

        if (articleDocSnapshot.exists()) {
            const articleData = articleDocSnapshot.data();
            return articleData; // Return the article data
        } else {
            console.error(`Article with ID ${articleId} not found.`);
            return null;
        }
    } catch (error) {
        console.error("Error fetching article by ID:", error);
        return null;
    }
};


export const fetchAllBlogArticles = async () => {
    const articles = [];
    const q = query(collection(db, "articles"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        // @ts-ignore
        articles.push({
            ...doc.data(),
            id: doc.id
        });
    });

    return articles;
};
