import {createUserWithEmailAndPassword} from "firebase/auth";
import {addDoc, collection} from "@firebase/firestore";
import {auth, db} from "../config";

export const registerWithEmailAndPassword = async (email: string, name: string, password: string, passwordConfirmation: string) => {
    const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name: name,
            authProvider: "local",
            email: email,
            isAdmin: false,
            likedArticles:['none'],
            likedEvents:['none'],
        });
        return user;
};
