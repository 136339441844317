import {BUTTON_STYLE} from "../../../types/style";
import LOGO from "../../../Assets/Autumn2023/logo-with-tune.svg";
import BG from "../../../Assets/playlist_bg.webp";
import Button from "../../../Components/Common/Button";
import {BackgroundDiv, ContentDiv, DecorativeDiv, EmbedDiv, StyledPlaylistSection} from "./PlaylistSection.styles";

interface PlaylistSectionProps {
    isMobile:boolean
}

function PlaylistSection({isMobile}: PlaylistSectionProps) {
    return (
        <StyledPlaylistSection isMobile={isMobile}>
            <EmbedDiv isMobile={isMobile}>
                <h3>Muzyka <br/> do słuchania w mieście</h3>
                <p>Sprawdź playlistę, którą przygotowaliśmy dla Ciebie!</p>
                <iframe
                    title="Spotify Playlist"
                    src={'https://open.spotify.com/embed/playlist/7pJx7CckNVJn0v1JJdDT76?utm_source=generator&theme=0'}
                    width={isMobile? "100%" : "760"}
                    height={isMobile? "620" : "380"}
                    allowTransparency={true}
                    allow="encrypted-media"
                    />
        </EmbedDiv>
            <DecorativeDiv isMobile={isMobile}>
                <BackgroundDiv isMobile={isMobile}>
                    <img src={BG} alt="Background" />
                </BackgroundDiv>
                <ContentDiv>
                <img src={LOGO} alt="Logo" />
                <Button
                    label='Przejdz do playlisty'
                    //TODO:Link
                    onClick={() => console.log('go to spotify')}
                    buttonStyle={BUTTON_STYLE.GREEN}
                />
                </ContentDiv>
            </DecorativeDiv>
        </StyledPlaylistSection>
    );
}

export default PlaylistSection;

