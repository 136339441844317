import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {eventsSelector} from "../../Store/selectors/eventsSelector";
import {EventData} from "../../Models/event.model";
import { fetchAllEventsState} from "../../Utilities/fetchEvents";
import styled from "styled-components";
import {activeFestivalSelector} from "../../Store/selectors/activeFestivalSelector";
import EventBox from "./AdminPanelManageEventsConent";

const ManageEventsComponent = () => {

    const dispatch = useAppDispatch();
    const baseEvents = useAppSelector(eventsSelector);
    const activeFestivals = useAppSelector(activeFestivalSelector);
    const gdansk = activeFestivals.filter(fest => fest.location === 'Gdańsk')[0].id;
    const gdynia = activeFestivals.filter(fest => fest.location === 'Gdynia')[0].id;
    const [displayOption, setDisplayOption] = useState<string | null>('all');

    useEffect(() => {
        fetchAllEventsState(dispatch)
            .catch(console.error);
    }, [])

    if(baseEvents?.length === 0 || baseEvents === null){
        return <div>Loading...</div>
    }

    const sortedEvents = [...baseEvents].sort((a, b) => a.title.localeCompare(b.title));

    return (
        <AdminContent>
            <h1>Eventy</h1>
            <BtnDisplay>
                <button onClick={() => {setDisplayOption('gdynia')}}
                        style={{backgroundColor: (displayOption === 'gdynia') ? '#FFC627' : ''}}
                >Gdynia
                </button>
                <button onClick={() => {setDisplayOption('gdansk')}}
                        style={{backgroundColor: (displayOption === 'gdansk') ? '#FFC627' : ''}}
                >Gdansk
                </button>
                <button onClick={() => {setDisplayOption('archive')}}
                        style={{backgroundColor: (displayOption === 'archive') ? '#FFC627' : ''}}
                >Archiwalne
                </button>
                <button onClick={() => {setDisplayOption('all')}}
                        style={{backgroundColor: (displayOption === 'all') ? '#FFC627' : ''}}
                >Wszystkie
                </button>
            </BtnDisplay>


            {displayOption === 'archive' && sortedEvents.filter((event) => event.festivalId !== gdansk && event.festivalId !== gdynia)
                .map((e: EventData) => {return (<EventBox e={e}/>)})
            }

            {displayOption === 'all' && sortedEvents.map((e: EventData) => {return (<EventBox e={e}/>)})
            }

            {displayOption === 'gdynia' && sortedEvents.filter((event) => event.festivalId === gdynia)
                .map((e: EventData) => {return (<EventBox e={e}/>)
            })
            }

            {displayOption === 'gdansk' && sortedEvents.filter((event) => event.festivalId === gdansk)
                .map((e: EventData) => {return (<EventBox e={e}/>)
            })
            }

        </AdminContent>
    );
};

export default ManageEventsComponent;

const AdminContent = styled.div`
  width: 85%;
  height: 100%;
  background: #f5f5f5;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin-left: 20px;

  button {
    color: black;
    background: none;
    width: 140px;
    height: 40px;
    border: 1px solid black;
    border-radius: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      background-color: #E9CE44;
      border: none;
      transition: background-color 0.3s, border 0.3s
    }

    p {
      font-weight: 600;
    }
`

const BtnDisplay = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`
