import React, {Dispatch, SetStateAction, useEffect} from 'react';
import styled from "styled-components";
import {useFormik} from "formik";
import {fetchAllArticles} from "../../Utilities/fetchBlog";
import {ArticleDataDisplay} from "../../Models/blog.model";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {IoMdClose} from "react-icons/io";
import updateBlog from "../../Utilities/updateArticle";
import {selectedImageUrlsLabelsSelector} from "../../Store/selectors/imageUrlsLabelsSelector";
import {setSelectedImageUrlsLabels} from "../../Store/slices/imageUrlLabelPairSlice";
import AddImagesModal from "../../Components/Common/AddImagesModal";

type Props = {
    article:ArticleDataDisplay
    openEditBlog: string;
    setOpenEditBlog: Dispatch<SetStateAction<string | null>>
}

const EditArticle = (props: Props) => {
    const dispatch = useAppDispatch();
    const imagesWithLabels = useAppSelector(selectedImageUrlsLabelsSelector);

    useEffect(() => {
        if (imagesWithLabels.length === 0) {
            dispatch(setSelectedImageUrlsLabels(props.article.images!));
        }
    }, [])


    const formik = useFormik({
        initialValues: {
            title: props.article.title,
            content: props.article.content,
            author: props.article.author,
            tags: props.article.tags.join(', '),
            layout: 1,
        },

        onSubmit: (values: any) => {
            console.log(values);
        }
    });

    return (
        <ModalWrapper>
            <Whiteout/>
            <ContentBox>
            <Icon>
                <IoMdClose onClick={() => props.setOpenEditBlog(null)}/>
            </Icon>
            <h2>Dodaj Artykuł</h2>
            <form onSubmit={formik.handleSubmit}>
                <FormUnit>
                    <label htmlFor="title">Tytuł</label>
                    <input
                        id="title"
                        name="title"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        style={{
                            backgroundColor: formik.touched.title && formik.errors.title ? "#FFD8C9" : "",
                            color: formik.touched.title && formik.errors.title ? "red" : ""
                        }}
                    />
                    {formik.touched.title && formik.errors.title ? (
                        <ErrorDiv>{formik.errors.title}</ErrorDiv>
                    ) : null}

                    <label htmlFor="content">Treść</label>
                    <textarea
                        id="content"
                        name="content"
                        rows={10}
                        cols={100}
                        onChange={formik.handleChange}
                        value={formik.values.content}
                    />
                </FormUnit>
                <FormUnit>
                    <label htmlFor="author"> Autor</label>
                    <input
                        type="text"
                        id="author"
                        name="author"
                        onChange={formik.handleChange}
                        value={formik.values.author}>
                    </input>

                    <label htmlFor="tags">Tagi (podawaj po przecinku)</label>
                    <input
                        type="text"
                        id="tags"
                        name="tags"
                        onChange={formik.handleChange}
                        value={formik.values.tags}>
                    </input>


                    <label htmlFor="layout"> Layout </label>
                    <select
                        id="layout"
                        name="layout"
                        onChange={formik.handleChange}
                        value={formik.values.layout}>
                        <option value="">Wybierz layout</option>
                        <option value={1}>1</option>
                        <option value={2} disabled>2</option>
                        <option value={3} disabled>3</option>
                    </select>
                </FormUnit>
                <AddImagesModal/>

                <button onClick={() => props.setOpenEditBlog(null)}>Wróć</button>
                <button type="submit" onClick={async (e) => {
                    e.preventDefault();
                    console.log(formik.values);
                    await updateBlog(
                        props.article.id,
                        formik.values.title,
                        formik.values.content,
                        formik.values.author,
                        formik.values.tags,
                        formik.values.layout,
                        imagesWithLabels,
                    )
                    await fetchAllArticles(dispatch);
                    props.setOpenEditBlog(null);
                }}>Zaakceptuj zmiany
                </button>
            </form>
        </ContentBox>

</ModalWrapper>
    )
};
export default EditArticle


const ErrorDiv = styled.div`
  color: red;
`
const FormUnit = styled.div`
  background-color: #ECEFF1;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Whiteout = styled.div`
  background: gray;
  opacity: 0.7;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
`

const ContentBox = styled.div`
  background: white;
  height: 600px;
  width: 700px;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: scroll;

  span {
    margin-right: 10px;
  }

  button{
    margin-top: 20px;
  }
`
const Icon = styled.div`
  width: 100%;
  position: absolute;
  left: 95%;
  top: 4%;

`

const FormWrap = styled.div`
      width: 65%;
      height: auto;
      background: white;
      color: black;
      display: flex;
      flex-direction: column;
      padding: 10px 50px;
      margin: 0 auto;

      form {
        display: flex;
        flex-direction: column;
        width: 90%;
      }

      label {
        margin-bottom: 5px;
      }

      input {
        padding: 6px 3px;
        margin-bottom: 12px;
      }

      textarea {
        padding: 6px 3px;
        margin-bottom: 12px;
      }

      select {
        padding: 6px 3px;
        margin-bottom: 12px;
      }

      button {
        width: 200px;
      }
    `

