export enum SELECTED_CITY {
    GDANSK = 'GDANSK',
    GDYNIA = 'GDYNIA',
    NONE = 'NONE',
}

export enum IS_VISIBLE {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    BOTH = 'BOTH',
}

export enum AuthCode {
    InvalidPassword ="auth/invalid-password",
    InvalidEmail ="auth/invalid-email",
    WrongPassword ="auth/wrong-password",
    UserNotFound ="auth/user-not-found",
    EmailAlreadyInUse ="auth/email-already-in-use",
    PasswordsDoNotMatch ="Hasła się nie zgadzają"
}
