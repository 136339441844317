import {configureStore, PreloadedState} from "@reduxjs/toolkit";
import rootReducer from "./slices";

export const storeSetup = (initialPreloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState: initialPreloadedState,
    });
    };

export const store = storeSetup();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof storeSetup>;
export type AppDispatch = AppStore['dispatch']
