import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #1B0466;
    display: flex;
    
    img{
      width: 100vw;
      height: 101vh;
        object-fit: fill;
        position: absolute;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    z-index: 2;

    h1 {
        color: #FBFDFF;
        font-family: Cabinet Grotesk;
        font-size: 220px;
        font-style: normal;
        font-weight: 700;
        line-height: 90%;
    }

    h2 {
        color: #FBFDFF;
        text-align: center;
        font-family: Cabinet Grotesk;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }
`
export const ButtonSpan = styled.div`
    margin-top: 20px;
`
