import React, {Ref, useState} from "react";
import styled from "styled-components";
import errorIcon from "../../../Assets/errorIcon.svg";

interface InputProps {
    label?: string;
    error?: string;
    placeholder?: string;
    filled?: boolean;
    ref: Ref<Element>;
    onFocus?: () => void;
    onBlur?: () => void;
    type?: string;
    isMobile?: boolean
}

const InputField = React.forwardRef(
    ({ label, error, placeholder, filled, onFocus, onBlur, type, isMobile}: InputProps, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <InputWrapper>
            {label && <InputLabel>{label}</InputLabel>}
            <StyledInput
                isMobile={isMobile? isMobile: false}
                filled={filled} error={error} isFocused={isFocused}
                placeholder={placeholder}
                ref={ref as React.MutableRefObject<HTMLInputElement>}
                type={type}
                onFocus={() => {
                    setIsFocused(true);
                    onFocus && onFocus();
                }}
                onBlur={() => {
                    setIsFocused(false);
                    onBlur && onBlur();
                }}
            />
            {error && <ErrorText><img src={errorIcon} alt=''/>{error}</ErrorText>}
        </InputWrapper>
    );
});

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const InputLabel = styled.label`
  color: #514D58;

  font-family: Cabinet Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;

const StyledInput = styled.input<{ filled?: boolean; error?: string; isFocused: boolean; isMobile:boolean }>`
  flex: 1;
  width: ${({isMobile}) => (isMobile ? '75vw;' : '30vw;')};
  height: 31px;
  padding: 16px 24px;  
  align-items: center;
  gap: 80px;
  border-radius: 60px;
  border: 2px solid ${(props) => (props.error ? "#CC3A80" : props.filled ? "#3E1DAA" : "#8E8A95")};
  background: ${(props) => (props.error ? "#FBFDFF" : "#FBFDFF")};
`;

const ErrorText = styled.div`
    color: #CC3A80;
    display: flex !important;
    flex-direction: row;

    img {
        margin-right: 8px;
        height: 22px;
        width: 22px;
    }
`;

export default InputField;
