import {Link, useNavigate} from 'react-router-dom';
import styled from "styled-components";
import PINK_LOGO from "../../Assets/Autumn2023/PINK_LOGO.svg";
import SEARCH from "../../Assets/Autumn2023/Search.svg";
import USER from "../../Assets/Autumn2023/User.svg";
import ARROW_DOWN from "../../Assets/Autumn2023/Arrow.svg";
import ARROW_DOWN_ALT from "../../Assets/Autumn2023/ArrowAlt.svg";
import BURGER from "../../Assets/Autumn2023/burger.svg";
import CLOSE_BURGER from "../../Assets/Autumn2023/close_burger.svg";
import NavbarLink from "./components/NavBarLink";
import {NAVBAR_LINK_ICON_POSITION, NAVBAR_LINK_STYLE, NAVBAR_SECTION_STYLE} from "../../types/style"
import NavbarSection from './components/NavbarSection';
import {useEffect, useState} from "react";
import {signOutUser} from "../../config";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {userSelector} from "../../Store/selectors/userSelector";
import {setUser} from "../../Store/slices/userSlice";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";

interface NavbarProps {
    isAdmin: boolean
}

const Navbar = ({isAdmin}: NavbarProps) => {

    const handleLogout = async () => {
        await signOutUser();
        dispatch(setUser(null))
    };

    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const isMobile = useAppSelector(isMobileSelector);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const user = useAppSelector(userSelector);
    const dispatch = useAppDispatch()


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const isScrollingDown = currentScrollPos > prevScrollPos;
        setIsVisible(!isScrollingDown || currentScrollPos < 100);
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;

        const scrollHandler = () => {
            handleScroll();
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                setIsVisible(!isVisible);
            }, 300); // 300 milliseconds delay
        };

        window.addEventListener('scroll', scrollHandler);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [isVisible, prevScrollPos]);

    if (isMobile) {
        // mobile
        return (
            <>
                <NavHeader>
                    <img src={PINK_LOGO} alt="Logo"/>
                    {!isMenuOpen && <BurgerIcon
                        onClick={toggleMenu}
                        isMenuOpen={isMenuOpen}
                    >
                        <img
                            src={BURGER}
                            alt="Burger Menu"/>
                    </BurgerIcon>
                    }
                </NavHeader>


                <StyledMobileNavbar isMenuOpen={isMenuOpen}>
                    <CloseIcon
                        onClick={toggleMenu}
                        isMenuOpen={isMenuOpen}
                    >
                        <img
                            src={CLOSE_BURGER}
                            alt="Burger Menu"/>
                    </CloseIcon>
                    <nav>
                        <NavbarSection isMobile={isMobile}>
                            <NavbarLink
                                path="/blog"
                                label="Blog"
                                isMobile={isMobile}
                            />
                            <NavbarLink
                                id='gdynia'
                                path="/gdynia"
                                label="Gdynia"
                                icon={ARROW_DOWN}
                                altIcon={ARROW_DOWN_ALT}
                                iconPosition={NAVBAR_LINK_ICON_POSITION.RIGHT}
                                dropdown='Gdynia'
                                isMobile={isMobile}
                            />
                            <NavbarLink
                                id='gdansk'
                                path="/gdansk"
                                label="Gdańsk"
                                icon={ARROW_DOWN}
                                altIcon={ARROW_DOWN_ALT}
                                iconPosition={NAVBAR_LINK_ICON_POSITION.RIGHT}
                                dropdown='Gdansk'
                                isMobile={isMobile}/>
                            {/*<NavbarLink*/}
                            {/*    path="/archive"*/}
                            {/*    label="Archiwum"*/}
                            {/*    isMobile={isMobile}/>*/}
                            <NavbarLink
                                path="/faq"
                                label="FAQ"
                                isMobile={isMobile}/>
                        </NavbarSection>
                        <NavbarSection navbarSectionStyle={NAVBAR_SECTION_STYLE.GAP} isMobile={isMobile}>
                            <NavbarLink
                                navbarLinkStyle={NAVBAR_LINK_STYLE.PURPLE}
                                label="Szukaj"
                                path="/search"
                                icon={SEARCH}
                                isMobile={isMobile}
                                iconPosition={NAVBAR_LINK_ICON_POSITION.LEFT}/>
                            {user && <Link to="/user">
                                <NavbarLink
                                    navbarLinkStyle={NAVBAR_LINK_STYLE.PURPLE}
                                    label="Mój Festiwal"
                                    path="/user"
                                    icon={USER}
                                    iconPosition={NAVBAR_LINK_ICON_POSITION.LEFT}
                                    isMobile={isMobile}/>
                            </Link>}
                            {!user &&
                                <NavbarLink
                                    navbarLinkStyle={NAVBAR_LINK_STYLE.PURPLE}
                                    label="Zaloguj się"
                                    path="/login"
                                    icon={USER}
                                    iconPosition={NAVBAR_LINK_ICON_POSITION.LEFT}
                                    isMobile={isMobile}/>}
                            {isAdmin && <Link to="/admin" style={{padding: 5}}>
                                Admin
                            </Link>}
                            {user && <span onClick={handleLogout} style={{padding: 5, cursor: 'pointer'}}>
            Logout
            </span>}
                        </NavbarSection>
                    </nav>
                </StyledMobileNavbar>
            </>
        );
    } else {
        /// not mobile
        return (
            <StyledNavbar isVisible={isVisible}>
                <nav>
                    <NavbarSection isMobile={isMobile}>
                        <NavbarLink
                            path="/"
                            icon={PINK_LOGO}
                            navbarLinkStyle={NAVBAR_LINK_STYLE.SINGLE}
                            isMobile={isMobile}/>
                    </NavbarSection>
                    <NavbarSection shift={true} isMobile={isMobile}>
                        <NavbarLink
                            path="/blog"
                            label="Blog"
                            isMobile={isMobile}/>
                        <NavbarLink
                            id='gdynia'
                            path="/gdynia"
                            label="Gdynia"
                            icon={ARROW_DOWN}
                            altIcon={ARROW_DOWN_ALT}
                            iconPosition={NAVBAR_LINK_ICON_POSITION.RIGHT}
                            dropdown='Gdynia'
                            isMobile={isMobile}
                        />
                        <NavbarLink
                            id='gdansk'
                            path="/gdansk"
                            label="Gdańsk"
                            icon={ARROW_DOWN}
                            altIcon={ARROW_DOWN_ALT}
                            iconPosition={NAVBAR_LINK_ICON_POSITION.RIGHT}
                            dropdown='Gdansk'
                            isMobile={isMobile}/>
                        {/*<NavbarLink*/}
                        {/*    path="/archive"*/}
                        {/*    label="Archiwum"*/}
                        {/*    isMobile={isMobile}/>*/}
                        <NavbarLink
                            path="/faq"
                            label="FAQ"
                            isMobile={isMobile}/>
                    </NavbarSection>
                    <NavbarSection navbarSectionStyle={NAVBAR_SECTION_STYLE.GAP} isMobile={isMobile}>
                        <NavbarLink
                            navbarLinkStyle={NAVBAR_LINK_STYLE.PURPLE}
                            label="Szukaj"
                            path="/search"
                            icon={SEARCH}
                            iconPosition={NAVBAR_LINK_ICON_POSITION.LEFT}
                            isMobile={isMobile}/>
                        {user && <Link to="/user">
                            <NavbarLink
                                navbarLinkStyle={NAVBAR_LINK_STYLE.PURPLE}
                                label="Mój Festiwal"
                                path="/user"
                                icon={USER}
                                iconPosition={NAVBAR_LINK_ICON_POSITION.LEFT}
                                isMobile={isMobile}/>
                        </Link>}
                        {!user ?
                            <NavbarLink
                                navbarLinkStyle={NAVBAR_LINK_STYLE.PURPLE}
                                label="Zaloguj się"
                                path="/login"
                                icon={USER}
                                iconPosition={NAVBAR_LINK_ICON_POSITION.LEFT}
                                isMobile={isMobile}/>
                            : <span onClick={handleLogout} style={{padding: 5, cursor: 'pointer'}}>Logout</span>}
                        {isAdmin && <Link to="/admin" style={{padding: 5}}>
                            Admin
                        </Link>}

                    </NavbarSection>
                </nav>
            </StyledNavbar>
        );
    }
}

export default Navbar;


const StyledNavbar = styled.div<{ isVisible: boolean }>`
    position: fixed;
    top: 0;
    margin: 0 auto;
    width: 100vw;
    background: transparent;
    padding: 12px 10px;
    z-index: 999;
    transition: transform 0.1s ease-out;
    transform: ${props => (props.isVisible ? 'translateY(0px)' : 'translateY(-80px)')};

    nav {
        background-color: #FBFDFF;
        border-radius: 16px;
        height: 60px;
        width: 92vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 4px 40px;
        gap: 20px;
    } `

const StyledMobileNavbar = styled.div<{ isMenuOpen: boolean }>`
    position: fixed;
    top: 0;
    right: 0;
    width: 260px;
    height: 100vh;
    background: #FCE36E;
    padding: 12px 20px;
    z-index: 999;
    transition: transform 0.2s ease-out;
    transform: ${({isMenuOpen}) => (isMenuOpen ? 'translateX(0px)' : 'translateX(700px)')};
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    overflow: scroll;

    nav {
        padding-left: 10px;
    }
`;

const NavHeader = styled.div`
    border-radius: 16px;
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 20px;
    overflow: hidden;
    margin: 0 auto;

    img {
        padding: 0;
        margin: 0;
    }
`

const NavbarContent = styled.div`
    padding: 20px;
`;


const BurgerIcon = styled.button<{ isMenuOpen: boolean }>`
    cursor: pointer;
    width: 42px;
    height: 42px;
    background-color: #FCE36E;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

`;

const CloseIcon = styled.button<{ isMenuOpen: boolean }>`
    cursor: pointer;
    width: 42px;
    height: 42px;
    background-color: #3E1DAA;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: relative;
    margin-left: auto;
    margin-right: 10px;
`;
