import React from 'react';
import {HiPlus, HiMinus} from 'react-icons/hi';
import {useMap} from "react-leaflet";
import {MAP_ZOOM} from "../../Constants/mapConstants.d";
import useZoom from "../../Utilities/Hooks/useZoom";
import styled from "styled-components";

const MapControlsEvent = () => {
    const map = useMap();

    return (
        <MapControlPanel>
            <button disabled={useZoom(map) === MAP_ZOOM.max}
                    onClick={() => {
                        map.zoomIn(1)
                    }}>
                <HiPlus fill={'#FCE36E'}/>
            </button>
            <button disabled={useZoom(map) === MAP_ZOOM.min}
                    onClick={() => {
                        map.zoomOut(1)
                    }}>
                <HiMinus fill={'#FCE36E'}/>
            </button>
        </MapControlPanel>
    )
}

export default MapControlsEvent;


const MapControlPanel = styled.div`
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;

  button {
    border: none;
    background-color: black !important;
    border-radius: 50%;
    font-weight: 700;
    width: 40px;
    height: 40px;
    margin: 0;
    margin-bottom: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      transform: scale(1.1);
      color: black;
      border: 1px solid black;
      transition: scale 0.3s;
    }
  }
`
