import {doc, deleteDoc} from "@firebase/firestore";
import {db} from "../config";

const deleteFestival = async(id: string) => {
    try{
        const docRef = doc(db, 'festivals', id);
        await deleteDoc(docRef);

    } catch (err){
        alert('Wystąpił błąd');
        console.log(err)
    }
};

export default deleteFestival;
