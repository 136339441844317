import {doc, deleteDoc} from "@firebase/firestore";
import {db} from "../config";

const deleteBlog = async(id: string) => {
    try{
        console.log('here')
        const docRef = doc(db, 'articles', id);
        await deleteDoc(docRef);
    } catch (err){
        alert('Wystąpił błąd');
        console.log(err)
    }
};

export default deleteBlog;
