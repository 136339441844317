import React, {useState} from 'react';
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {FestivalBox, CollapsibleHead} from './styles/Collapsible.styled';
import {setActiveEvent} from "../../Store/slices/activeEventSlice";
import styled from "styled-components";




const CollapsibleEvent = (props: any) => {
    const [open, setOPen] = useState(false);
    const toggle = () => {
        setOPen(!open)
    }


    return (
        <FestivalBox>
            <CollapsibleHead>
                <p> {props.event.title}</p>
                <IconButton>
                <button onClick={toggle}>{open ? <FaChevronUp size={16}/> : <FaChevronDown size={16}/>}</button>
                </IconButton>
            </CollapsibleHead>
            {open && <div className="toggle">{props.children}</div>}
        </FestivalBox>
    );
}
export default CollapsibleEvent

const IconButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 20px 0 10px;


  button {
    border: none;
    background-color: #E9CE44;
    border-radius: 50%;
    font-weight: 700;
    width: 40px;
    height: 40px;


    :hover {
      background-color: #F5f5f5;
      color: black;
      border: 1px solid black;
      transition: background-color 0.3s, border 0.3s
    }
    

  }
`
