import {combineReducers} from "redux";
import selectedImageUrlsReducer from "./imageUrlsSlice";
import AddImagesModalReducer from "./addImagesModalSlice";
import UserReducer from "./userSlice";
import IsAdminReducer from "./isAdminSlice"
import FestivalsSlice from "./festivalsSlice";
import ActiveFestivalSlice from "./activeFestivalSlice";
import DeleteFestivalModalSlice from "./deleteFestivalModalSlice";
import ActiveArticleSlice from "./activeArticleSlice";
import EventsSlice from "./eventsSlice";
import SelectedImageUrlsLabelsSlice from "./imageUrlLabelPairSlice";
import DeleteBlogModalSlice from "./deleteBlogModalSlice";
import BlogSlice from "./blogSlice";
import DeleteEventModalSlice from "./deleteEventModalSlice";
import searchQuerySlice from "./searchQuerySlice";
import IsMobileSlice from "./isMobileSlice";
import ActiveEventSlice from "./activeEventSlice";
import IsVisibleSlice from "./isVisibleSlice";
import SelectedCitySlice from "./selectedCitySlice";

const rootReducer = combineReducers({
    selectedImageUrls: selectedImageUrlsReducer,
    selectedImageUrlsLabels: SelectedImageUrlsLabelsSlice,
    showAddImagesModal: AddImagesModalReducer,
    isAuthenticated: UserReducer,
    isAdmin: IsAdminReducer,
    isMobile: IsMobileSlice,
    festivals: FestivalsSlice,
    activeFestival: ActiveFestivalSlice,
    deleteFestivalModal: DeleteFestivalModalSlice,
    deleteEventModal: DeleteEventModalSlice,
    deleteBlogModal: DeleteBlogModalSlice,
    activeArticle: ActiveArticleSlice,
    activeEvent: ActiveEventSlice,
    events: EventsSlice,
    blog: BlogSlice,
    searchQuery: searchQuerySlice,
    isVisible: IsVisibleSlice,
    selectedCity: SelectedCitySlice,
});

export default rootReducer;
