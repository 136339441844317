import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NewFestivalData} from "../../Models/festival.model";

interface ActiveFestivalState {
    value: NewFestivalData[]
}

const initialState: ActiveFestivalState = {
    value: []
};

export const activeFestivalSlice = createSlice({
    name: 'activeFestival',
    initialState,
    reducers: {
        setActiveFestival: (state, action: PayloadAction<NewFestivalData[]>) => {
            state.value = action.payload;
        },
    },
});

export const {setActiveFestival} = activeFestivalSlice.actions;
export default activeFestivalSlice.reducer;

