import {BookingData} from "../../types/events";
import {doc, setDoc} from "@firebase/firestore";
import {db} from "../../config";

export function generateConsecutiveNumbersArray(length) {
    let array: number[] = [];
    for (let i = 1; i <= length; i++) {
        array.push(i);
    }
    return array;
}

export const calculateTotalSlots = (data: any) => {
    let totalSlots = 0;

    data.dateTimes.forEach(date => {
        totalSlots += date.timeSlots.length * data.bookingsPerSlot;
    });
    return totalSlots;
};


export function calculateSpotsForDateTime(bookings, date: string, timeSlot: string, bookingsPerSlot: number): number {
    if (bookings && bookings.length > 0){
        return bookings
            .filter((booking) => booking.date === date && booking.timeSlot === timeSlot)
            .reduce((totalSpots, booking) => totalSpots + booking.spots, 0);
    } else return 0
}

export function calculateTotalSpotsUsed(bookings: { spots: number }[]): number {
    if (bookings && bookings.length > 0) {
        return bookings.reduce((totalSpots, booking) => {
            return totalSpots + booking.spots;
        }, 0);
    } else return 0
}




export const handleDate = (date: string) => {
    switch (date) {
        case '2024-05-23':
            return "Czwartek";
        case '2024-05-24':
            return 'Piątek';
        case '2024-05-25':
            return 'Sobota';
        case '2024-05-26':
            return 'Niedziela';
        default:
            return 'Err';
    }
}
