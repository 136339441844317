import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import React, {useEffect, useRef, useState} from "react";
import {userSelector} from "../../Store/selectors/userSelector";
import {activeEventSelector} from "../../Store/selectors/activeEventSelector";
import {Link, useNavigate, useParams} from "react-router-dom";
import {EventData} from "../../Models/event.model";
import {addDoc, collection, doc, getDoc, setDoc} from "@firebase/firestore";
import {db} from "../../config";
import {BookingData} from "../../types/events";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";
import errorIcon from "../../Assets/errorIcon.svg";
import {
    AddressDiv,
    BookingWrapper,
    ErrorText, EventCard,
    EventWrapper,
    FormDiv,
    FormWrapper,
    InputWrapper, InputWrapperRow
} from "./BookingPage.styles";
import {
    calculateSpotsForDateTime,
    calculateTotalSlots,
    calculateTotalSpotsUsed,
    generateConsecutiveNumbersArray,
    handleDate
} from "./BookingPage.helpers";
import Reaptcha from "reaptcha";
import {setActiveEventStore} from "../../Store/slices/activeEventSlice";
import {eventsSelector} from "../../Store/selectors/eventsSelector";
import {fetchEventsState} from "../../Utilities/fetchEvents";


function BookingPage() {
    const user = useAppSelector(userSelector);
    const navigate = useNavigate();
    const isMobile = useAppSelector(isMobileSelector);
    const dispatch = useAppDispatch();
    const activeEvent = useAppSelector(activeEventSelector) as NonNullable<EventData>
    const [selectedDateIndex, setSelectedDateIndex] = useState(-1);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
    const [selectedNumberOfGuests, setSelectedNumberOfGuests] = useState(1);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [acceptedRules, setAcceptedRules] = useState(false);
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const captchaRef = useRef<Reaptcha | null>(null);
    const allEvents = useAppSelector(eventsSelector) as NonNullable<EventData[]>;
    const {slug} = useParams();

    useEffect(() => {
        if (allEvents === null || allEvents.length === 0) {
            const activeFestival = {id: "xth2h1HWUDSHXtjuIEqE"};
            fetchEventsState(dispatch, activeFestival.id);
            return;
        }
        const currentEvent = allEvents.filter(ev => ev.id === slug);
        currentEvent && dispatch(setActiveEventStore(currentEvent[0]))
    }, [slug, allEvents]);


    const verify = () => {
        if (captchaRef.current) {
            captchaRef.current.getResponse().then((res: string | null) => {
                setCaptchaToken(res);
            });
        }
    };

    // Event handler for selecting a date
    const handleDateChange = (event) => {
        const index = parseInt(event.target.value);
        setSelectedDateIndex(index);
    };

    // Event handler for selecting a time slot
    const handleTimeSlotChange = (event) => {
        setSelectedTimeSlot(event.target.value);
    };

    // Event handler for selecting number of guests
    const handleNumberOfGuestsChange = (event) => {
        setError('');
        setSelectedNumberOfGuests(parseInt(event.target.value));
    };

    // Event handler for entering user's name
    const handleNameChange = (event) => {
        setError('');
        setUserName(event.target.value);
    };

    // Event handler for entering user's email
    const handleEmailChange = (event) => {
        setError('');
        setUserEmail(event.target.value);
    }

    const updateEventBookings = async (
        id: string | undefined,
        bookingData: BookingData
    ) => {
        try {


            if (id === undefined) {
                return;
            }
            const docRef = doc(db, 'events', id)

            const docData = await getDoc(docRef);

            if (docData.exists()) {
                const data = docData.data();
                if (data) {
                    const currentBookings = data['bookings'];
                    const bookingsPerSlot = data['bookingsPerSlot'];
                    const numberOfSlotsBooked = currentBookings
                        .filter((booking) => booking.date === bookingData.date && booking.timeSlot === bookingData.timeSlot)
                        .reduce((totalSpots, booking) => totalSpots + booking.spots, 0);

                    // if (((bookingsPerSlot + bookingData.spots) - numberOfSlotsBooked) >= 0) {
                        const newBookings = data['bookings'].length ? [...data['bookings'], bookingData] : [bookingData];


                        await setDoc(docRef, {bookings: newBookings, id: id}, {merge: true});
                        return true;
                    // } else {
                    //     throw new Error('Brak miejsc');
                    // }
                }
            }
        } catch
            (err) {
            // alert('Wystąpił błąd!')
            console.log(err);
        }
    }


    const validateNameAndEmail = (name, email) => {
        let errors: string[] = []

        // name validation
        if (!name) {
            errors.push('Imię nie może być puste');
        } else if (!/^[A-Za-ząęółńśćżźĄĘÓŁŃŚĆŻŹ ]+$/g.test(name)) {
            errors.push('Imię może zawierać tylko litery');
        }

        // email validation
        if (!email) {
            errors.push('Adres email nie może być pusty');
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g.test(email)) {
            errors.push('Adres email jest niepoprawny');
        }

        return errors;
    }


    const handleSubmit = async () => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        if (!acceptedRules) {
            setError('By dokonać rezerwacji musisz zaakcpetować regulamin');
            return;
        }

        const errors = validateNameAndEmail(userName, userEmail);

        if (!user && errors?.length > 0) {
            setError(errors.join(', '));
            return;
        }

        if (activeEvent.bookings?.filter((booking) => booking.userUid === user?.uid).length > 0) {
            setError("Jesteś już zapisany/a na to wydarzenie");
            return;
        }

        if (activeEvent.bookings?.filter((booking) => booking.userEmail === userEmail).length > 0) {
            setError("Jesteś już zapisany/a na to wydarzenie");
            return;
        }


        if (!activeEvent.bookingsPerSlot) {
            setError("Wydarzenie nie wymaga rejestracji");
            return;
        }

        // if (calculateTotalSpotsUsed(activeEvent.bookings) < calculateTotalSlots(activeEvent)) {
        //     if (activeEvent.dateTimes[selectedDateIndex]?.date && selectedTimeSlot) {
        //         if (calculateSpotsForDateTime(activeEvent.bookings, activeEvent.dateTimes[selectedDateIndex]?.date, selectedTimeSlot, activeEvent.bookingsPerSlot) + selectedNumberOfGuests <= activeEvent.bookingsPerSlot) {

                    const result = await updateEventBookings(activeEvent.id, {
                        userName: user?.displayName ? user?.displayName : userName,
                        userEmail: user?.email ? user?.email : userEmail,
                        userUid: user?.uid || "",
                        date: activeEvent.dateTimes[selectedDateIndex]?.date,
                        timeSlot: selectedTimeSlot,
                        spots: selectedNumberOfGuests,
                    });

                    if (result) {
                        setSuccess("Dziękujemy za zgłoszenie, potwierdzenia wyślemy niebawem!");
                        try {
                            await delay(Math.floor(Math.random() * 10) * 500);
                            await addDoc(collection(db, 'mail'),
                                {
                                    to: user?.email || userEmail,
                                    message: {
                                        subject: `Finalne potwierdzenie rezerwacji - ${activeEvent.title}`,
                                        html: `
<h1>Cześć ${user?.displayName || userName}!</h1>
<h2>FINALNE POTWIERDZENIE TWOJEJ REZERWACJI</h2> 
<p>dziękujemy za cierpliwość i do zobaczenia na Open House Gdynia 2024!</p> 
<hr/> 
           <p>Otrzymaliśmy twoją rezerwację na - ${activeEvent.title}</p>
        <img src="${activeEvent.images[0].url}" alt="Event Image" style="width:300px; height:auto;" />
           <p>Szczegóły rezerwacji:</p>
           <ul>
               <li>Data: ${activeEvent.dateTimes[selectedDateIndex]?.date}</li>
               <li>Godzina: ${selectedTimeSlot}</li>
               <li>Ilość biletów: ${selectedNumberOfGuests}</li>
               <li>Adres: ${activeEvent.address}</li>
           </ul>
           <p>Jeśli chcesz zrezygnować możeż zrobić to w panelu "Mój festiwal" lub dać nam znać:</p>`
                                    },
                                }
                            );
                        } catch (e) {
                            setError("Dziękujemy za zainteresowanie, oczekuj potwierdzenia e-mail")
                        }
                    }
        //         } else {
        //             setError("Wszystkie miejsca zostały zarezerwowane - wybierz inną datę lub godzinę");
        //         }
        //     } else {
        //         setError("Wybierz datę oraz godzinę");
        //     }
        // } else {
        //     setError("Brak miejsc na wybrane wydarzenie");
        // }
    };


//     const handleSubmit = async () => {
//         const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
//
//         if (!acceptedRules) {
//             setError('By dokonać rezerwacji musisz zaakcpetować regulamin');
//             return;
//         }
//
//         const errors = validateNameAndEmail(userName, userEmail);
//
//         if (!user && errors?.length > 0) {
//             setError(errors.join(', '));
//             return;
//         }
//
//         if (activeEvent.bookings?.filter((booking) => booking.userUid === user?.uid).length > 0) {
//             setError("Jesteś już zapisany/a na to wydarzenie");
//             return;
//         }
//
//         if (activeEvent.bookings?.filter((booking) => booking.userEmail === userEmail).length > 0) {
//             setError("Jesteś już zapisany/a na to wydarzenie");
//             return;
//         }
//
//
//         if (!activeEvent.bookingsPerSlot) {
//             setError("Wydarzenie nie wymaga rejestracji");
//             return;
//         }
//
//         const result = await updateEventBookings(activeEvent.id, {
//             userName: user?.displayName ? user?.displayName : userName,
//             userEmail: user?.email ? user?.email : userEmail,
//             userUid: user?.uid || "",
//             date: activeEvent.dateTimes[selectedDateIndex]?.date,
//             timeSlot: selectedTimeSlot,
//             spots: selectedNumberOfGuests,
//         });
//
//         if (result) {
//             setSuccess("Dziękujemy za zgłoszenie, potwierdzenia wyślemy niebawem!");
//             try {
//                 await delay(Math.floor(Math.random() * 10) * 500);
//                 await addDoc(collection(db, 'mail'),
//                     {
//                         to: user?.email || userEmail,
//                         message: {
//                             subject: `Potwierdzenie rezerwacji - ${activeEvent.title}`,
//                             html: `
// <h1>Cześć ${user?.displayName || userName}!</h1>
// <h2>FINALNE POTWIERDZENIE TWOJEJ REZERWACJI</h2> <p>dziękujemy za cierpliwość i do zobaczenia na Open House Gdynia 2024!</p>
// <hr/>
//            <p>Otrzymaliśmy twoją rezerwację na - ${activeEvent.title}</p>
//         <img src="${activeEvent.images[0].url}" alt="Event Image" style="width:300px; height:auto;" />
//            <p>Szczegóły rezerwacji:</p>
//            <ul>
//                <li>Data: ${activeEvent.dateTimes[selectedDateIndex]?.date}</li>
//                <li>Godzina: ${selectedTimeSlot}</li>
//                <li>Ilość biletów: ${selectedNumberOfGuests}</li>
//                <li>Adres: ${activeEvent.address}</li>
//            </ul>
// <p>Jeśli chcesz zrezygnować daj nam znać, ponieważ rezerwacja w tym przypadku może nie być widoczna w Twojej zakładce mój festiwal</p>`                        },
//                     }
//                 );
//             } catch (e) {
//                 setError("Dziękujemy za zainteresowanie, oczekuj potwierdzenia e-mail")
//             }
//         }
//     };


    return activeEvent !== null ? (
        <BookingWrapper isMobile={isMobile}>
            <FormWrapper isMobile={isMobile}>
                <h1>Zarezerwuj Wejście</h1>
                {success && <div>{success}</div>}
                {error && <ErrorText><img src={errorIcon} alt=''/>{error}</ErrorText>}
                {!success ? <><FormDiv>
                        <InputWrapper>
                            <label htmlFor="dateSelect">Dzień Tygodnia:</label>
                            <select id="dateSelect" onChange={handleDateChange} value={selectedDateIndex}>
                                <option>Dzień tygodnia:</option>
                                {activeEvent.dateTimes.map((item, index) => (
                                    <option key={index} value={index}>{handleDate(item.date)}</option>
                                ))}
                            </select>
                        </InputWrapper>
                        <InputWrapper>
                            <label htmlFor="timeSelect">Wybierz godzinę:</label>
                            <select id="timeSelect" onChange={handleTimeSlotChange} value={selectedTimeSlot}>
                                <option>Wybierz godzinę:</option>
                                {selectedDateIndex !== -1 && activeEvent.dateTimes[selectedDateIndex].timeSlots.map((timeSlot, index) => {

                                    const bookedSpots = activeEvent.bookings?.length
                                        ? calculateSpotsForDateTime(activeEvent.bookings, activeEvent.dateTimes[selectedDateIndex]?.date, timeSlot, activeEvent.bookingsPerSlot as NonNullable<number>)
                                        : 0

                                    return (
                                        <option key={index} value={timeSlot}
                                                // disabled={bookedSpots >= (activeEvent.bookingsPerSlot as NonNullable<Number>)}>
                                            >
                                            {`${timeSlot} (Dostępne miejsca: ${(activeEvent.bookingsPerSlot as NonNullable<Number>) - bookedSpots})`}
                                        </option>
                                    );
                                })}
                            </select>
                        </InputWrapper>
                        <InputWrapper>
                            <label htmlFor="numberOfGuestsSelect">Liczba biletów:</label>
                            <select id="numberOfGuestsSelect" onChange={handleNumberOfGuestsChange}
                                    value={selectedNumberOfGuests}>
                                {generateConsecutiveNumbersArray(activeEvent.bookingsPerUser).map((number) => (
                                    <option key={number} value={number}>{number}</option>
                                ))}
                            </select>
                        </InputWrapper>

                        {!user ?
                            <>
                                <InputWrapper>
                                    <label htmlFor="userName">Imię i nazwisko:</label>
                                    <input id="userName" type="text" onChange={handleNameChange}/>
                                </InputWrapper>
                                <InputWrapper>
                                    <label htmlFor="userEmail">Adres Email:</label>
                                    <input id="userEmail" type="email" onChange={handleEmailChange}/>
                                </InputWrapper>
                            </> : null}

                        <InputWrapperRow>
                            <label><Link to={'/rules'}>Akceptuję regulamin</Link></label>
                            <input type="checkbox" checked={acceptedRules}
                                   onChange={(e) => setAcceptedRules(e.target.checked)}/>
                        </InputWrapperRow>


                        <Reaptcha sitekey={'6LfS_8YpAAAAAKofOBMAjF7VlixAsfvIXPSo8n0j'} ref={captchaRef}
                                  onVerify={verify}/>
                    </FormDiv>

                        <Button onClick={handleSubmit} label={"Zarezerwuj wejście"} buttonStyle={BUTTON_STYLE.GREEN}/>
                    </>
                    : <Button onClick={() => navigate(-1)} label={"Wróc do wydarzeń"} buttonStyle={BUTTON_STYLE.GREEN}/>
                }
            </FormWrapper>
            <EventWrapper isMobile={isMobile}>
                <EventCard isMobile={isMobile}>
                    {activeEvent.images?.length && <img src={activeEvent?.images[0].url}/>}
                    <h2>{activeEvent.title}</h2>
                    <AddressDiv>
                        <h3>Gdzie?</h3>
                        <h4>{activeEvent.address}</h4>
                    </AddressDiv>
                </EventCard>
            </EventWrapper>
        </BookingWrapper>
    ) : <div>Loading...</div>
}

export default BookingPage
