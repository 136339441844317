import {db} from "../config";
import {doc, setDoc,} from "@firebase/firestore";

const updateIsArticleVisible = async (
    id: string | undefined,
    isVisible?: boolean,
) => {
    try {
        if (id === undefined) return;
        const docRef = doc(db, 'articles', id)
        await setDoc(docRef, {isVisible: !isVisible, id:id}, {merge: true});
        console.log("success!");

    } catch(err){
        alert('Wystąpił błąd!')
        console.log(err);
    }
}

export default updateIsArticleVisible
