import styled from "styled-components";

export const StyledPlaylistSection = styled('section')<{isMobile: boolean}>`
  display: flex;
  width: 100vw;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  background: #FFA4CF;
  font-family: Cabinet Grotesk;
  font-style: normal;
  line-height: 120%;
  margin: 0;
  padding: ${({isMobile}) => (isMobile ? '30px 8px' : '0')};
`;

export const EmbedDiv = styled('div')<{isMobile: boolean}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({isMobile}) => (isMobile ? 'left' : 'center')};
  padding: ${({isMobile}) => (isMobile ? '0 8px 30px 8px' : '0 40px')};


  h3 {
    font-size: 58px;
    max-width: 662px;
    line-height: 100%;
    margin: 0 0 16px 0;
    
  }
  
  iframe{
    border: none;
    margin-top: 20px;
  }

  p {
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.24px;
    font-family: Arial;
    padding: ${({isMobile}) => (isMobile ? '0 0 20px 0' : '0')};

  }
`;

export const DecorativeDiv = styled('div')<{isMobile: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({isMobile}) => (isMobile ? '100%' : '45%')};
  height: 680px;
  padding: 0;
  margin: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 56px;
    z-index: 999;
    width: 259px;
  }

  img {
    width: 262px;
    height: 211px;
    z-index: 999;
      overflow: hidden;
  }
`;

export const BackgroundDiv = styled('div')<{isMobile: boolean}>`
  position: absolute;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow-y: clip;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
`;

export const ContentDiv = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 200px 166px 156px 166px;
  margin: 0;
  `
