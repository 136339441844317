import {BORDER_STYLE} from "../../types/style";

export const calculateBorderStyle = (index) => {
    switch (index+1) {
        case 1:
        case 4:
        case 7:
            return BORDER_STYLE.TOP_LEFT;
        case 2:
        case 8:
            return BORDER_STYLE.NONE;
        case 3:
        case 9:
            return BORDER_STYLE.BOTTOM_RIGHT;
        default:
            return BORDER_STYLE.BOTH_SIDES;
    }
}
