import React, { useMemo, useState} from "react";
import {EventView, EventViewFilters, EventViewWeekdayFilters} from "../../Models/menu.model";
import styled from "styled-components";
import MapProvider from "../../Components/Map/MapProvider";
import EventTile from "../GdyniaFestival/components/EventTile";
import EventList from "../GdyniaFestival/components/EventList";
import GdyniaEventsHeaderMobile from "./components/GdyniaEventsHeaderMobile";
import GdyniaEventsHeader from "./components/GdyniaEventsHeader";
import {useAppSelector} from "../../Store/hooks";
import {eventsSelector} from "../../Store/selectors/eventsSelector";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";
import {Link} from "react-router-dom";

function GdyniaDescription() {

    const events = useAppSelector(eventsSelector);
    const [eventViewMode, setEventViewMode] = useState(EventView.tile)
    const [filteredEvents, setFilteredEvents] = useState(EventViewFilters.ALL);
    const [filteredEventsByDay, setFilteredEventsByDay] = useState(EventViewWeekdayFilters.ALL);
    const isMobile = useAppSelector(isMobileSelector);

    function formatDateToPolish(dateString) {
        const daysOfWeek = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        const date = new Date(dateString);
        const dayOfWeek = daysOfWeek[date.getUTCDay()];
        const day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        return `${dayOfWeek}, ${day}.${month}`;
    }


    const filterAndSortEvents = (events, viewFilter, weekdayFilter) => {
        let filteredEvents = [...events]; // Copy all events

        if (viewFilter === EventViewFilters.RESERVATIONS) {
            filteredEvents = filteredEvents.filter(event => event.bookingUrl);
        } else if (viewFilter === EventViewFilters.NO_RESERVATIONS) {
            filteredEvents = filteredEvents.filter(event => !event.bookingUrl);
        }

        if (weekdayFilter !== EventViewWeekdayFilters.ALL) {
            // Filter events by the selected weekday
            const targetDay = getTargetDayOfWeek(weekdayFilter); // Implement getTargetDayOfWeek function
            filteredEvents = filteredEvents.filter(event =>
                event.dateTimes.some(dateTime => {
                    const eventDate = new Date(dateTime.date);
                    return eventDate.getDay() === targetDay;
                })
            );
        }

        // Sort the filtered events by name
        filteredEvents.sort((eventA, eventB) =>
            eventA.title.localeCompare(eventB.title)
        );

        return filteredEvents;
    };

    function getTargetDayOfWeek(weekdayFilter) {
        switch (weekdayFilter) {
            case EventViewWeekdayFilters.THURSDAY:
                return 4; // Friday corresponds to day 5
            case EventViewWeekdayFilters.FRIDAY:
                return 5; // Friday corresponds to day 5
            case EventViewWeekdayFilters.SATURDAY:
                return 6; // Saturday corresponds to day 6
            case EventViewWeekdayFilters.SUNDAY:
                return 0; // Sunday corresponds to day 0
            default:
                return -1; // Return an invalid value for other cases
        }
    }
    const thursdayEvents = useMemo(() => filterAndSortEvents(events, filteredEvents, 'THURSDAY'), [events, filteredEvents]);
    const fridayEvents = useMemo(() => filterAndSortEvents(events, filteredEvents, 'FRIDAY'), [events, filteredEvents]);
    const saturdayEvents = useMemo(() => filterAndSortEvents(events, filteredEvents, 'SATURDAY'), [events, filteredEvents]);
    const sundayEvents = useMemo(() => filterAndSortEvents(events, filteredEvents, 'SUNDAY'), [events, filteredEvents]);

    const dateStringCzwartek = "2024-05-23";
    const dateStringPiatek = "2024-05-24";
    const dateStringSobota = "2024-05-25";
    const dateStringNiedziela = "2024-05-26";

    const formattedDateCzwartek = formatDateToPolish(dateStringCzwartek);
    const formattedDatePiatek = formatDateToPolish(dateStringPiatek);
    const formattedDateSobota = formatDateToPolish(dateStringSobota);
    const formattedDateNiedziela = formatDateToPolish(dateStringNiedziela);

    if (!events || events.length === 0) {
        return <div>Loading...</div>
    }

    if (isMobile) {
        return (
            <MobileWrapper>
                <GdyniaEventsHeaderMobile
                    eventViewMode={eventViewMode}
                    setEventViewMode={setEventViewMode}
                    filteredEvents={filteredEvents}
                    setFilteredEvents={setFilteredEvents}
                    filteredEventsByDay={filteredEventsByDay}
                    setFilteredEventsByDay={setFilteredEventsByDay}
                />
                {eventViewMode === EventView.list
                    && <EventList
                        events={filterAndSortEvents(events, filteredEvents, filteredEventsByDay)}
                        isMobile={isMobile}/>}
                {eventViewMode === EventView.map
                    && <MapProvider events={filterAndSortEvents(events, filteredEvents, filteredEventsByDay)}/>}
                {eventViewMode === EventView.tile
                    && <>
                        {thursdayEvents
                            && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                                || filteredEventsByDay === EventViewWeekdayFilters.THURSDAY)
                            && <EventTiles isMobile={isMobile}>
                                {thursdayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>}
                        {fridayEvents
                            && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                                || filteredEventsByDay === EventViewWeekdayFilters.FRIDAY)
                            && <EventTiles isMobile={isMobile}>
                                {fridayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>}
                        {saturdayEvents
                            && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                                || filteredEventsByDay === EventViewWeekdayFilters.SATURDAY)
                            && <EventTiles isMobile={isMobile}>
                                {saturdayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>}
                        {sundayEvents
                            && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                                || filteredEventsByDay === EventViewWeekdayFilters.SUNDAY)
                            && <EventTiles isMobile={isMobile}>
                                {sundayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        }
                    </>}
            </MobileWrapper>
        )
    }


    return (
        <Wrapper>
            <Breadcrumb aria-label="Breadcrumb" className="breadcrumb">
                <ol>
                    <li>
                        <Link to="/">
                            Strona Główna
                        </Link>
                        <span>/</span>
                    </li>
                    <li>
                        <Link to="/gdynia">
                            Gdynia
                        </Link>
                        <span>/</span>
                    </li>
                    <li>
                        <Link to="/gdynia/events">
                            Program
                        </Link>
                    </li>
                </ol>
            </Breadcrumb>

            <GdyniaEventsHeader
                eventViewMode={eventViewMode}
                setEventViewMode={setEventViewMode}
                filteredEvents={filteredEvents}
                setFilteredEvents={setFilteredEvents}
                filteredEventsByDay={filteredEventsByDay}
                setFilteredEventsByDay={setFilteredEventsByDay}
            />
            {eventViewMode === EventView.list
                && <EventList
                    events={filterAndSortEvents(events, filteredEvents, filteredEventsByDay)}
                    isMobile={isMobile}/>}
            {eventViewMode === EventView.map
                && <MapProvider events={filterAndSortEvents(events, filteredEvents, filteredEventsByDay)}/>}
            {eventViewMode === EventView.tile
                && <>
                    {thursdayEvents
                        && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                            || filteredEventsByDay === EventViewWeekdayFilters.THURSDAY)
                        && <>
                            <Separator>
                                <h4>{formattedDateCzwartek}</h4>
                            </Separator>
                            <EventTiles isMobile={isMobile}>
                                {thursdayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        </>
                    }
                    {fridayEvents
                        && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                            || filteredEventsByDay === EventViewWeekdayFilters.FRIDAY)
                        && <>
                            <Separator>
                                <h4>{formattedDatePiatek}</h4>
                            </Separator>
                            <EventTiles isMobile={isMobile}>
                                {fridayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        </>
                    }
                    {saturdayEvents
                        && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                            || filteredEventsByDay === EventViewWeekdayFilters.SATURDAY)
                        && <>
                            <Separator>
                                <h4>{formattedDateSobota}</h4>
                            </Separator>
                            <EventTiles isMobile={isMobile}>
                                {saturdayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        </>
                    }
                    {sundayEvents
                        && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                            || filteredEventsByDay === EventViewWeekdayFilters.SUNDAY)
                        && <>
                            <Separator>
                                <h4>{formattedDateNiedziela}</h4>
                            </Separator>
                            <EventTiles isMobile={isMobile}>
                                {sundayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        </>}
                </>}
        </Wrapper>
    );
}

export default GdyniaDescription


const Wrapper = styled.div`
    width: 1440px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: #F5F4F5;
    padding: 120px 40px 80px 40px;
`

const MobileWrapper = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #F5F4F5;
    padding: 0;
`


const EventTiles = styled.div`
    width: ${({isMobile}) => (isMobile ? '100vw' : '1440px')};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${({isMobile}) => (isMobile && 'center')};
    position: relative;
    top: ${({isMobile}) => (isMobile ? '20px' : '40px')};
    gap: ${({isMobile}) => (isMobile ? '10px' : '30px')};
    margin-top: 10px;
`


const Separator = styled.div`
    width: 95%;
    border-bottom: 1px solid black;
    position: relative;
    top: 40px;
    margin-top: 20px;

    h4 {
        color: #1B0466;
        font-family: Arial;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
`

export const Breadcrumb = styled.nav`
    a {
        text-decoration: none;
        color: #514D58;

        &:last-of-type {
            color: #1F0812;
        }
    }


    ol {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    li {
        text-decoration: none;
        color: #514D58;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        cursor: pointer;

        &:last-of-type {
            font-size: 18px;
        }
    }

    span {
        padding: 0 8px;
    }

    a:hover {
        color: #3e1daa;
    }
`
