import * as Yup from 'yup';

export const festivalSchema = Yup.object().shape({
    title: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .required('Required'),
    description: Yup.string()
        .min(50, 'Must be 50 characters or more')
        .required('Required'),
    location: Yup.string().required('Required')

})
