export function generateBlogPostParts(blogPost) {

    const remainingImages = blogPost.images.slice(1);
    const imageCount = remainingImages.length;
    const sentences = blogPost.content.split('. ');

    const sentencesPerImage = Math.ceil(sentences.length / imageCount);
    const resultArray = remainingImages.map((image, index) => {
        const startIndex = index * sentencesPerImage;
        const endIndex = startIndex + sentencesPerImage;
        let part = sentences.slice(startIndex, endIndex).join('. ');
        const lastSentence = part.split('. ').pop();
        if (lastSentence && !lastSentence.endsWith('.')) {
            part += '.';
        }

        return {
            part,
            imageLabel: image.label,
            imageUrl: image.url
        };
    });

    if (imageCount === 0) {
        return [{
            part: blogPost.content,
            imageLabel: '',
            imageUrl: ''
        }]
    } else return resultArray;
}
