export enum NAVBAR_LINK_STYLE {
    PURPLE = 'PURPLE',
    SINGLE = 'SINGLE',
}

export enum BUTTON_STYLE {
    GREEN = 'GREEN',
    PINK = 'PINK',
    SECONDARY = 'SECONDARY',
    SECONDARY_NO_PADDING = 'SECONDARY_NO_PADDING',
    FILTERS = 'FILTERS',
    FILTERS_ACTIVE = 'FILTERS_ACTIVE',
}

export enum BORDER_STYLE {
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    TOP_LEFT = 'TOP_LEFT',
    NONE = 'NONE',
    BOTH_SIDES = 'BOTH_SIDES',
}

export enum NAVBAR_LINK_ICON_POSITION {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export enum NAVBAR_SECTION_STYLE {
    GAP = 'GAP',
}

