import CURRENT_GDA from "../../Assets/HomePageAssets/gdacover.webp";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";
import React from "react";
import {useAppSelector} from "../../Store/hooks";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";


function GdanskEvents() {

    const navigate = useNavigate();
    const isMobile = useAppSelector(isMobileSelector);


    return (<Wrapper isMobile={isMobile}>
        {!isMobile ? <Header isMobile={isMobile}>
            <CardContainer>
                <ColorSquare color={'#E2FD5F'} isMobile={isMobile}/>
                <Image src={CURRENT_GDA} alt={'gdansk'} isMobile={isMobile}/>
            </CardContainer>

            <FestivalTitleDiv isMobile={isMobile}>
                <h1>Gdańsk</h1>
                <div>
                    <h2>18.10 – 20.10.2024</h2>
                    <Bullet isMobile={isMobile}>VII - ANIOŁKI*
                    </Bullet>
                </div>
            </FestivalTitleDiv>
        </Header> : null}

        <ContentDiv isMobile={isMobile}>
            {isMobile ?
                <>
                    <CardContainer isMobile={isMobile}>
                        <ColorSquare color={'#E2FD5F'} isMobile={isMobile}/>
                        <Image src={CURRENT_GDA} alt={'gdansk'} isMobile={isMobile}/>
                    </CardContainer>

                    <FestivalTitleDiv isMobile={isMobile}>
                        <h1>Gdansk</h1>
                        <div>
                            <h2>18.10 – 20.10.2024</h2>
                            <Bullet isMobile={isMobile}>VII - ANIOŁKI*
                            </Bullet>
                        </div>
                    </FestivalTitleDiv>
                </>
                : null}
            <DescriptionDiv isMobile={isMobile}>
                <Title isMobile={isMobile}>
                    <h2>O festiwalu</h2>
                </Title>
                <Text isMobile={isMobile}>
                    <p>
                        Mijane między Wrzeszczem a Śródmieściem, wlewające się w podleśną kieszeń miasta, wycofane.
                        Odwiedzane zazwyczaj z konkretnego powodu lub z potrzeby miejskiej eksploracji. Aniołki. </p>
                    <span>Siódma edycja Open House Gdańsk dostarczy wystarczająco wielu argumentów do tego, by spędzić
                        festiwalowy weekend w kameralnej, a przy tym malowniczej dzielnicy. Za pomocą programowanych
                        wydarzeń opiszemy jej różnorodność rozpiętą między sąsiedztwem stoczni i granicą Suchanina. Na
                        tym stosunkowo niewielkim obszarze współistnieją ze sobą budynki pokolejowe, założenia parkowe i
                        cmentarne, potężny kompleks medyczny, dojrzała i zupełnie młoda architektura codzienności, a
                        pomiędzy nimi nić komunikacyjna nawleczona na Wielką Aleję.</span>
                    <p> Granice administracyjne potraktujemy jednak dość swobodnie, rozmiękczając je i sięgając po
                        przestrzenie Aniołkami-niemal-będące: ku Górze Gradowej i dawnym fortyfikacjom, w kierunku
                        stadionu i cerkwi. Granice międzydzielnicowe są tu bowiem miejscami napięć, nawarstwień, spotkań
                        niemożliwych. Będziemy dekodować je poprzez indywidualne doświadczanie miejsca, ale skorzystamy
                        też z przewodnictwa osób eksperckich. Dostrzeżemy unikalną tożsamość dzielnicy, a przy tym
                        wykonamy krok w tył, by zrozumieć szerszą perspektywę.</p>
                    <p>W architekturze Aniołków znajdziemy ślady, które pozostawiły po sobie historyczne wydarzenia i
                        procesy ponadlokalne. Każda z edycji
                        Open House Gdańsk mówi o wspólnocie w różnorodności, współodczuwaniu, nawiązywaniu i wzmacnianiu
                        relacji. Troska jest niejako wpisana w Aniołki za sprawą obecnych tam instytucji oraz osób
                        indywidualnych, które wspierają słabszych, cichszych - międzypokoleniowo, międzygatunkowo, w
                        odpowiedzi na potrzeby. Te głosy i te praktyki pragniemy wzmocnić.</p>
                    <p>Zadamy pytania o rolę
                        przyrody we współczesnym mieście i nasze z nią relacje. Przyjrzymy się kondycji architektury
                        historycznej w kontekście otaczających ją przemian i trawiących ją procesów. Przekonamy się też,
                        jakie formy przybiera i przybierała publiczna opieka - fizyczna, duchowa. Wszystko to w
                        demokratycznej strukturze uczestnictwa i otwartości na zróżnicowanie.</p>
                    <p> Zwiedzanie większości
                        tegorocznych lokalizacji odbywa się bez rezerwacji, w godzinach wskazanych w programie i na
                        zasadzie kolejności przyjścia. W przypadku, kiedy zabraknie dla ciebie miejsca w grupie, zapytaj
                        kogoś z naszego zespołu o najbliższe, otwarte punkty programu - lub sprawdź samodzielnie na
                        stronie openhousefestival.pl. Zachęcamy do korzystania z komunikacji zbiorowej i roweru - to
                        najlepsze sposoby na poruszanie się po mieście, szczególnie w tej dzielnicy. Na wszelki wypadek
                        chwyć ze sobą kurtkę przeciwdeszczową i (lub) parasol. Ponieważ Aniołki nie mają rozbudowanej
                        oferty gastronomicznej, warto zabrać ciepły napój w termosie i przekąskę. Jeśli mimo przygotowań
                        nie zaopatrzysz się w gorącą herbatę, poratujemy cię w punkcie info. Możesz też poprosić o nią w
                        prywatnych mieszkaniach.</p>

                    <p> Festiwalowe punkty informacyjne znajdują się w dwóch miejscach:
                        <ul>
                            <li>PUNKT INFO ANIOŁKI - Collegium Biomedicum (CBM), Dębinki 1</li>
                            <li>PUNKT INFO HEVELIANUM, Gradowa 6, kasy biletowe Hevelianum</li>
                        </ul>
                        Do zobaczenia na trasie!
                    </p>
                    <Button
                        buttonStyle={BUTTON_STYLE.GREEN}
                        onClick={() => navigate("/gdansk/events")}
                        label='Przejdź do wydarzeń'/>
                </Text>
            </DescriptionDiv>
        </ContentDiv>
    </Wrapper>)
        ;
}

export default GdanskEvents


const Wrapper = styled.div`
    height: ${({isMobile}) => (!isMobile && '2600px')};
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-x: hidden;
    padding: ${({isMobile}) => (isMobile ? '16px' : 'inherit')};

    button {
        margin: 80px 0 40px 0;
        width: 320px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 0;
        }
    }
`


const FestivalTitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    max-height: ${({isMobile}) => (!isMobile && '200px')};
    opacity: 1;
    margin: ${({isMobile}) => (!isMobile && 'auto 0 0 auto')};
    padding-right: 40px;
    position: relative;


    h1 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk, sans -serif;
        font-size: ${({isMobile}) => (isMobile ? '70px' : '140px')};
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 140px */
        letter-spacing: -1.4px;
        margin: 0;
    }

    h2 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk, sans -serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        margin: 0;
    }

    & > div {
        display: flex;
        flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
        justify-content: space-between;
        align-items: ${({isMobile}) => (isMobile ? 'left' : 'center')};
    }
`
const Bullet = styled.div`
    color: #3E1DAA;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    display: flex;
    padding: 12px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    background: #E2FD5F;
    overflow-x: ${({isMobile}) => (!isMobile && '16px 0')};
    max-width: 200px;
    margin: ${({isMobile}) => (isMobile && '16px 0')};

`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    color: #1F0812;
    width: ${({isMobile}) => (isMobile ? '97%' : '785px')};
    font-family: Arial, sans-serif;

    p {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 32px 0;
    }

    span {
        font-size: 24px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
        margin: 0 0 32px 0;
    }

    ul {
        margin: 10px;
        font-size: 18px;
    }
    
    li {
        padding-left: 20px;
    }
`

const Title = styled.div`
    width: ${({isMobile}) => (isMobile ? '90%' : '37%')};

    h2 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk, sans -serif;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-align: ${({isMobile}) => (isMobile ? 'left' : 'right')};
        padding-right: ${({isMobile}) => (isMobile ? '0' : '35px')};
    }
`

const DescriptionDiv = styled.div`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    gap: 20px;
    width: ${({isMobile}) => (isMobile ? '100%' : '80%')};
    margin: 0 auto;
    overflow-x: hidden;
`
const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({isMobile}) => (isMobile ? '0' : '70px 40px')};
    position: ${({isMobile}) => (!isMobile ? 'absolute' : 'relative')};
    top: ${({isMobile}) => (!isMobile ? '50vw' : '5vh')};
    width: ${({isMobile}) => (isMobile ? '95%' : '1440px')};
    overflow-x: hidden;

    @media (max-width: 1280px) {
        width: ${({isMobile}) => (isMobile ? '95%' : '1280px')};
    }
`

const Header = styled.div`
    display: flex;
    gap: 5vw;
    position: relative;
    margin-top: ${({isMobile}) => (isMobile ? '30px' : '120px')};
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
`

const CardContainer = styled.div`
    width: ${({isMobile}) => (isMobile ? '86vw' : '40vw')};
    height: ${({isMobile}) => (isMobile ? '60vw' : '40vw')};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;
    margin-bottom: ${({isMobile}) => (isMobile && '26vw')};

`;

export const ColorSquare = styled.div`
    width: ${({isMobile}) => (isMobile ? '70vw' : '55vw')};
    height: ${({isMobile}) => (isMobile ? '70vw' : '40vw')};
    background-color: ${props => props.color};
    position: absolute;
    top: -20px;
    left: 20px;
`;

export const Image = styled.img`
    position: absolute;
    max-height: ${({isMobile}) => (isMobile ? '70vw' : '50vw')};
    max-width: ${({isMobile}) => (isMobile ? '70vw' : '55vw')};
    border-radius: 0 300px 0 0;
    left: ${({isMobile}) => (isMobile ? '0px' : 0)};

`;
