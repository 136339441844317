import React, {Dispatch, SetStateAction, useState} from 'react';
import {useFormik} from "formik";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {festivalsSelector} from "../../Store/selectors/festivalsSelector";
import {EventFormData} from "../../Models/event.model";
import styled from "styled-components";
import {AdminPagesDisplay} from "../../Models/menu.model";
import AddImagesModal from "../../Components/Common/AddImagesModal";

type Props = {
    submit: (values?: any) => void;
    setPage: Dispatch<SetStateAction<AdminPagesDisplay>>;
}

const AdminPanelAddEvent = (props: Props) => {
    const dispatch = useAppDispatch();
    const festivals = useAppSelector(festivalsSelector);
    const [secondDay, setSecondDay] = useState(false);
    const formik = useFormik({
        initialValues: {
            address: "",
            address2: "",
            date: "",
            timeSlots: "",
            secondDate: "",
            secondTimeSlots: "",
            images: [],
            festivalId: "",
            title: "",
            description: "",
            bookingUrl: "",
            bookingsPerSlot: 0,
            bookingsPerUser: 0,
        },
        onSubmit: (values: EventFormData) => {
            props.submit(values)
            props.setPage(AdminPagesDisplay.events)
        }
    });

    return (
        <FormWrap>
            <h2>Dodaj Wydarzenie</h2>
            <form onSubmit={formik.handleSubmit}>

                <FormUnit>
                <label htmlFor="title">Nazwa</label>
                <input
                    id="title" name="title"  type="text"
                    onChange={formik.handleChange} value={formik.values.title}
                    style={{
                        backgroundColor: formik.touched.title && formik.errors.title ? "#FFD8C9" : "",
                        color: formik.touched.title && formik.errors.title ? "red" : ""
                    }}/>
                {formik.touched.title && formik.errors.title ? (
                    <ErrorDiv>{formik.errors.title}</ErrorDiv>
                ) : null}

                <label htmlFor="description">Opis</label>

                <textarea
                    id="description" name="description" rows={10} cols={50}
                    onChange={formik.handleChange} value={formik.values.description}/>
                    <p>FYI: Aby wygenerować nowy akapit - po spacji napisz: /n </p>
                </FormUnit>
                <FormUnit>

                <label htmlFor="address"> Adres </label>
                <input
                    id="address" name="address"
                    onChange={formik.handleChange} value={formik.values.address}/>

                    <label htmlFor="address2"> Komentarz do adresu </label>
                    <input
                        id="address2" name="address2"
                        onChange={formik.handleChange} value={formik.values.address2}/>


                <label htmlFor="festivalId"> Przypisz do festiwalu </label>
                <select
                    id="festivalId"
                    name="festivalId"
                    onChange={formik.handleChange}
                    value={formik.values.festivalId}>
                    <option value="">Wybierz Festival</option>
                    {festivals &&
                        festivals.map((festival) => {
                            return (
                                <option value={festival.id}>{festival.title}</option>
                            )
                        })
                    }
                </select>

                    </FormUnit>


                <FormUnit>
                <label htmlFor="date">Data:</label>
                <input
                    id="date"
                    name="date"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.date}
                />
                <label htmlFor="timeSlots">Time slots</label>
                <p>Podaj przedziały czasowe po przecinku (16:00-17:00, 17:15-17:45)</p>
                <input
                    id="timeSlots"
                    name="timeSlots"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.timeSlots}
                />
                <button onClick={(e) => {
                    e.preventDefault();
                    setSecondDay(!secondDay)
                }}>Jeszcze jeden dzień?</button>
                <div style={{display: secondDay ? 'block' : 'none'}}>
                    <label htmlFor="secondDate">Data:</label>
                    <input
                        id="secondDate"
                        name="secondDate"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.secondDate}
                    />

                    <label htmlFor="secondTimeSlots">Time slots</label>
                    <p>Podaj przedziały czasowe po przecinku (16:00-17:00, 17:15-17:45)</p>
                    <input
                        id="secondTimeSlots"
                        name="secondTimeSlots"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.secondTimeSlots}
                    />
                </div>

                    <label htmlFor="bookingUrl">Link do rezerwacji</label>
                    <input
                        id="bookingUrl" name="bookingUrl"  type="text"
                        onChange={formik.handleChange} value={formik.values.bookingUrl}
                        style={{
                            backgroundColor: formik.touched.bookingUrl && formik.errors.bookingUrl ? "#FFD8C9" : "",
                            color: formik.touched.bookingUrl && formik.errors.bookingUrl ? "red" : ""
                        }}/>
                    {formik.touched.bookingUrl && formik.errors.bookingUrl ? (
                        <ErrorDiv>{formik.errors.bookingUrl}</ErrorDiv>
                    ) : null}

                    <label htmlFor="bookingUrl">Ilość miejsc per slot</label>
                    <input
                        id="bookingsPerSlot" name="bookingsPerSlot"  type="number"
                        onChange={formik.handleChange} value={formik.values.bookingsPerSlot}
                        style={{
                            backgroundColor: formik.touched.bookingsPerSlot && formik.errors.bookingsPerSlot ? "#FFD8C9" : "",
                            color: formik.touched.bookingsPerSlot&& formik.errors.bookingsPerSlot ? "red" : ""
                        }}/>
                    {formik.touched.bookingsPerSlot && formik.errors.bookingsPerSlot ? (
                        <ErrorDiv>{formik.errors.bookingsPerSlot}</ErrorDiv>
                    ) : null}

                    <label htmlFor="bookingsPerUser">Ile miejsc może zarezerwować użytkownik podczas rezerwacji</label>
                    <input
                        id="bookingsPerUser" name="bookingsPerUser"  type="number"
                        onChange={formik.handleChange} value={formik.values.bookingsPerUser}
                        style={{
                            backgroundColor: formik.touched.bookingsPerUser && formik.errors.bookingsPerUser ? "#FFD8C9" : "",
                            color: formik.touched.bookingsPerUser&& formik.errors.bookingsPerUser? "red" : ""
                        }}/>
                    {formik.touched.bookingsPerUser && formik.errors.bookingsPerUser ? (
                        <ErrorDiv>{formik.errors.bookingsPerUser}</ErrorDiv>
                    ) : null}

                </FormUnit>
                <AddImagesModal/>
                <button type="submit">Dodaj Event</button>
            </form>
        </FormWrap>
    )
};
export default AdminPanelAddEvent

const FormWrap = styled.div`
  width: 65%;
  height: auto;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin: 0 auto;

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  label{
    margin-bottom: 5px;
  }
  input{
    padding: 6px 3px;
    margin-bottom: 12px;
  }
  textarea{
    padding: 6px 3px;
    margin-bottom: 12px;
  }
  select{
    padding: 6px 3px;
    margin-bottom: 12px;
  }

  button{
    width: 200px;
    margin-bottom: 10px;
  }
`
const ErrorDiv = styled.div`
  color: red;
`
const FormUnit = styled.div`
    background-color: #ECEFF1;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

`
