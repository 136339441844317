import styled from "styled-components";
import {BUTTON_STYLE} from "../../types/style";

interface ButtonProps {
    label: string;
    icon?: string;
    onClick: (e?: any) => (void | Promise<void>);
    buttonStyle: BUTTON_STYLE;
    disabled?: boolean
}

const Button = ({label, icon, onClick, buttonStyle, disabled}: ButtonProps) => {
    return (
        <StyledButton onClick={onClick} buttonStyle={buttonStyle} disabled={disabled}>
            {icon && <img src={icon}/>}
          <p>{label}</p>
        </StyledButton>
    )
}

export default Button

const StyledButton = styled.button<{ buttonStyle: BUTTON_STYLE }>`
  display: flex;
  flex-direction: row;
  padding: ${props => {
    switch (props.buttonStyle) {
      case BUTTON_STYLE.SECONDARY:
        return '2px 8px';
      case BUTTON_STYLE.SECONDARY_NO_PADDING:
        return '2px';
      case BUTTON_STYLE.FILTERS:
        return '16px 20px';
      case BUTTON_STYLE.FILTERS_ACTIVE:
        return '16px 20px';
      default:
        return '12px 20px'
    }
  }};
  
  justify-content: center;
  align-items: center;  
  gap: 10px;
  border-radius:
      ${props => {
        switch (props.buttonStyle) {
          case BUTTON_STYLE.SECONDARY:
            return '0';
          case BUTTON_STYLE.SECONDARY_NO_PADDING:
            return '0';
          default:
            return '60px'
        }
      }};
  width: fit-content;
  
  border-bottom: ${props => {
    switch (props.buttonStyle) {
      case BUTTON_STYLE.SECONDARY:
      case BUTTON_STYLE.SECONDARY_NO_PADDING:
        return '2px solid #3E1DAA';
      case BUTTON_STYLE.FILTERS_ACTIVE:
      case BUTTON_STYLE.FILTERS:
        return '2px solid #FFA4CF';
      default:
        return 'none';
    }
  }};
  border-top: ${props => {
    switch (props.buttonStyle) {
      case BUTTON_STYLE.FILTERS_ACTIVE:
      case BUTTON_STYLE.FILTERS:
        return '2px solid #FFA4CF';
      default:
        return 'none';
    }
  }};
  border-left: ${props => {
    switch (props.buttonStyle) {
      case BUTTON_STYLE.FILTERS_ACTIVE:
      case BUTTON_STYLE.FILTERS:
        return '2px solid #FFA4CF';
      default:
        return 'none';
    }
  }};
  border-right: ${props => {
    switch (props.buttonStyle) {
      case BUTTON_STYLE.FILTERS_ACTIVE:
      case BUTTON_STYLE.FILTERS:
        return '2px solid #FFA4CF';
      default:
        return 'none';
    }
  }};
  background: ${props => {
    if(props.disabled) {
      return '#E5E4E7';
    } else {
    switch (props.buttonStyle) {
      case BUTTON_STYLE.PINK:
        return '#FF59A9';
      case BUTTON_STYLE.GREEN:
        return '#E2FD5F';
      case BUTTON_STYLE.FILTERS_ACTIVE:
        return '#FFA4CF';
      default:
        return 'transparent';
    }
    }
  }};
  box-shadow: ${props => {
    switch (props.buttonStyle) {
      case BUTTON_STYLE.PINK:
        return '8px 8px 0px 0px #1F0812';
      case BUTTON_STYLE.GREEN:
        return '8px 8px 0px 0px #1B0466';
      default:
        return 'none'
    }
  }};
  
  color: ${props => (props.buttonStyle === BUTTON_STYLE.FILTERS_ACTIVE || BUTTON_STYLE.FILTERS) ? '#1B0466' : '#1F0812'};

  font-size: ${props => {
    switch (props.buttonStyle) {
      case BUTTON_STYLE.FILTERS_ACTIVE:
      case BUTTON_STYLE.FILTERS:
        return '16px';
      case BUTTON_STYLE.SECONDARY:
        return '24px';
      default:
        return '1.5rem';
    }
  }};


  text-transform: ${props => (props.buttonStyle === BUTTON_STYLE.FILTERS_ACTIVE || props.buttonStyle === BUTTON_STYLE.FILTERS) ? '700' : '400'};
  text-transform: ${props => (props.buttonStyle === BUTTON_STYLE.FILTERS_ACTIVE || props.buttonStyle === BUTTON_STYLE.FILTERS) ? 'uppercase' : ''};
  font-family: Arial;
  font-style: normal;
  line-height: 110%;
  letter-spacing: 0.24px;
  
  img{
    height: 20px;
    width: 20px;
  }
  p{
    margin: 0;
  }


  :hover {
    background-color: ${props => {
      switch (props.buttonStyle) {
        case BUTTON_STYLE.PINK:
          return '#CC3A80';
        case BUTTON_STYLE.GREEN:
          return '#B2CA3F';
      }
    }};

    opacity: ${props => {
      switch (props.buttonStyle) {
        case BUTTON_STYLE.PINK:
          return 1;
        case BUTTON_STYLE.GREEN:
          return 1;
        default:
          return 0.7;
      }
    }};

    p {
      color: ${props => (props.buttonStyle === BUTTON_STYLE.SECONDARY
          || props.buttonStyle === BUTTON_STYLE.SECONDARY_NO_PADDING) ? '#3E1DAA' : 'black'};
    }
  }
  
`

