import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ImageUrlsState {
    value: string[] | null;
}

const initialState: ImageUrlsState = {
    value: null
};

export const selectedImageUrlsSlice = createSlice({
    name: 'selectedImageUrls',
    initialState,
    reducers: {
        setSelectedImageUrls: (state, action: PayloadAction<string[]>) => {
            state.value = action.payload;
        },
    },
});

export const {setSelectedImageUrls} = selectedImageUrlsSlice.actions;
export default selectedImageUrlsSlice.reducer;

