import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IS_VISIBLE} from "../../types/constants";

interface IsVisibleState {
    value: IS_VISIBLE
}

const initialState: IsVisibleState = {
    value: IS_VISIBLE.BOTH,
};

export const isVisibleSlice = createSlice({
    name: 'isVisible',
    initialState,
    reducers: {
        setIsVisible: (state, action: PayloadAction<IS_VISIBLE>) => {
            state.value = action.payload;
        },
    },
});

export const {setIsVisible} = isVisibleSlice.actions;
export default isVisibleSlice.reducer;
