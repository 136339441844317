import {db} from "../config";
import {doc, setDoc,} from "@firebase/firestore";

const updateIsFestivalActive = async (
    id: string | undefined,
    isCurrent?: boolean,
) => {
    try {
        if (id === undefined) {
            return;
        }
        const docRef = doc(db, 'festivals', id)
        await setDoc(docRef, {isCurrent: !isCurrent, id: id}, {merge: true});

    } catch(err){
        alert('Wystąpił błąd!')
        console.log(err);
    }
}

export default updateIsFestivalActive;
