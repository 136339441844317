import {INITIAL_POSITION} from "../../Constants/mapConstants.d";
import {useEffect, useState} from "react";


const useLocation = (): [number, number] => {
        const [location, setLocation] = useState<[number, number]>(INITIAL_POSITION);
    useEffect(() => {
        try {
            navigator.geolocation.getCurrentPosition((geo) =>
                setLocation([geo.coords.latitude, geo.coords.longitude])
            );
        } catch {
            setLocation(INITIAL_POSITION);
        }
    },[]);
    return location
};

export default useLocation;

