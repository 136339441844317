import styled from "styled-components";
import {useEffect, useState} from "react";

function Scroll() {

    const [prevScrollY, setPrevScrollY] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState("#1F0812"); // Default background color
    const [hideScroll, setHideScroll] = useState(false);

    useEffect(() => {
        const handleBackgroundColor = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const section = entry.target;
                    const computedStyle = getComputedStyle(section);
                    const computedBackgroundColor = computedStyle.backgroundColor;
                    let newBackgroundColor;

                    if (computedBackgroundColor === "rgb(62, 29, 170)") {
                        newBackgroundColor = "#FBFDFF";
                    } else if (computedBackgroundColor === "rgba(0, 0, 0, 0)") {
                        newBackgroundColor = "#1F0812";
                    } else {
                        newBackgroundColor = "#FFFFFF";
                    }
                    setBackgroundColor(newBackgroundColor);
                }
            });
        };

        const handleFooterVisibility = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setHideScroll(true);
                } else {
                    setHideScroll(false);
                }
            });
        };

        const sectionObserver = new IntersectionObserver(handleBackgroundColor, {
            threshold: 0.2,
        });

        const footerObserver = new IntersectionObserver(handleFooterVisibility, {
            threshold: 0.2,
        });

        const sections = document.querySelectorAll("section")
        const footer = document.querySelector("footer");

        sections.forEach(section => {
            sectionObserver.observe(section);
        });

        footer && footerObserver.observe(footer);

        return () => {
            sections.forEach(section => {
                sectionObserver.unobserve(section);
            });
            footer && footerObserver.unobserve(footer);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            setPrevScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollY]);

    return (
        <ScrollDiv hideScroll={hideScroll}>
            <p style={{color: backgroundColor}}>Scroll</p>
            <div style={{backgroundColor: backgroundColor}}/>
        </ScrollDiv>

    );
}

export default Scroll;

const ScrollDiv = styled.div<{ hideScroll: boolean }>`
    display: flex;
    opacity ${props => (props.hideScroll) ? '0' : '1'};
    flex-direction: column;
    align-items: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.16px;
    color: #1F0812;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    div {
        content: '';
        width: 1px;
        height: 70px;
        background: #1F0812;
        top: 40px;
    }
`
