import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface DeleteFestivalModalState {
    value: string
}

const initialState: DeleteFestivalModalState = {
    value: '',
};

export const deleteFestivalModalSlice = createSlice({
    name: 'deleteFestivalModal',
    initialState,
    reducers: {
        setDeleteFestivalModal: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
    },
});

export const {setDeleteFestivalModal} = deleteFestivalModalSlice.actions;
export default deleteFestivalModalSlice.reducer;

