import {BookingData} from "../types/events";
import {doc, setDoc} from "@firebase/firestore";
import {db} from "../config";

const deleteBookingById = async (
    id: string,
    bookings: BookingData[],
    indexToRemove: number
) => {
    try {

        if (id === undefined) {
            return;
        }
        const docRef = doc(db, 'events', id)

        const newBookings = [
            ...bookings.slice(0, indexToRemove),
            ...bookings.slice(indexToRemove + 1)
        ];

        await setDoc(docRef, {
            bookings: newBookings,
            id: id
        }, {merge: true});

    } catch (err) {
        alert('Wystąpił błąd!')
        console.log(err);
    }
}

export default deleteBookingById
