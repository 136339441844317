import styled from "styled-components";
import {useState} from "react";
import ARROW from '../../Assets/Autumn2023/Arrow.svg'
import ARROW_DISABLED from '../../Assets/Autumn2023/ArrowDisabled.svg'

interface HoverableButtonProps {
    left: boolean;
    disabled: boolean;
    onClick: () => void;
}

const PaginationButton = ({disabled, left = true, onClick}: HoverableButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <StyledHoverableButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isHovered={isHovered}
            disabled={disabled}
        >
            <button disabled={disabled} onClick={() => onClick()}>
                {!disabled && <img src={ARROW} style={{transform: left ? 'rotate(90deg)' : 'rotate(-90deg)'}}/>}
                {disabled && <img src={ARROW_DISABLED} style={{transform: left ? 'rotate(90deg)' : 'rotate(-90deg)'}}/>}

            </button>
        </StyledHoverableButton>
    );
};

export default PaginationButton;


const StyledHoverableButton = styled.div<{
    onMouseEnter: () => void,
    onMouseLeave: () => void
    isHovered: boolean
    disabled: boolean
}>`

  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 56px;
  height: 56px;
  justify-content: center;
  border: none;
  border-radius: 360px;
  margin: 5px;
  background: ${props => props.disabled ? '#E5E4E7' : '#fff'};
  z-index: 2;

  img {
    width: 16px;
    height: 16px;
    margin: 0;
    left: 0;
  }
  
  
    button {
      position: absolute;
      display: flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 360px;
      margin: 5px;
      background: ${props => props.disabled ? '#E5E4E7' : '#fff'};
  }

  ${props => !props.disabled && `
          ::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  z-index: -1;
  margin: -2px;
  border-radius: 360px;
  background-image: linear-gradient(to right,
  rgba(175, 142, 245, 1),
  rgba(228, 119, 241, 1),
  rgba(241, 145, 84, 1),
  rgba(245, 193, 25, 1));
  }
   `}
  
`
