import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NewFestivalData} from "../../Models/festival.model";
import {ArticleDataDisplay} from "../../Models/blog.model";

interface BlogState {
    value: ArticleDataDisplay[] | null;
}

const initialState: BlogState = {
    value: null
};

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setBlog: (state, action: PayloadAction<ArticleDataDisplay[]>) => {
            state.value = action.payload;
        },
    },
});

export const {setBlog} = blogSlice.actions;
export default blogSlice.reducer;

