import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface SearchQueryState {
    value: string
}

const initialState: SearchQueryState = {
    value: '',
};

export const searchQuerySlice = createSlice({
    name: 'searchQuery',
    initialState,
    reducers: {
        setSearchQuery: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
    },
});

export const {setSearchQuery} = searchQuerySlice.actions;
export default searchQuerySlice.reducer;
