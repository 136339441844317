import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ActiveArticleState {
    value: string | undefined
}

const initialState: ActiveArticleState = {
    value: undefined
};

export const activeArticleSlice = createSlice({
    name: 'activeArticle',
    initialState,
    reducers: {
        setActiveArticle: (state, action: PayloadAction<string | undefined>) => {
            state.value = action.payload;
        },
    },
});

export const {setActiveArticle} = activeArticleSlice.actions;
export default activeArticleSlice.reducer;

