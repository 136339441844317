export const disclosures = [
    {
        question: "Kiedy odbywa się festiwal Open House?",
        answer: "Festiwal odbywa się raz w roku, na jesieni. O dacie kolejnej edycji informujemy pod koniec poprzedzającego go roku."
    },
    {
        question: "Czy mogę zgłosić moje mieszkanie, studio, pracownię do programu festiwalu?",
        answer: "Tak! Jesteśmy otwarci na współpracę z mieszkańcami Gdańska. Napisz do nas maila na: info@openhousefestival.pl. Nie otwieramy wyłącznie wnętrz perfekcyjnie zaprojektowanych. Zależy nam na przedstawianiu mieszkańców miasta, dzieleniu się z odbiorcami ciekawymi historiami, otwartości, która wiąże się często z zapraszaniem nieznajomych do wnętrz prywatnych. Te działania wiążą się prawie zawsze z koniecznością dokonania wcześniejszej rezerwacji przez osoby zainteresowane zwiedzaniem. Nad porządkiem zwiedzania i bezpieczeństwem czuwają przeszkoleni wolontariusze. Odwiedziny w prywatnych wnętrzach, to zawsze bardzo miłe spotkania, a często nowe znajomości i kontakty."
    },
    {
        question: "Czy mogę zgłosić się do wolontariatu Open House Gdańsk?",
        answer: "Jasne! Nabór do wolontariatu nie ogranicza się do tygodni przed festiwalem. Działamy cały rok! Napisz do nas na: wolontariat@openhousefestival.pl\n" +
            "Koordynatorka wolontariatu Alicja, pomoże ci na każdym kroku. Przede wszystkim KAŻDY nadaje się do pracy przy tworzeniu festiwalu. Poszukujemy osób w dowolnym wieku, zainteresowaniach, wykształceniu."
    },
    {
        question: "Czy festiwal Open House Gdańsk jest imprezą darmową?",
        answer: "Tak! Uczestnictwo w festiwalu jest darmowe."
    },
    {
        question: "Czy na wydarzenia festiwalowe obowiązują zapisy?",
        answer: "Nie na wszystkie, ale na niektóre tak, np. warsztaty, zwiedzanie mieszkań i pracowni. Staramy się dbać zarówno o komfort uczestników, jak i osób otwierających swoje przestrzenie, stąd ograniczona liczba miejsc. Jeżeli nie dasz rady pojawić się w miejscu, do którego jesteś zapisan_, prosimy o wcześniejszą informację (mailowo czy poprzez FB/Instagram) - da nam to możliwość zaoferowania miejsca osobie z listy rezerwowej. \n" +
            "Na pozostałe wydarzenia wystarczy pojawić się w danej lokalizacji w odpowiednim czasie, wg programu."
    },
    {
        question: "Czy wydarzenia w ramach festiwalu odbywają się tylko stacjonarnie?",
        answer: "Tak! Główną ideą festiwalu są spotkania i kontakt z mieszkańcami, czego nie da się zrobić online."
    },
    {
        question: "Gdzie mogę szukać informacji o festiwalu?",
        answer: "Podstawowym źródłem informacji o danej edycji festiwalu jest strona internetowa, na której się znajdujesz. Warto śledzić również nasze social media: Facebook i Instagram, gdzie odsłaniamy kulisy festiwalu i informujemy o nowościach w programie. Podczas samego wydarzenia działa punkt info, gdzie zdobędziesz wszystkie potrzebne informacje, pobierzesz program i mapę festiwalu."
    },
    {
        question: "Jeśli chcę wesprzeć festiwal finansowo jako osoba prywatna lub firma?",
        answer: "Ponieważ festiwal i wszystkie nasze działania są darmowe, chętnie podejmiemy współpracę z osobami i firmami, które wspierają lub chcą wspierać kulturę. Jesteśmy organizacją non profit działającą na terenie Trójmiasta. Od 2015 roku organizujemy festiwal Open House Gdynia, który był pierwszym festiwalem architektury o takiej formule w Polsce. W 2017 roku odbyła się pierwsza edycja festiwalu Open House Gdańsk. Od tego czasu poszerzamy naszą działalność edukacyjną w zakresie architektury, urbanistyki i szeroko pojętej wiedzy o mieście. Open House jest marką międzynarodową, zrzeszającą 60 miast na całym świecie. Na co dzień współpracujemy z innymi miastami członkowskimi dzieląc się doświadczeniami, organizując wspólne wydarzenia takie, jak wymiany wolontariuszy, konferencje, zwiedzanie wirtualne. Obecnie zarówno Open House Gdynia, jak i Open House Gdańsk, to największe festiwale architektury w Polsce zrzeszające wielotysięczną publiczność w ciągu roku – zarówno podczas festiwalowych weekendów na żywo, jak również w sieci." +
            "Napisz do nas, chętnie odpowiemy na każde pytanie: info@openhousefestival.pl" +
            "Fundacja Pura, Wzgórze Bernadowo 272, 81-583 Gdynia, nr KRS: 0000586232"
    },
];
