import styled from "styled-components";
import React, {useEffect, useState} from "react";
import MM from "../../Assets/GdyniaTeam/MM.png"
import DL from "../../Assets/GdyniaTeam/DL.png"
import MC from "../../Assets/GdyniaTeam/MC.png"
import MS from "../../Assets/GdyniaTeam/MS.png"
import AO from "../../Assets/GdyniaTeam/AO.png"
import AB from "../../Assets/GdyniaTeam/AB.png"
import GROUP from "../../Assets/GdyniaTeam/GROUP.png"
import VolonteeringSection from "../Home/Component/VolounteeringSection";
import {useAppSelector} from "../../Store/hooks";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";


export function GdyniaTeam() {

    const isMobile = useAppSelector(isMobileSelector);
    const team = [
        {
            name: 'Michał Miegoń',
            description: 'Na co dzień chodzi innymi szlakami – można go spotkać przy bocznych drogach i uliczkach. Michał is Open for House – odpowiedzialny w programie za miejsca znane i te, o których nie wiedziałyście/wiedzieliście że istnieją, a istnieją i można do nich dotrzeć.',
            img: MM
        },
        {
            name: 'Diana Lenart',
            description: 'Założycielka i girlboss festiwalu Open House Gdynia i Open House Gdańsk. Poetka, niezależna kuratorka, redaktorka.',
            img: DL
        },
        {
            name: 'Maria Chojnacka',
            description: 'Koordynatorka wolontariatu Open House Gdynia. Żywi się w Barze Mlecznym Słonecznym.',
            img: MC
        },
        {
            name: 'Melania Szymerowska',
            description: 'Producentka festiwalu Open House Gdynia i Open House Gdańsk. Od 20 lat produkuje najważniejsze wydarzenia kulturalne w Trójmieście. Zawodowo związana z Gdańskim Teatrem Szekspirowskim. Producentka festiwalu Open House Gdynia i Open House Gdańsk. Od 20 lat produkuje najważniejsze wydarzenia kulturalne w Trójmieście. Zawodowo związana z Gdańskim Teatrem Szekspirowskim.',
            img: MS
        },
        {
            name: 'Ania Odrzygóźdź ',
            description: 'Koordynatorka działań artystycznych i wydarzeń towarzyszących. Współtworzy program festiwalowy, czuwa nad treścią i aspektem wizualnym.',
            img: AO
        },
        {
            name: 'Arek Brzęczek ',
            description: 'Pisarz, publicysta, przewodnik, ale przede wszystkim opowiadacz gdyńskich historii. W zespole Open House Gdynia od samego początku.',
            img: AB
        },
    ]

    return (
        <ArchiveWrapper isMobile={isMobile}>
            <Title isMobile={isMobile}>Poznaj Zespół</Title>
            <Description isMobile={isMobile}>Od kilku lat pracujemy nad festiwalem z nadzieją, że zakochacie się w
                każdej z kolejnych dzielnic, tak jak zakochujemy się w nich my. Poznajmy się!</Description>
            <ProfilesSection isMobile={isMobile}>
                {team.map((entry) =>
                    (<ProfileCard isMobile={isMobile}>
                        <img src={entry.img}/>
                        <h3>{entry.name}</h3>
                        <p>{entry.description}</p>
                    </ProfileCard>))}
            </ProfilesSection>
            <LoveSection isMobile={isMobile}>
                <Testimonials isMobile={isMobile}>
                    <Title isMobile={isMobile}>Za co kochamy Gdynię?</Title>
                    <Description isMobile={isMobile}>Dlaczego Gdynia? Ponieważ żyjemy gdyńskimi legendami miejskimi: krokodylami, UFO, nawiedzonymi domami, a przede wszystkim gdyńską architekturą – od pereł modernizmu przedwojennego, przez postmodernistyczne dziwolągi, po to, co jeszcze nie powstało.</Description>
                    <Description isMobile={isMobile}>Tworzymy tu święto wielkiej celebracji wszystkiego, co gdyńskie: dla wszystkich bez wyjątku, bezpłatnie.</Description>
                    <Description isMobile={isMobile}>Dlaczego architektura? Bo jest doskonałym pretekstem do spotkań, mieści w sobie mnóstwo pojęć i tematów. I jest naszym domem.</Description>

                </Testimonials>
                <img src={GROUP}/>
            </LoveSection>
            <VolonteeringSection isMobile={isMobile}/>
        </ArchiveWrapper>
    );
}

const Testimonials = styled.div<{isMobile: boolean}>`
    width: ${({isMobile}) => (isMobile ? '80vw' : '42vw')};
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const LoveSection = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    justify-content: space-evenly;
    padding: 80px 40px 88px 40px;
    gap: 24px;
    background: #FFA4CF;
    width: 100vw;
    height: ${({isMobile}) => (isMobile ? '170vw' : '505px')};


    img {
        width: ${({isMobile}) => (isMobile ? '80vw;' : '600px')};
        height: ${({isMobile}) => (isMobile ? '50vw' : '400px')};
    }

    h1 {
        padding: 0;
        margin: 0;
    }

    p {
        margin: 0;
        padding: 0;
    }
`


const ProfilesSection = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${({isMobile}) => (isMobile ? 'center' : '')};
    width: 100%;
    gap: 31px;
    padding-left: ${({isMobile}) => (isMobile ? '0' : '40px')};
    margin: 60px 0; 
`

const ProfileCard = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    height: 510px;
    gap: 12px;
    width: ${({isMobile}) => (isMobile ? '90%' : '22vw')};

    img {
        width: 100%;
    }

    h3 {
        color: #1F0812;
        font-family: Arial;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%
    }

    p {
        color: #1F0812;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.16px;
    }
`


const ArchiveWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    padding: ${({isMobile}) => (isMobile ? '80px 0 0 0' : '150px 0 50px 0')};
`;

const Title = styled.h1<{ isMobile: boolean }>`
    color: #1F0812;
    font-family: Cabinet Grotesk;
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    padding: 0;
    padding-left: ${({isMobile}) => (isMobile ? '12px' : '44px')};
    margin: 0;
`

const Description = styled.p<{ isMobile: boolean }>`
    color: #514D58;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.16px;
    width: ${({isMobile}) => (isMobile ? '80vw' : '100%')};
    padding-left: ${({isMobile}) => (isMobile ? '12px' : '40px')};
    margin: 0;
`

