import {geocodingOptions} from "../Constants/geocodingConstants";

export const addressGeocoding = async (value: string) => {
    const encodedValue = encodeURIComponent(`${value}`);
    const url = `https://trueway-geocoding.p.rapidapi.com/Geocode?address=${encodedValue}&language=en`;
    try{
        const response = await fetch(url, geocodingOptions)
        const position = await response.json();
        const lat = position.results[0].location.lat ? position.results[0].location.lat : 50
        const lng = position.results[0].location.lng ? position.results[0].location.lng : 50
        return [lat, lng] ;
    } catch(error){
        console.log('Something went wrong', error)
    }

}
