import styled from "styled-components";
import React, {useEffect, useState} from "react";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";
import {GrClose, GrFormPreviousLink, GrFormNextLink} from "react-icons/gr";

import mg from "../../Assets/magnifying-glass.svg";

export function ArchivePage() {
    const [isMobile, setIsMobile] = useState(false);
    const [selectedCity, setSelectedCity] = useState('Gdynia')

    useEffect(() => {
        if (window.outerWidth < 1100) {
            setIsMobile(true);
        }
    }, []);

    const [years, setYears] = useState([]); // Years for the selected location
    const [selectedYear, setSelectedYear] = useState(''); // Selected year
    const [filteredFestivals, setFilteredFestivals] = useState([]);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0); // Index of the selected photo in the modal
// Add a new state to manage the modal visibility
    const [isModalOpen, setIsModalOpen] = useState(false);

// Add a new state to manage the selected photo for the modal
//     const [selectedPhoto, setSelectedPhoto] = useState('');


    useEffect(() => {
        // Get unique years for the selected location
        const uniqueYears = [...new Set(mockFestivals.filter(festival => festival.location === selectedCity).map(festival => new Date(festival.startDate).getFullYear()))];
        // @ts-ignore
        setYears(uniqueYears.sort()); // Sort the years
    }, [selectedCity]);


    useEffect(() => {
        // Filter festivals based on location and selected year
        const festivalsForLocation = mockFestivals.filter(festival => festival.location === selectedCity);

        // If a selected year exists, further filter festivals by year
        const festivalsForYear = selectedYear
            //@ts-ignore
            ? festivalsForLocation.filter(festival => new Date(festival.startDate).getFullYear() === selectedYear)
            : festivalsForLocation;
        // Set the filtered festivals and select the first year in the list
        // @ts-ignore
        setFilteredFestivals(festivalsForYear);
    }, [selectedCity, selectedYear, years]);

    useEffect(() => {
        setSelectedYear(years[0]);
    }, [selectedCity, years]);


    // @ts-ignore
    const imagecount = (filteredFestivals.map((festival, festivalIndex) => (festival.photos?.length)));

    const openModal = (photoIndex) => {
        setSelectedPhotoIndex(photoIndex);
        setIsModalOpen(true);
    };

    const goToPreviousPhoto = () => {
        setSelectedPhotoIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const goToNextPhoto = () => {
        setSelectedPhotoIndex((prevIndex) =>
            // @ts-ignore
            prevIndex < imagecount - 1 ? prevIndex + 1 : prevIndex
        );
    };

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowLeft':
                goToPreviousPhoto();
                break;
            case 'ArrowRight':
                goToNextPhoto();
                break;
            case 'Escape':
                setIsModalOpen(false);
                break;
            default:
                break;
        }
    };

    // Listen for keyboard events
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedPhotoIndex, isModalOpen]);






    return (
        <ArchiveWrapper isMobile={isMobile}>
            <Title isMobile={isMobile}>Archiwum</Title>
            <CitySelect isMobile={isMobile}>
                <Button
                    label={'Gdynia'}
                    onClick={() => setSelectedCity('Gdynia')}
                    buttonStyle={BUTTON_STYLE.SECONDARY_NO_PADDING}/>
                <Button
                    label={'Gdańsk'}
                    onClick={() => setSelectedCity('Gdańsk')}
                    buttonStyle={BUTTON_STYLE.SECONDARY_NO_PADDING}/>
            </CitySelect>
            <YearSelect isMobile={isMobile}>
                {years.length > 0 && years.map((year) => (
                    <Button
                        key={year}
                        label={year}
                        buttonStyle={selectedYear === year
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        onClick={() => setSelectedYear(year)}
                    />
                ))}
            </YearSelect>

            <Grid isMobile={isMobile}>
                {filteredFestivals.map((festival, festivalIndex) => (
                    // @ts-ignore
                    festival.photos?.map((photo, photoIndex) => (
                        // @ts-ignore
                        <GridItem isMobile={isMobile}
                            key={`${festivalIndex}-${photoIndex}`}
                            onClick={() => openModal(photoIndex)}
                        >
                            <PhotoOverlay className="overlay">
                                <MagnifyingGlass src={mg} alt="Magnifying Glass"/>
                            </PhotoOverlay>
                            <Photo src={photo} alt={`Festival - Photo ${photoIndex + 1}`} isMobile={isMobile}/>
                        </GridItem>
                    ))
                ))}
            </Grid>

            {isModalOpen && (
                <Overlay>
                    <ModalWrapper>
                        <CloseIcon onClick={() => setIsModalOpen(false)} isMobile={isMobile}>
                            <GrClose  color="white"/>
                        </CloseIcon>
                        <NavigationIcon onClick={goToPreviousPhoto} left={true}>
                            <GrFormPreviousLink/>
                        </NavigationIcon>
                        <ModalContent>
                            {/*@ts-ignore*/}
                            <CurrentPhoto src={filteredFestivals[0].photos[selectedPhotoIndex]}
                                          alt="Selected Festival"/>
                            <PhotoCounter>{`${selectedPhotoIndex + 1} of ${imagecount}`}</PhotoCounter>
                        </ModalContent>
                        <NavigationIcon onClick={goToNextPhoto} left={false}>
                            <GrFormNextLink/>
                        </NavigationIcon>
                    </ModalWrapper>
                </Overlay>
            )}
        </ArchiveWrapper>
    );
}


const ArchiveWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  padding: ${({isMobile}) => (isMobile ? '0' : '150px 0 50px 0')};

  p {
    color: #1F0812;
    text-align: center;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    letter-spacing: 0.24px;
    text-transform: none;
  }
`;

const Title = styled.h1<{ isMobile: boolean }>`
  color: #1F0812;
  font-family: Cabinet Grotesk;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding-left: ${({isMobile}) => (isMobile ? '12px' : '44px')};
`

const CitySelect = styled.div<{ isMobile: boolean }>`
  margin-top: 44px;
  display: flex;
  flex-direction: row;
  padding-left: ${({isMobile}) => (isMobile ? '12px' : '48px')};
  gap: 24px
`

const YearSelect = styled.div<{ isMobile: boolean }>`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: ${({isMobile}) => (isMobile ? 'wrap' : 'no-wrap')};
  padding-left: ${({isMobile}) => (isMobile ? '12px' : '48px')};
  gap: ${({isMobile}) => (isMobile ? '12px' : '24px')};

  button{
    font-size: 8px;
    padding: 6px 8px;
  }
`
const GridItem = styled.div<{ isMobile: boolean }>`
  position: relative;
  width: ${({isMobile}) => (isMobile ? '50vw' : 'calc(16.6667% - 16px)')};
  cursor: pointer;
  transition: filter 0.3s ease;


  &:hover .overlay {
    opacity: 1;
  }
`;

const PhotoOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.64);
`;

const MagnifyingGlass = styled.img`
  width: 35px; // Adjust the size of the magnifying glass
  height: 35px; // Adjust the size of the magnifying glass
  filter: brightness(100%) !important; // Ensure the icon remains white
`;

const Photo = styled.img<{isMobile: boolean}>`
  width: ${({isMobile}) => (isMobile ? '50vw' : ' 16.6667vw')};
  height: ${({isMobile}) => (isMobile ? '50vw' : ' 16.6667vw')};
  object-fit: cover;
`;

const Grid = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({isMobile}) => (isMobile ? '0' : ' 16px')};
  margin-top: 24px;
`;


const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  height: 75vh;
  width: 600px;
`;


const PhotoCounter = styled.div`
  margin-top: 16px;
  color: #fff;
  font-size: 16px;
  position: absolute;
  bottom: 16px; // Adjust the distance from the bottom
  right: 40px; // Adjust the distance from the right
`;


const CurrentPhoto = styled.img`
  height: 75vh; /* Adjust the height as needed */
  width: auto; /* Allow width to adjust proportionally */
  object-fit: contain;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
  z-index: 999; /* Ensure it's above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's above the overlay */
`;

const CloseIcon = styled.div<{ isMobile: boolean }>`
  position: ${({isMobile}) => (isMobile ? 'relative' : 'absolute')};
  top: 16px;
  right: ${({isMobile}) => (isMobile ? '130px' : '16px')};
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  position: absolute;
  z-index: 1001; /* Ensure it's above the modal content */
  filter: brightness(50%);

  &:hover {
    filter: brightness(100%);
  }
`;

const NavigationIcon = styled.div<{ left: boolean }>`
  cursor: pointer;
  font-size: 36px;
  color: #fff;
  margin-top: 24px;
  position: absolute;
  left: ${props => (props.left) ? '-100px' : '700px'};
  top: 50%;
`;
