import {collection, doc, getDoc, getDocs, query} from "@firebase/firestore";
import {db} from "../config";
import {setEvents} from "../Store/slices/eventsSlice";

export const fetchEventsState = async(dispatchFunction:any, activeFestivalId: string)=> {
    let events: any = []
    const q = query(collection(db, "events"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        if(doc.data().festivalId === activeFestivalId) {
            events.push({
                ...doc.data(),
                id: doc.id
            });
        }
    })

    dispatchFunction(setEvents(events));
}

export const fetchAllEventsState = async(dispatchFunction:any)=> {
    let events: any = []
    const q = query(collection(db, "events"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
            events.push({
                ...doc.data(),
                id: doc.id
            });
    })
    dispatchFunction(setEvents(events));
}

export const fetchEvents = async (setEvents, activeFestival) => {
    const eventsData: any = [];
    const q = query(collection(db, 'events'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        if (doc.data().festivalId === activeFestival.id) {
            eventsData.push({
                ...doc.data(),
                id: doc.id,
            });
        }
    });
    setEvents(eventsData);
};

export const fetchEventById = async (eventId) => {
    try {
        const eventDocRef = doc(db, "events", eventId);
        const eventDocSnapshot = await getDoc(eventDocRef);

        if (eventDocSnapshot.exists()) {
            const eventData = eventDocSnapshot.data();
            return { ...eventData, id: eventDocSnapshot.id }; // Return the event data with the event ID
        } else {
            console.error(`Event with ID ${eventId} not found.`);
            return null;
        }
    } catch (error) {
        console.error("Error fetching event by ID:", error);
        return null;
    }
};
