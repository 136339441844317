import styled from "styled-components";
import {NAVBAR_SECTION_STYLE} from "../../../types/style";
import {ReactNode} from "react";


interface NavbarSectionProps {
    navbarSectionStyle?: NAVBAR_SECTION_STYLE,
    children: ReactNode;
    isMobile: boolean
    shift?: boolean
}

const NavbarDropdown = ({navbarSectionStyle, children, isMobile, shift}: NavbarSectionProps) => {
    return(
        <StyledNavbarSection navbarSectionStyle={navbarSectionStyle} isMobile={isMobile} shift={shift}>
            {children}
        </StyledNavbarSection>
    )
}

export default NavbarDropdown

const StyledNavbarSection = styled.div<{
    navbarSectionStyle?: NAVBAR_SECTION_STYLE
    isMobile: boolean
    shift?: boolean
}>`
  display: flex;
  flex-direction: ${props => (props.isMobile) ? "column" : "row"};
  align-items: ${props => (props.isMobile) ? "left" : "center"};
    min-width: 240px;
  gap: ${props => (props.navbarSectionStyle === NAVBAR_SECTION_STYLE.GAP && props.isMobile === false) ? "24" : "0"}px;
  
`
