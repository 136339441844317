import QuoteSection from "./Component/QuoteSection";
import VolonteeringSection from "./Component/VolounteeringSection";
import PlaylistSection from "./Component/PlaylistSection";

import underline from "../../Assets/HomePageAssets/underline.svg"
import gdanskCover from "../../Assets//HomePageAssets/gdansk_cover.webp"
import gdyniaCover from "../../Assets/HomePageAssets/gdynia_cover.webp"

import NewestBlogSection from "./Component/NewestBlogSection";
import {HomePageCard} from "./Component/HomePageCard";
import {theme} from "../../theme";
import {CardDiv, HomeDiv, HomePage, TitleDiv} from "./Home.styles";
import AboutOpenHouse from "./Component/AboutOpenHouse";
import {useAppSelector} from "../../Store/hooks";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";

function Home() {

    const isMobile = useAppSelector(isMobileSelector);

    return (
        <HomePage isMobile={isMobile}>
            <HomeDiv isMobile={isMobile}>
                <TitleDiv isMobile={isMobile}>
                    <h1>Międzynarodowy Festiwal Architektury</h1>
                    <img src={underline} alt={""}/>
                    <h2>Open House Festival</h2>
                </TitleDiv>
                <CardDiv isMobile={isMobile}>
                    <HomePageCard
                        isMobile={isMobile}
                        color={theme.pink}
                        imageUrl={gdyniaCover}
                        linkLabel="Odkryj 10 edycję w Gdyni"
                        linkTo="/gdynia"
                    />
                    <HomePageCard
                        isMobile={isMobile}
                        color={theme.lime}
                        imageUrl={gdanskCover}
                        linkLabel="Odkryj 7 edycję w Gdańsku"
                        linkTo="/gdansk"
                    />
                </CardDiv>
            </HomeDiv>
            <AboutOpenHouse isMobile={isMobile}/>
            <QuoteSection isMobile={isMobile}/>
            <NewestBlogSection isMobile={isMobile}/>
            <VolonteeringSection isMobile={isMobile}/>
            <PlaylistSection isMobile={isMobile}/>
        </HomePage>
    );
}

export default Home;

