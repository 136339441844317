    import {doc, deleteDoc} from "@firebase/firestore";
import {db} from "../config";

const deleteEvent = async(id: string) => {
    try{
        await deleteDoc(doc(db, `events/${id}`));

    } catch (err){
        alert('Wystąpił błąd');
        console.log(err)
    }
};

export default deleteEvent;
