import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IsAdminState {
    value: boolean
}

const initialState: IsAdminState = {
    value: false,
};

export const isAdminSlice = createSlice({
    name: 'isAdmin',
    initialState,
    reducers: {
        setIsAdmin: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
    },
});

export const {setIsAdmin} = isAdminSlice.actions;
export default isAdminSlice.reducer;

