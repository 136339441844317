import styled from "styled-components";

export const ButtonSpan = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: ${({isMobile}) => (isMobile ? 'flex-start' : 'center')};
    margin-top: 12px;
    
    p {
        font-family: "Lato", serif;
        font-size: 1.5rem;
        margin-top: 0 !important;
    }
 

    @media (max-width: 500px) {
        flex-direction: column;
    }
`

export const ContentWrapper = styled.div<{ isMobile: boolean }>`
    width: 100vw;
    background: #FBFDFF;
    display: flex;
    padding: ${({isMobile}) => (isMobile ? '16px' : ' 120px 40px 40px 40px')};
    gap: 115px;

    img {
        width: 40vw;
        max-height: 80vh;

        @media (max-width: 1000px) {
            width: 90%;
            height: auto;
        }
    }

    @media (max-width: 500px) {
        height: auto;
    }
`

export const FormContainer = styled.div`
    position: relative;
    width: 500px;
    top: 20%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
        color: #514D58;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.16px;
        margin-top: 8px;
    }


    form {
        margin-bottom: 20px;
    }

    @media (max-width: 500px) {
        width: 280px;
        top: 3%;
    }
`

export const LoginTitle = styled.div`
    color: #3E1DAA;
    font-family: Cabinet Grotesk;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    padding-bottom: 20px;
`
export const DivButton = styled.div`
    margin-top: 12px;
    color: #3E1DAA;
    font-family: Cabinet Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline;

    :hover {
        font-weight: 800;
    }
`

export const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    margin: 16px 0;
`;

export const CheckboxInput = styled.input`
    margin-right: 8px;
`;

export const CheckboxText = styled.span`
    color: #1F0812;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.16px;
`;

