import React, {useState} from 'react';
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {ArticleDataDisplay} from "../../Models/blog.model";
import {deleteBlogModalSelector} from "../../Store/selectors/deleteBlogModalSelector";
import {setDeleteBlogModal} from "../../Store/slices/deleteBlogModalSlice";
import DeleteBlogModal from "./Messages/DeleteBlogModal";
import EditArticle from "./EditArticleModal";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";

import updateIsArticleVisible from "../../Utilities/updateArticleVisible";
import {fetchAllArticles} from "../../Utilities/fetchBlog";
import CollapsibleBlog from "../../Components/Common/CollapsibleBlog";

type Props = {
    articles: ArticleDataDisplay[] | null;
}

const ManageBlogComponent = (props: Props) => {


    const deleteBlogModal = useAppSelector(deleteBlogModalSelector)
    const dispatch = useAppDispatch();

    const toggler = async (id, visibility) => {
        await updateIsArticleVisible(id, visibility)
        await fetchAllArticles(dispatch);
    }

    const [openEditBlog, setOpenEditBlog] = useState<string | null>(null);

    return (
        <AdminContent>
            <h1>Blog</h1>

            {props.articles?.map((article: ArticleDataDisplay) => {
                return (
                    <div key={Math.random()}>
                        <CollapsibleBlog article={article}>
                            <CollapsibleChildren>
                                <CollapsibleInfo>
                                    <>
                                        <p><span>Autor:</span> {article.author}</p>
                                        <p><span>Data Publikacji:</span> {article.datePublished}</p>
                                        <Tags><span>Tagi:</span><p>{article.tags.map((tag) => <p>{tag}</p>)}</p></Tags>
                                        <p><span>Treść:</span></p>
                                        <p>{article.content}</p>
                                        <p><span>Ilustracje:</span></p>
                                        <ImageDisplay>
                                            {article.images.map((image) =>
                                                (<img src={image.url} alt={'img'}/>)
                                            )}
                                        </ImageDisplay>

                                    </>
                                </CollapsibleInfo>
                            </CollapsibleChildren>
                            <ButtonCollapse>
                                <button onClick={async () => {
                                    await toggler(article.id, article.isVisible)
                                }}>
                                    {article.isVisible === true ? <MdVisibility/> : <MdVisibilityOff/>}
                                </button>

                                <button onClick={(e) => {
                                    setOpenEditBlog(article.id!)
                                }}>Edytuj
                                </button>
                                <button onClick={() => {
                                    dispatch(setDeleteBlogModal(article.id))
                                }}>Usuń
                                </button>
                            </ButtonCollapse>
                        </CollapsibleBlog>

                        {deleteBlogModal === article.id && <DeleteBlogModal id={article.id}/>}
                        {openEditBlog === article.id &&
                            <EditArticle openEditBlog={openEditBlog} setOpenEditBlog={setOpenEditBlog}
                                         article={article}/>}
                    </div>
                )
            })}
        </AdminContent>
    );
};

export default ManageBlogComponent;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`


const ImageDisplay = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    max-height: 180px;
    margin: 10px;
  }
`


const ButtonCollapse = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
`
const AdminContent = styled.div`
  width: 85%;
  height: 100%;
  background: #f5f5f5;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin-left: 20px;

  button {
    border-radius: 20px;
    padding: 8px 12px;
    background-color: #FFC627;
    border: 2px solid #07080D;
    color: black;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      background-color: #F5f5f5;
      border: 2px solid #FFC627;
    }
  }
`
const CollapsibleChildren = styled.div`
  display: flex;
  flex-direction: row`

const CollapsibleInfo = styled.div`
  padding: 20px;

  span {
    font-weight: 600;
  }
`
