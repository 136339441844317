import React from 'react'

const YellowGradient: React.FC = () => {
    return (
        <svg width="0" height="0">
            <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                <stop stopColor="#F2B354" offset="0%"/>
                <stop stopColor="#B75F2E" offset="100%"/>
            </linearGradient>
        </svg>
    )
}
export default YellowGradient
