import React, {useEffect, useRef, useState} from 'react';
import InputField from "../../Components/Common/InputField/InputField";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";
import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import {blogSelector} from "../../Store/selectors/blogSelector";
import {eventsSelector} from "../../Store/selectors/eventsSelector";
import {ArticleDataDisplay} from "../../Models/blog.model";
import {EventData} from "../../Models/event.model";
import {fetchAllEventsState} from "../../Utilities/fetchEvents";
import {setSearchQuery} from "../../Store/slices/searchQuerySlice";
import {searchQuerySelector} from "../../Store/selectors/searchQuerySelector";
import maxLength from "../../Utilities/maxLength";
import eventSearch from "../../Assets/event_search.svg";
import blogSearch from "../../Assets/blogpost_search.svg";
import {useLocation, useNavigate} from "react-router-dom";
import Slider from "../Home/Component/Slider";
import {Breadcrumb, ContentDiv, Display, InputDiv, ResultContent, ResultDiv, Results, Wrapper} from './search.styles';
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";

const SearchBar = () => {
    const location = useLocation();
    const queryParam = new URLSearchParams(location.search).get('query');
    const [query, setQuery] = useState<string | undefined>(queryParam || "");
    const [currentPage, setCurrentPage] = useState(1);
    const isMobile = useAppSelector(isMobileSelector);
    const articles = useAppSelector(blogSelector) as NonNullable<ArticleDataDisplay[]>
    const events = useAppSelector(eventsSelector) as NonNullable<EventData[]>
    const [firstQuery, setFirstQuery] = useState(true);
    const stateQuery = useAppSelector(searchQuerySelector);
    const dispatch = useAppDispatch();
    const searchRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if(queryParam){
            setQuery(queryParam);
        }
    }, []);

    useEffect(() => {
        if (!events) {
            fetchAllEventsState(dispatch);
        }
    }, [articles, events, dispatch]);


    useEffect(() => {
        if (firstQuery && !queryParam) {
            setQuery(stateQuery);
            dispatch(setSearchQuery(''))
        }
    }, [])


    const results = (articles, events, query) => {
        let searchResult: any[] = [];
        articles && articles.filter(post => {
            if (query === '') {
                return
            } else if (post.title.toLowerCase().includes(query.toLowerCase())) {
                searchResult.push(post);
            } else if (post.content.toLowerCase().includes(query.toLowerCase())) {
                searchResult.push(post);
            } else if (post.author.toLowerCase().includes(query.toLowerCase())) {
                searchResult.push(post);
            } else if (post.tags.join(', ').toLowerCase().includes(query.toLowerCase())) {
                searchResult.push(post);
            }
        })

        events && events.filter(post => {
            if (query === '') {
                return;
            } else if (post.title.toLowerCase().includes(query.toLowerCase())) {
                searchResult.push(post);
            } else if (post.description.toLowerCase().includes(query.toLowerCase())) {
                searchResult.push(post);
            } else if (post.address.toLowerCase().includes(query.toLowerCase())) {
                searchResult.push(post);
            }
        })
        return searchResult
    }

    const searchValues = results(articles, events, query);

    const clickHandler = (post) => {
        if (post.content) {
            navigate(`/blog/${post.id}`)
        }
        if (post.address && post.description) {
            if (post.address.includes('Gdynia')) {
                navigate(`/gdynia/events/${post.id}`)
            } else {
                navigate(`/gdansk/events/${post.id}`)
            }
        }
    }

    const onSearchClick = () => {
        const query = searchRef.current?.value;
        setQuery(query);
        setFirstQuery(false);
    }

    const resultsPerPage = 4;
    const startIndex = (currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    const paginatedSearchValues = results(articles, events, query).slice(startIndex, endIndex);
    const totalPages = Math.ceil(searchValues.length / resultsPerPage);

    const renderPaginationButtons = () => {
        const buttons: any[] = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                buttons.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={currentPage === i ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            const startPage = Math.max(1, currentPage - 1);
            const endPage = Math.min(totalPages, startPage + 2);

            if (startPage > 1) {
                buttons.push(<button key={1} onClick={() => setCurrentPage(1)}>{1}</button>);
                if (startPage > 2) {
                    buttons.push(<span key="ellipsis1">...</span>);
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                buttons.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={currentPage === i ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    buttons.push(<span key="ellipsis2">...</span>);
                }
                buttons.push(
                    <button key={totalPages} onClick={() => setCurrentPage(totalPages)}>
                        {totalPages}
                    </button>
                );
            }
        }
        return buttons;
    };

    return (
        <>
            <Wrapper isMobile={isMobile}>
                <Breadcrumb aria-label="Breadcrumb" className="breadcrumb">
                    <ol>
                        <li>
                            <a href="/">
                                Strona Główna
                            </a>
                            <span>/</span>
                        </li>
                        <li>
                            <a href="/search">
                                Wyszukiwanie
                            </a>
                        </li>
                    </ol>
                </Breadcrumb>


                <InputDiv isMobile={isMobile}>
                    <InputField label="Wyszukaj"
                                type="text"
                                placeholder="Co chcesz znaleźć?"
                                isMobile={isMobile}
                                ref={searchRef}/>
                    <Button label="Szukaj" onClick={() => onSearchClick()} buttonStyle={BUTTON_STYLE.GREEN}/>
                </InputDiv>
                <ContentDiv>
                    <Results>
                        {searchValues.length !== 0 ? (<h2>Wyniki wyszukiwania: {queryParam ? queryParam : query}</h2>) : null}
                        {paginatedSearchValues.length !== 0 ? paginatedSearchValues.map((post, index) =>
                                (
                                    <ResultDiv key={index}>
                                        {post.content ? <img src={blogSearch}/> : <img src={eventSearch}/>}
                                        <ResultContent>
                                            <h3>{post.title}</h3>
                                            {post.content
                                                ? <p>{maxLength(post.content, 230)}</p>
                                                : <p>{maxLength(post.description, 230)}</p>}
                                            <Button
                                                label={'Czytaj więcej'}
                                                buttonStyle={BUTTON_STYLE.SECONDARY_NO_PADDING}
                                                onClick={() => clickHandler(post)}/>
                                        </ResultContent>
                                    </ResultDiv>
                                ))
                            : null
                        }
                    </Results>
                    <Display>
                        {!isMobile && <Slider isMobile={false} circleColor={'#3E1DAA'}/>}
                    </Display>
                </ContentDiv>
                {searchValues.length !== 0 && <div className="pagination">
                    <button disabled={currentPage === 1} onClick={() => setCurrentPage(1)}>{"<<"}</button>
                    <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>{'<'}</button>
                    {renderPaginationButtons()}
                    <button disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(currentPage + 1)}>{'>'}</button>
                    <button disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(totalPages)}>{'>>'}</button>
                </div>}
            </Wrapper>
        </>
    )
}

export default SearchBar

