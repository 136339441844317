import {StyledQuoteSection} from "./QuoteSection.styles";

interface QuoteSectionProps {
    isMobile: boolean
}

function QuoteSection({isMobile}: QuoteSectionProps) {

    return (
        <StyledQuoteSection isMobile={isMobile}>
           <h4>
               Przyglądając się architekturze od środka zaglądamy do kamienic, bloków i willi.
               Dzieje się tak, bo mamy <span>ciekawość</span> ludzi, którzy projektują, urządzają i mieszkają.
           </h4>
        </StyledQuoteSection>
    );
}

export default QuoteSection;
