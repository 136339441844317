import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import ARROW from '../../Assets/Autumn2023/Arrow.svg';

interface SliderProps {
    images: { url: string; label: string }[];
    width: number
}

function BlogSlider({ images, width }: SliderProps) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
    const [isRightArrowVisible, setIsRightArrowVisible] = useState(false);
    const [arrowPosition, setArrowPosition] = useState({ top: '50%', left: '50%' });
    const sliderRef = useRef<HTMLDivElement | null>(null);

    const goToPrevious = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const goToNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        const sliderRect = sliderRef.current?.getBoundingClientRect();

        if (sliderRect) {
            const cursorX = e.clientX - sliderRect.left;
            const cursorY = e.clientY - sliderRect.top;
            const isOverLeftHalf = cursorX < sliderRect.width / 2;
            const isOverRightHalf = cursorX >= sliderRect.width / 2;
            const arrowOffset = 20;
            const centerX = sliderRect.width / 2;
            const centerY = sliderRect.height / 2;
            const distanceFromCenter = Math.sqrt((cursorX - centerX) ** 2 + (cursorY - centerY) ** 2);

            setArrowPosition({ top: `${cursorY}px`, left: `${cursorX}px` });
            setIsLeftArrowVisible(isOverLeftHalf && distanceFromCenter < centerX - arrowOffset);
            setIsRightArrowVisible(isOverRightHalf && distanceFromCenter < centerX - arrowOffset);
        }
    };

    return (
        <StyledSlider ref={sliderRef} onMouseMove={handleMouseMove} width={width}>
            {isLeftArrowVisible && (
                <LeftArrow onClick={goToPrevious} style={arrowPosition}>
                    <img src={ARROW} style={{ transform: 'rotate(90deg)' }} alt="Left Arrow" />
                </LeftArrow>
            )}
            <ImageSlider width={width}>
                {images.map((image, index) => (
                    <ImageSlide key={index} isActive={index === activeIndex} width={width}>
                        <img src={image.url} alt={`Slide ${index}`} />
                        <ImageLabel>{image.label}</ImageLabel>
                    </ImageSlide>
                ))}
            </ImageSlider>
            {isRightArrowVisible && (
                <RightArrow onClick={goToNext} style={arrowPosition}>
                    <img src={ARROW} style={{ transform: 'rotate(-90deg)' }} alt="Right Arrow" />
                </RightArrow>
            )}
            <DotContainer>
                {images.map((_, index) => (
                    <Dot key={index} isActive={index === activeIndex} />
                ))}
            </DotContainer>
        </StyledSlider>
    );
}

export default BlogSlider;

interface ImageSlideProps {
    isActive: boolean;
    width: number
}

const StyledSlider = styled.div<{ width: number}>`
  position: relative;
  width: ${({width}) => (width ? `${width-20}px` : '555px')};
  height: ${({width}) => (width ? `${width*0.76}px` : '426px')};
  overflow: hidden;
`;

const ImageSlider = styled.div<{ width: number}>`
  position: relative;
  width: ${({width}) => (width ? `${width}px` : '555px')};
  height: ${({width}) => (width ? `${width*0.76}px` : '426px')};
  overflow: hidden;
`;

const ImageSlide = styled.div<ImageSlideProps>`
  position: absolute;
  width: ${({width}) => (width ? `${width}px` : '555px')};
  height: ${({width}) => (width ? `${width*0.76}px` : '426px')};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  img {
    width: ${({width}) => (width ? `${width}px` : '555px')};
    height: ${({width}) => (width ? `${width*0.76}px` : '426px')};
    object-fit: contain;
    margin: 0;
    left: 0;
  }
`;

const ImageLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  font-size: 14px;
  text-align: center;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  right: 50%;
  transform: translateX(50%);
  
  z-index: 1000;
  margin-top: 10px;
`;

interface DotProps {
    isActive: boolean;
}

const Dot = styled.div<DotProps>`
  width: 10px;
  height: 10px;
  background-color: ${({ isActive }) => (isActive ? '#FF59A9' : 'gray')};
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
`;

const LeftArrow = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  color: white;
  background-color: #ffa4cf;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  z-index: 2;

  img {
    width: 16px !important;
    height: 16px !important;
    margin: 0 !important;
    left: 0 !important;
  }

  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
`;

const RightArrow = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  color: white;
  background-color: #ffa4cf;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  z-index: 2;

  img {
    width: 16px !important;
    height: 16px !important;
    margin: 0 !important;
    left: 0 !important;
  }

  top: 50%;
  right: -20px;
  transform: translate(-10%, -50%);
`;


