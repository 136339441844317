import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {store} from "./Store";
import {Provider} from "react-redux";
import { ThemeProvider } from 'styled-components';
import {theme} from "./theme";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
        <App />
        </ThemeProvider>
    </Provider>
);


