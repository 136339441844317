import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";
import React, {useEffect, useState} from "react";
import gdyniaCover from "../../Assets/HomePageAssets/gdynia_cover.webp"
import {useAppSelector} from "../../Store/hooks";
import {isMobileSelector} from "../../Store/selectors/isMobileSelector";


function GdyniaEvents() {

    const navigate = useNavigate();
    const isMobile = useAppSelector(isMobileSelector);


    return (<Wrapper isMobile={isMobile}>
        {!isMobile ? <Header isMobile={isMobile}>
            <CardContainer>
                <ColorSquare color={'#FF59A9'} isMobile={isMobile}/>
                <Image src={gdyniaCover} alt={'gdynia'} isMobile={isMobile}/>
            </CardContainer>

            <FestivalTitleDiv isMobile={isMobile}>
                <h1>Gdynia</h1>
                <div>
                    <h2>23.05 – 26.05.2024</h2>
                    <Bullet isMobile={isMobile}>10 edycja</Bullet>
                </div>
            </FestivalTitleDiv>
        </Header> : null}

        <ContentDiv isMobile={isMobile}>
            {isMobile ?
                <>
                    <CardContainer isMobile={isMobile}>
                        <ColorSquare color={'#FF59A9'} isMobile={isMobile}/>
                        <Image src={gdyniaCover} alt={'gdynia'} isMobile={isMobile}/>
                    </CardContainer>

                    <FestivalTitleDiv isMobile={isMobile}>
                        <h1>Gdynia</h1>
                        <div>
                            <h2>23.05 – 26.05.2024</h2>
                            <Bullet isMobile={isMobile}>10 edycja</Bullet>
                        </div>
                    </FestivalTitleDiv>
                </>
                : null}
            <DescriptionDiv isMobile={isMobile}>
                <Title isMobile={isMobile}>
                    <h2>O festiwalu</h2>
                </Title>
                <Text isMobile={isMobile}>
                    <p>Dziesiąta, jubileuszowa edycja festiwalu architektury i otwartych mieszkań Open House
                        Gdynia,
                        będzie nie tylko corocznym świętem miasta i jego mieszkańców w wybranych dzielnicach. W
                        tej
                        edycji festiwal obejmie wszystkie dzielnice Gdyni – te reprezentacyjne, kultowe i
                        bardziej
                        znane, jak i peryferyjne oraz rzadziej odwiedzane. 21 dzielnic Gdyni pokażemy w różnych
                        odsłonach, podczas i poza festiwalowym długim weekendem.</p>
                    <span>To poprzez działania koncentrujące się na indywidualnych, oddolnych inicjatywach, przedstawieniu specyfiki poszczególnych dzielnic i obszarów Gdyni, a w szczególności przywołaniu zapomnianych historii, poczynimy krok w kierunku myślenia glokalnego. Glokalność jest przecież jedną z wiodących tendencji w myśleniu o współczesnych miastach. </span>

                    <p>Zwiedzanie budynków, spacery tematyczne z przewodnikami, odwiedziny w mieszkaniach
                        prywatnych, biurach, ogrodach i na działkach, wystawy, warsztaty eksperckie, spotkania,
                        projekcje filmowe oraz koncerty odbywać się będą przez cały rok, a w skoncentrowanej
                        formie
                        uświetnią długi festiwalowy weekend 23 - 26 maja 2024 roku. 10 edycja Open House Gdynia
                        będzie kolejną odsłoną poznawania miasta i jego historii – dogłębnie, darmowo, w
                        towarzystwie i rodzinnej atmosferze.
                    </p>
                    <p>Z okazji jubileuszu festiwalu częściej oddamy głos samym mieszkańcom, którzy opowiedzą
                        swoje
                        historie związane z miejscami, w których żyją i pracują, budynkami gdzie się wychowali,
                        szkołami, do których uczęszczali.
                    </p>
                    <p>Celem festiwalu jest pokazanie walorów Gdyni w sposób przystępny, dający zarazem
                        możliwość
                        szerokiego spojrzenia na historię miasta, jak i skupienia się na wątkach osobistych
                        mieszkańców. Należy wskazać również na cele historiotwórcze i kulturotwórcze, które w
                        mniejszym, lokalnym zasięgu, tworzą część najnowszej historii miasta, przyczyniając się
                        do
                        powstawania nowych znajomości, trwałych relacji i wspólnych wspomnień.
                    </p>
                    <p>Open House Gdynia jest nie tylko największym w Polsce festiwalem architektury i
                        miejskości,
                        ale przede wszystkim wielkim świętem Gdyni – dla wszystkich i bez wyjątku.</p>
                    <Button
                        buttonStyle={BUTTON_STYLE.GREEN}
                        onClick={() => navigate("/gdynia/events")}
                        label='Przejdź do wydarzeń'/>
                </Text>
            </DescriptionDiv>
        </ContentDiv>
    </Wrapper>);
}

export default GdyniaEvents


const Wrapper = styled.div`
    height: ${({isMobile}) => (!isMobile && '2000px')};
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-x: hidden;
    padding: ${({isMobile}) => (isMobile ? '16px' : 'inherit')};

    button {
        margin: 80px 0 40px 0;
        width: 320px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 0;
        }
    }
`


const FestivalTitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    max-height: ${({isMobile}) => (!isMobile && '200px')};
    opacity: 1;
    margin: ${({isMobile}) => (!isMobile && 'auto 0 0 auto')};
    padding-right: 40px;
    position: relative;
    

    h1 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk, sans -serif;
        font-size: ${({isMobile}) => (isMobile ? '70px' : '140px')};
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 140px */
        letter-spacing: -1.4px;
        margin: 0;
    }

    h2 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk, sans -serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        margin: 0;
    }

    & > div {
        display: flex;
        flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
        justify-content: space-between;
        align-items: ${({isMobile}) => (isMobile ? 'left' : 'center')};
    }
`
const Bullet = styled.div`
    color: #3E1DAA;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    display: flex;
    padding: 12px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    background: #E2FD5F;
    overflow-x: ${({isMobile}) => (!isMobile && '16px 0')};
    max-width: 200px;
    margin: ${({isMobile}) => (isMobile && '16px 0')};

`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    color: #1F0812;
    width: ${({isMobile}) => (isMobile ? '97%' : '785px')};
    font-family: Arial, sans-serif;

    p {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 32px 0;
    }

    span {
        font-size: 24px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
        margin: 0 0 32px 0;
    }
`

const Title = styled.div`
    width: ${({isMobile}) => (isMobile ? '90%' : '37%')};

    h2 {
        color: #3E1DAA;
        font-family: Cabinet Grotesk, sans -serif;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-align: ${({isMobile}) => (isMobile ? 'left' : 'right')};
        padding-right: ${({isMobile}) => (isMobile ? '0' : '35px')};
    }
`

const DescriptionDiv = styled.div`
    display: flex;
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
    gap: 20px;
    width: ${({isMobile}) => (isMobile ? '100%' : '80%')};
    margin: 0 auto;
    overflow-x: hidden;
`
const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({isMobile}) => (isMobile ? '0' : '70px 40px')};
    position: ${({isMobile}) => (!isMobile ? 'absolute' : 'relative')};
    top: ${({isMobile}) => (!isMobile ? '50vw' : '5vh')};
    width: ${({isMobile}) => (isMobile ? '95%' : '1440px')};
    overflow-x: hidden;

    @media (max-width: 1280px) {
        width: ${({isMobile}) => (isMobile ? '95%' : '1280px')};
    }
`

const Header = styled.div`
    display: flex;
    gap: 5vw;
    position: relative;
    margin-top: ${({isMobile}) => (isMobile ? '30px' : '120px')};
    flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
`

 const CardContainer = styled.div`
    width: ${({isMobile}) => (isMobile ? '86vw' : '40vw')};
    height: ${({isMobile}) => (isMobile ? '60vw' : '40vw')};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;
    margin-bottom: ${({isMobile}) => (isMobile && '26vw')};

`;

export const ColorSquare = styled.div`
    width: ${({isMobile}) => (isMobile ? '70vw' : '40vw')};
    height: ${({isMobile}) => (isMobile ? '70vw' : '40vw')};
    background-color: ${props => props.color};
    position: absolute;
    top: -20px;
    left: 20px;
`;

export const Image = styled.img`
    position: absolute;
    max-height: ${({isMobile}) => (isMobile ? '70vw' : '40vw')};
    max-width: ${({isMobile}) => (isMobile ? '70vw' : '40vw')};
    border-radius: 0 300px 0 0;
    left: ${({isMobile}) => (isMobile ? '0px' : 0)};

`;
