import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import maxLength from "../../Utilities/maxLength";
import {ArticleDataDisplay} from "../../Models/blog.model";
import Button from "../../Components/Common/Button";
import {BUTTON_STYLE} from "../../types/style";

interface NewestBlogSectionProps {
    entry: ArticleDataDisplay;
}

function NewestBlogSection({entry}: NewestBlogSectionProps) {

    const navigate = useNavigate();

    return (
        <StyledFirstEntry>
            <ImageContainer>
                <img src={entry.images[0].url}/>
                <EmptyCircle index={1}/>
                <EmptyCircle index={2}/>
                <EmptyCircle index={3}/>
            </ImageContainer>
            <ContentContainer>
                <TextContainer>
                    <h2>{entry.title}</h2>
                    <p>{maxLength(entry.content, 250)}</p>
                    <Button
                        label="Czytaj więcej"
                        onClick={() => navigate(`/blog/${entry.id}`)}
                        buttonStyle={BUTTON_STYLE.SECONDARY}
                    />
                </TextContainer>
            </ContentContainer>
        </StyledFirstEntry>
    );
}

export default NewestBlogSection;


const StyledFirstEntry = styled('div')`
    max-height: 364px;
    width: 100%;
    height: auto;
    color: #1F0812;
    font-weight: 400;
    font-style: normal;

    h2 {
        font-family: Arial, sans-serif;
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 16px;
    }

    p {
        font-family: Arial, sans-serif;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.16px;
        margin-bottom: 16px;
    }

    button {
        padding-left: 0;
   p {
       margin-bottom: 0;
    }
    }
`;

const ImageContainer = styled('div')`
    width: 60%;
    max-height: 364px;
    position: relative;

    img {
        width: 100%;
        max-height: 364px;
        object-fit: cover;
        border-radius: 0 360px 360px 0;
    }
`

const EmptyCircle = styled.div<{ index: number }>`
    opacity: 1;
    position: absolute;
    content: "";
    border: 2px solid #FBFDFF;
    border-radius: 50%;
    width: 240px;
    height: 240px;
    top: 120px;
    left: ${props => (props.index - 1) * 50}px;
`

const ContentContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 66%;
    height: 360px;
    object-fit: cover;
    border-radius: 360px;
    border: 2px solid #1F0812;
    position: relative;
    top: -364px;
    left: 455px;
    color: #1F0812;
`
const TextContainer = styled('div')`
    width: 450px;
    margin-right: 60px;
`
