import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EventData} from "../../Models/event.model";

interface ActiveEventState {
    value: EventData | null
}

const initialState: ActiveEventState = {
    value: null
};

export const activeEventSlice = createSlice({
    name: 'activeEvent',
    initialState,
    reducers: {
        setActiveEventStore: (state, action: PayloadAction<EventData | null>) => {
            state.value = action.payload;
        },
    },
});

export const {setActiveEventStore} = activeEventSlice.actions;
export default activeEventSlice.reducer;
